
import { defineComponent } from 'vue';
import Divider from 'primevue/divider';

export default defineComponent({
  components: {
    Divider,
  },
  props: {
    align: {
      type: String,
      default: 'center',
    },
  },
  setup() {
    return {};
  },
});
