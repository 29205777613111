
import { defineComponent } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  components: {
    ProgressSpinner,
  },
  setup() {
    return {};
  },
});
