
import { defineComponent } from 'vue';
import ConfirmDialog from 'primevue/confirmdialog';

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  props: {},
});
