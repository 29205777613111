
import { debounce } from "@/util";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  emits: ["search-text-changed"],
  setup(_, { emit }) {
    const containerKey = ref<number>(1);
    const searchText = ref<string>();

    watch(
      () => searchText.value,
      () => {
        debounce(() => {
          emit("search-text-changed", searchText.value);
        }, 600);
      }
    );

    function clearSearch() {
      searchText.value = "";
      containerKey.value *= -1;
    }

    return {
      containerKey,
      searchText,
      clearSearch
    };
  },
  methods: {
    setSearchText(filter: string) {
      this.searchText = filter;
      this.containerKey *= -1;
    }
  }
});
