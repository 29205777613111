
import { defineComponent, PropType } from "vue";
import Message from "primevue/message";

type MessageSeverityType = "success" | "info" | "warn" | "error" | undefined;

export default defineComponent({
  components: {
    Message
  },
  props: {
    content: {
      type: String,
      default: ""
    },
    severity: {
      type: String as PropType<MessageSeverityType>,
      default: "info"
    },
    closable: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  }
});
