import gql from "graphql-tag";
import * as VueApolloComposable from "@vue/apollo-composable";
import * as VueCompositionApi from "vue";
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: any;
  Time: string;
  Upload: any;
};

export type AccountPermissionInput = {
  id: Scalars["Int"];
  permissionType: Scalars["Int"];
};

export type Admin = Node & {
  __typename?: "Admin";
  avatar: Scalars["String"];
  createdAt?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  isDeletable?: Maybe<Scalars["Boolean"]>;
  isPending?: Maybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  mobileNumber?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["Int"]>;
  organizationName?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type AdminConnection = {
  __typename?: "AdminConnection";
  result: Array<Admin>;
  totalCount: Scalars["Int"];
};

export type AdminEdge = {
  __typename?: "AdminEdge";
  cursor: Scalars["String"];
  node: Admin;
};

export type AdminType = Node & {
  __typename?: "AdminType";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type AdminTypeConnection = {
  __typename?: "AdminTypeConnection";
  edges: Array<Maybe<AdminTypeEdge>>;
  nodes: Array<Maybe<AdminType>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type AdminTypeEdge = {
  __typename?: "AdminTypeEdge";
  cursor: Scalars["String"];
  node: AdminType;
};

export type AdminTypeSort = {
  direction: SortDirection;
  field: AdminTypeSortField;
};

export enum AdminTypeSortField {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT"
}

export type Announcement = Node & {
  __typename?: "Announcement";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isSent: Scalars["Boolean"];
};

export type AnnouncementConnection = {
  __typename?: "AnnouncementConnection";
  edges: Array<Maybe<AnnouncementEdge>>;
  nodes: Array<Maybe<Announcement>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type AnnouncementEdge = {
  __typename?: "AnnouncementEdge";
  cursor: Scalars["String"];
  node: Announcement;
};

export type AnnouncementSort = {
  direction: SortDirection;
  field: SortField;
};

export type ApproveExternalContactRequestInput = {
  accountIds: Array<Scalars["Int"]>;
  id: Scalars["Int"];
};

export type ContactGroup = {
  __typename?: "ContactGroup";
  id: Scalars["Int"];
  permissionTypeId: Scalars["Int"];
};

export type ContactGroupAccountInput = {
  id: Scalars["Int"];
  permissionTypeId: Scalars["Int"];
};

export type ContactGroupInput = {
  alias: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  permissionTypeId: Scalars["Int"];
};

export type CreateAdminTypeInput = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateAdminTypePayload = {
  __typename?: "CreateAdminTypePayload";
  adminType: AdminType;
};

export type CreateAnnouncementInput = {
  content: Scalars["String"];
  distributionId: Scalars["Int"];
};

export type CreateExternalContactRequestInput = {
  groupName: Scalars["String"];
  message: Scalars["String"];
  organization: Scalars["String"];
};

export type CreateOrganizationGroupInput = {
  accounts: Array<OrganizationGroupAccountInput>;
  alias: Scalars["String"];
  name: Scalars["String"];
};

export type CreateOrganizationJobTitleInput = {
  name: Scalars["String"];
};

export type CreateOrganizationJobTitlePayload = {
  __typename?: "CreateOrganizationJobTitlePayload";
  organizationJobTitle: OrganizationJobTitle;
};

export type CreateOrganizationSectionInput = {
  name: Scalars["String"];
};

export type CreateOrganizationSectionPayload = {
  __typename?: "CreateOrganizationSectionPayload";
  organizationSection: OrganizationSection;
};

export type CreateProAccountPayload = {
  __typename?: "CreateProAccountPayload";
  generatedPassword?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type CreateSubscriptionTypeInput = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateSubscriptionTypePayload = {
  __typename?: "CreateSubscriptionTypePayload";
  subscriptionType: SubscriptionType;
};

export type DeleteOrganizationJobTitlePayload = {
  __typename?: "DeleteOrganizationJobTitlePayload";
  success: Scalars["Boolean"];
};

export type DeleteOrganizationSectionPayload = {
  __typename?: "DeleteOrganizationSectionPayload";
  success: Scalars["Boolean"];
};

export type Distribution = Node & {
  __typename?: "Distribution";
  accountOptions?: Maybe<Array<ProAccount>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  recipientIds?: Maybe<Array<Scalars["Int"]>>;
  totalRecipients?: Maybe<Scalars["Int"]>;
};

export type DistributionAnnouncement = Node & {
  __typename?: "DistributionAnnouncement";
  announcements?: Maybe<Array<Announcement>>;
  id: Scalars["ID"];
  recipients?: Maybe<Array<ProAccount>>;
};

export type DummySubscriptionPayload = {
  __typename?: "DummySubscriptionPayload";
  dummy?: Maybe<Scalars["String"]>;
};

export type ExternalContactRequest = Node & {
  __typename?: "ExternalContactRequest";
  accounts?: Maybe<Array<ProAccount>>;
  createdAt?: Maybe<Scalars["String"]>;
  groupId?: Maybe<Scalars["Int"]>;
  groupOptions?: Maybe<Array<OrganizationGroup>>;
  id: Scalars["ID"];
  isOwnRequest?: Maybe<Scalars["Boolean"]>;
  message?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["Int"]>;
  requestType?: Maybe<Scalars["Int"]>;
  requestingOrganizationId?: Maybe<Scalars["Int"]>;
  sharedAccountIds?: Maybe<Array<Scalars["Int"]>>;
  statusId?: Maybe<Scalars["Int"]>;
  totalSharedContacts?: Maybe<Scalars["Int"]>;
};

export type ExternalContactRequestConnection = {
  __typename?: "ExternalContactRequestConnection";
  edges: Array<Maybe<ExternalContactRequestEdge>>;
  nodes: Array<ExternalContactRequest>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ExternalContactRequestEdge = {
  __typename?: "ExternalContactRequestEdge";
  cursor: Scalars["String"];
  node: ExternalContactRequest;
};

export type ExternalSharedContactConnection = {
  __typename?: "ExternalSharedContactConnection";
  edges: Array<Maybe<ExternalContactRequestEdge>>;
  nodes: Array<ExternalContactRequest>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
};

export type FreeUser = Node & {
  __typename?: "FreeUser";
  avatar?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  isOnline: Scalars["Boolean"];
  lastActiveAt: Scalars["String"];
  statusId: Scalars["Int"];
  username: Scalars["String"];
};

export type FreeUserConnection = {
  __typename?: "FreeUserConnection";
  result: Array<FreeUser>;
  totalCount: Scalars["Int"];
};

export type FreeUserEdge = {
  __typename?: "FreeUserEdge";
  cursor: Scalars["String"];
  node: FreeUser;
};

export type GeneratedAccount = {
  __typename?: "GeneratedAccount";
  name: Scalars["String"];
  password: Scalars["String"];
};

export type History = {
  __typename?: "History";
  createdAt: Scalars["String"];
  destination: Scalars["String"];
  destinationAvatar: Scalars["String"];
  sender: Scalars["String"];
  senderAvatar: Scalars["String"];
  statusId: Scalars["Int"];
  updatedAt: Scalars["String"];
};

export type HistoryConnection = {
  __typename?: "HistoryConnection";
  organization?: Maybe<Scalars["String"]>;
  result: Array<History>;
  totalCount: Scalars["Int"];
};

export type LoginInput = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type LoginOtpInput = {
  token: Scalars["String"];
};

export type LoginPayload = {
  __typename?: "LoginPayload";
  qrcode?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  user: Admin;
};

export type LoqOverview = {
  __typename?: "LoqOverview";
  callStatistics: Array<OverviewMonthlyStatistic>;
  groupMessageStatistics: Array<OverviewMonthlyStatistic>;
  messageStatistics: Array<OverviewMonthlyStatistic>;
  totalActiveOrganizations: Scalars["Int"];
  totalActiveUsers: Scalars["Int"];
  totalDisabledOrganizations: Scalars["Int"];
  totalFreeActiveUsers: Scalars["Int"];
  totalFreePendingUsers: Scalars["Int"];
  totalPendingOrganizations: Scalars["Int"];
  totalPendingUsers: Scalars["Int"];
};

export type MePayload = {
  __typename?: "MePayload";
  avatar: Scalars["String"];
  organization: Scalars["String"];
  totalRequests: Scalars["Int"];
  username: Scalars["String"];
  versionNumber: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** @deprecated Field no longer supported */
  _dummy?: Maybe<Scalars["String"]>;
  cleanupUnusedAvatars: SuccessPayload;
  createAdminType: CreateAdminTypePayload;
  createAnnouncement: SuccessPayload;
  createOrganizationJobTitle: CreateOrganizationJobTitlePayload;
  createOrganizationSection: CreateOrganizationSectionPayload;
  createSubscriptionType: CreateSubscriptionTypePayload;
  deleteAdmin: SuccessPayload;
  deleteContactGroup: SuccessPayload;
  deleteDistribution: SuccessPayload;
  deleteExternalContactRequest: SuccessPayload;
  deleteFreeUser: SuccessPayload;
  deleteFreeUsers: SuccessPayload;
  deleteOrganization: SuccessPayload;
  deleteOrganizationJobTitle: DeleteOrganizationJobTitlePayload;
  deleteOrganizationSection: DeleteOrganizationSectionPayload;
  deletePermissionProfile: SuccessPayload;
  deleteProAccount: SuccessPayload;
  disableDistribution: SuccessPayload;
  disableFreeUser: SuccessPayload;
  disableOrganization: SuccessPayload;
  enableDistribution: SuccessPayload;
  enableFreeUser: SuccessPayload;
  enableOrganization: SuccessPayload;
  login: LoginPayload;
  loginOTP: SuccessPayload;
  multipleUpload: Array<File>;
  proAccountCredential?: Maybe<ProAccountCredentialPayload>;
  register: SuccessPayload;
  requestResetPasswordCode: SuccessPayload;
  resetAdmin2FA: SuccessPayload;
  resetOrganizationOwner2FA: SuccessPayload;
  resetPassword: ResetPasswordPayload;
  saveAdmin: SuccessPayload;
  saveContactGroup: SuccessPayload;
  saveDistribution: SuccessPayload;
  saveExternalContactRequest: SuccessPayload;
  saveOrganization: SuccessPayload;
  savePermissionProfile: SuccessPayload;
  saveProAccount?: Maybe<SaveProAccountPayload>;
  saveProAccounts: SaveProAccountsPayload;
  sendAdminVerification: SuccessPayload;
  singleUpload: File;
  updateAccountPermission: SuccessPayload;
  updateOrganizationJobTitle: UpdateOrganizationJobTitlePayload;
  updateOrganizationSection: UpdateOrganizationSectionPayload;
  verifyAdmin: VerifyAdminPayload;
  verifyResetPasswordCode: SuccessPayload;
};

export type MutationCreateAdminTypeArgs = {
  input: CreateAdminTypeInput;
};

export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput;
};

export type MutationCreateOrganizationJobTitleArgs = {
  input: CreateOrganizationJobTitleInput;
};

export type MutationCreateOrganizationSectionArgs = {
  input: CreateOrganizationSectionInput;
};

export type MutationCreateSubscriptionTypeArgs = {
  input: CreateSubscriptionTypeInput;
};

export type MutationDeleteAdminArgs = {
  adminId: Scalars["Int"];
};

export type MutationDeleteContactGroupArgs = {
  groupId: Scalars["Int"];
};

export type MutationDeleteDistributionArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteExternalContactRequestArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteFreeUserArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteFreeUsersArgs = {
  ids: Array<Scalars["Int"]>;
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteOrganizationJobTitleArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteOrganizationSectionArgs = {
  id: Scalars["Int"];
};

export type MutationDeletePermissionProfileArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteProAccountArgs = {
  email: Scalars["String"];
};

export type MutationDisableDistributionArgs = {
  id: Scalars["Int"];
};

export type MutationDisableFreeUserArgs = {
  id: Scalars["Int"];
};

export type MutationDisableOrganizationArgs = {
  id: Scalars["Int"];
};

export type MutationEnableDistributionArgs = {
  id: Scalars["Int"];
};

export type MutationEnableFreeUserArgs = {
  id: Scalars["Int"];
};

export type MutationEnableOrganizationArgs = {
  id: Scalars["Int"];
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLoginOtpArgs = {
  input: LoginOtpInput;
};

export type MutationMultipleUploadArgs = {
  files: Array<Scalars["Upload"]>;
};

export type MutationProAccountCredentialArgs = {
  email: Scalars["String"];
  ignoreAlreadyCreated?: Maybe<Scalars["Boolean"]>;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationRequestResetPasswordCodeArgs = {
  input: RequestResetPasswordCodeInput;
};

export type MutationResetAdmin2FaArgs = {
  adminId: Scalars["Int"];
};

export type MutationResetOrganizationOwner2FaArgs = {
  organizationId: Scalars["Int"];
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSaveAdminArgs = {
  input: SaveAdminInput;
};

export type MutationSaveContactGroupArgs = {
  input: SaveContactGroupInput;
};

export type MutationSaveDistributionArgs = {
  input: SaveDistributionInput;
};

export type MutationSaveExternalContactRequestArgs = {
  input: SaveExternalContactRequestInput;
};

export type MutationSaveOrganizationArgs = {
  input: SaveOrganizationInput;
};

export type MutationSavePermissionProfileArgs = {
  input: SavePermissionProfileInput;
};

export type MutationSaveProAccountArgs = {
  input: SaveProAccountInput;
};

export type MutationSaveProAccountsArgs = {
  input: SaveProAccountsInput;
};

export type MutationSendAdminVerificationArgs = {
  input: SendAdminVerificationInput;
};

export type MutationSingleUploadArgs = {
  file: Scalars["Upload"];
};

export type MutationUpdateAccountPermissionArgs = {
  input: UpdateAccountPermissionInput;
};

export type MutationUpdateOrganizationJobTitleArgs = {
  input: UpdateOrganizationJobTitleInput;
};

export type MutationUpdateOrganizationSectionArgs = {
  input: UpdateOrganizationSectionInput;
};

export type MutationVerifyAdminArgs = {
  input: VerifyAdminInput;
};

export type MutationVerifyResetPasswordCodeArgs = {
  input: VerifyResetPasswordCodeInput;
};

export type Node = {
  id: Scalars["ID"];
};

export type OptionValue = {
  __typename?: "OptionValue";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Organization = Node & {
  __typename?: "Organization";
  accountLimit?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Admin>;
  startDate?: Maybe<Scalars["String"]>;
  statusId: Scalars["Int"];
  subscriptionType?: Maybe<SubscriptionType>;
  subscriptionTypeId?: Maybe<Scalars["Int"]>;
  totalUsers: Scalars["Int"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrganizationConnection = {
  __typename?: "OrganizationConnection";
  result: Array<Organization>;
  totalCount: Scalars["Int"];
};

export type OrganizationContact = Node & {
  __typename?: "OrganizationContact";
  avatar: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isInternalContact?: Maybe<Scalars["Boolean"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  permissionTypeId?: Maybe<Scalars["Int"]>;
  section?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type OrganizationEdge = {
  __typename?: "OrganizationEdge";
  cursor: Scalars["String"];
  node: Organization;
};

export type OrganizationGroup = Node & {
  __typename?: "OrganizationGroup";
  alias?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  totalContacts?: Maybe<Scalars["Int"]>;
};

export type OrganizationGroupAccountInput = {
  id: Scalars["Int"];
  permissionTypeId: Scalars["Int"];
};

export type OrganizationGroupContactsDataPayload = {
  __typename?: "OrganizationGroupContactsDataPayload";
  accounts?: Maybe<Array<OrganizationContact>>;
};

export type OrganizationGroupDataPayload = {
  __typename?: "OrganizationGroupDataPayload";
  alias?: Maybe<Scalars["String"]>;
  appContacts: Array<OrganizationContact>;
  externalContacts: Array<OrganizationContact>;
  internalContacts: Array<OrganizationContact>;
  name: Scalars["String"];
};

export type OrganizationGroupsConnection = {
  __typename?: "OrganizationGroupsConnection";
  result: Array<OrganizationGroup>;
  totalCount: Scalars["Int"];
};

export type OrganizationGroupsDataPayload = {
  __typename?: "OrganizationGroupsDataPayload";
  organizationGroups?: Maybe<Array<OrganizationGroup>>;
};

export type OrganizationJobTitle = Node & {
  __typename?: "OrganizationJobTitle";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OrganizationJobTitleConnection = {
  __typename?: "OrganizationJobTitleConnection";
  edges: Array<Maybe<OrganizationJobTitleEdge>>;
  nodes: Array<Maybe<OrganizationJobTitle>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type OrganizationJobTitleEdge = {
  __typename?: "OrganizationJobTitleEdge";
  cursor: Scalars["String"];
  node: OrganizationJobTitle;
};

export type OrganizationJobTitleSort = {
  direction: SortDirection;
  field: OrganizationJobTitleSortField;
};

export enum OrganizationJobTitleSortField {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT"
}

export type OrganizationOverview = {
  __typename?: "OrganizationOverview";
  callStatistics: Array<OverviewMonthlyStatistic>;
  groupMessageStatistics: Array<OverviewMonthlyStatistic>;
  isPastValidDate: Scalars["Boolean"];
  messageStatistics: Array<OverviewMonthlyStatistic>;
  name?: Maybe<Scalars["String"]>;
  nextPaymentDue: Scalars["Date"];
  owner?: Maybe<OrganizationOwner>;
  statusId?: Maybe<Scalars["Int"]>;
  subscriptionTypeId: Scalars["Int"];
  totalActiveUsers: Scalars["Int"];
  totalPendingUsers: Scalars["Int"];
  totalUsers: Scalars["Int"];
  validUntil: Scalars["Date"];
};

export type OrganizationOwner = {
  __typename?: "OrganizationOwner";
  avatar?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type OrganizationSection = Node & {
  __typename?: "OrganizationSection";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OrganizationSectionConnection = {
  __typename?: "OrganizationSectionConnection";
  edges: Array<Maybe<OrganizationSectionEdge>>;
  nodes: Array<Maybe<OrganizationSection>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type OrganizationSectionEdge = {
  __typename?: "OrganizationSectionEdge";
  cursor: Scalars["String"];
  node: OrganizationSection;
};

export type OrganizationSectionSort = {
  direction: SortDirection;
  field: OrganizationSectionSortField;
};

export enum OrganizationSectionSortField {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT"
}

export type OverviewMonthlyStatistic = {
  __typename?: "OverviewMonthlyStatistic";
  month: Scalars["Int"];
  totalNotDelivered: Scalars["Int"];
  totalSent: Scalars["Int"];
};

export type OverviewMonthlyUserStatistic = {
  __typename?: "OverviewMonthlyUserStatistic";
  month: Scalars["Int"];
  totalPendingUsers: Scalars["Int"];
  totalUsers: Scalars["Int"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export type PermissionProfile = Node & {
  __typename?: "PermissionProfile";
  contactGroupOptions?: Maybe<Array<OptionValue>>;
  contactGroups?: Maybe<Array<ContactGroup>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  totalContactGroups?: Maybe<Scalars["Int"]>;
};

export type PermissionProfileConnection = {
  __typename?: "PermissionProfileConnection";
  result: Array<PermissionProfile>;
  totalCount: Scalars["Int"];
};

export type PermissionType = Node & {
  __typename?: "PermissionType";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PermissionTypeDataPayload = {
  __typename?: "PermissionTypeDataPayload";
  result: Array<Maybe<PermissionType>>;
};

export type PreSignedUrlPayload = {
  __typename?: "PreSignedURLPayload";
  url: Scalars["String"];
};

export type ProAccount = Node & {
  __typename?: "ProAccount";
  avatar: Scalars["String"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  isPending?: Maybe<Scalars["Boolean"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  jobTitleOptions?: Maybe<Array<OptionValue>>;
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
  organization?: Maybe<Organization>;
  organizationId: Scalars["Int"];
  permissionTypeId: Scalars["Int"];
  section?: Maybe<Scalars["String"]>;
  sectionOptions?: Maybe<Array<OptionValue>>;
  statistics?: Maybe<ProAccountStatistics>;
  totalContactGroups?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ProAccountConnection = {
  __typename?: "ProAccountConnection";
  result: Array<ProAccount>;
  totalCount: Scalars["Int"];
};

export type ProAccountContactGroupsPayload = {
  __typename?: "ProAccountContactGroupsPayload";
  contactGroupOptions: Array<OptionValue>;
  contactGroups: Array<ContactGroup>;
  permissionProfileOptions: Array<OptionValue>;
};

export type ProAccountCredentialPayload = {
  __typename?: "ProAccountCredentialPayload";
  generatedPassword?: Maybe<Scalars["String"]>;
};

export type ProAccountEdge = {
  __typename?: "ProAccountEdge";
  cursor: Scalars["String"];
  node: ProAccount;
};

export type ProAccountStatistics = {
  __typename?: "ProAccountStatistics";
  deliveredCalls: Scalars["Int"];
  deliveredChats: Scalars["Int"];
  deliveredGroupChats: Scalars["Int"];
  readChats: Scalars["Int"];
  readGroupChats: Scalars["Int"];
  recievedCalls: Scalars["Int"];
  recievedChats: Scalars["Int"];
  recievedGroupChats: Scalars["Int"];
  sentCalls: Scalars["Int"];
  sentChats: Scalars["Int"];
  sentGroupChats: Scalars["Int"];
};

export type Query = {
  __typename?: "Query";
  /** @deprecated Field no longer supported */
  _dummy?: Maybe<Scalars["String"]>;
  admin: Admin;
  adminTypes: AdminTypeConnection;
  admins: AdminConnection;
  announcements: AnnouncementConnection;
  distribution: Distribution;
  distributionAnnouncement: DistributionAnnouncement;
  distributions: Array<Distribution>;
  externalContactRequest: ExternalContactRequest;
  externalContactRequests: ExternalContactRequestConnection;
  externalSharedContacts: ExternalSharedContactConnection;
  freeUsers: FreeUserConnection;
  history: HistoryConnection;
  loqOverview: LoqOverview;
  me: MePayload;
  organization: Organization;
  organizationGroup: OrganizationGroupDataPayload;
  organizationGroups: OrganizationGroupsConnection;
  organizationInternalContacts: OrganizationGroupContactsDataPayload;
  organizationJobTitles: OrganizationJobTitleConnection;
  organizationOverview: OrganizationOverview;
  organizationOverviewUserStatistics: Array<OverviewMonthlyUserStatistic>;
  organizationSections: OrganizationSectionConnection;
  organizationUngroupedContacts: OrganizationGroupContactsDataPayload;
  organizations: OrganizationConnection;
  permissionProfile: PermissionProfile;
  permissionProfiles: PermissionProfileConnection;
  permissionTypes: PermissionTypeDataPayload;
  preSignedUploadURL: PreSignedUrlPayload;
  proAccount: ProAccount;
  proAccountContactGroups: ProAccountContactGroupsPayload;
  proAccountProfileContactGroups: Array<ContactGroup>;
  proAccounts: ProAccountConnection;
  subscriptionTypes: SubscriptionTypeConnection;
  uploadProAccounts: UploadProAccountsPayload;
  validateAccounts: UploadProAccountsPayload;
};

export type QueryAdminArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryAdminTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<AdminTypeSort>;
};

export type QueryAdminsArgs = {
  filter?: Maybe<Scalars["String"]>;
  getAll?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QueryAnnouncementsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  distributionId: Scalars["Int"];
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<AnnouncementSort>;
};

export type QueryDistributionArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryDistributionAnnouncementArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryExternalContactRequestArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryExternalContactRequestsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QueryExternalSharedContactsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
  viewExported?: Maybe<Scalars["Boolean"]>;
};

export type QueryFreeUsersArgs = {
  filter?: Maybe<Scalars["String"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QueryHistoryArgs = {
  filter?: Maybe<Scalars["String"]>;
  getOrganization?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
  type?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationArgs = {
  id: Scalars["Int"];
};

export type QueryOrganizationGroupArgs = {
  groupId?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationGroupsArgs = {
  filter?: Maybe<Scalars["String"]>;
  getAll?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationInternalContactsArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type QueryOrganizationJobTitlesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<OrganizationJobTitleSort>;
};

export type QueryOrganizationOverviewArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationOverviewUserStatisticsArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryOrganizationSectionsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<OrganizationSectionSort>;
};

export type QueryOrganizationsArgs = {
  filter?: Maybe<Scalars["String"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QueryPermissionProfileArgs = {
  id?: Maybe<Scalars["Int"]>;
};

export type QueryPermissionProfilesArgs = {
  filter?: Maybe<Scalars["String"]>;
  getAll?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QueryPreSignedUploadUrlArgs = {
  fileName: Scalars["String"];
};

export type QueryProAccountArgs = {
  id: Scalars["Int"];
};

export type QueryProAccountContactGroupsArgs = {
  id: Scalars["Int"];
};

export type QueryProAccountProfileContactGroupsArgs = {
  id: Scalars["Int"];
};

export type QueryProAccountsArgs = {
  filter?: Maybe<Scalars["String"]>;
  getAll?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SortBy>;
};

export type QuerySubscriptionTypesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<SubscriptionTypeSort>;
};

export type QueryUploadProAccountsArgs = {
  countryCode: Scalars["String"];
  excel: Scalars["String"];
};

export type QueryValidateAccountsArgs = {
  accounts: Array<ValidateAccountInput>;
  countryCode: Scalars["String"];
};

export type RegisterInput = {
  avatar?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  middleName?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  typeId: Scalars["Int"];
  username: Scalars["String"];
};

export type RequestResetPasswordCodeInput = {
  username: Scalars["String"];
};

export type ResetPasswordInput = {
  code: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type ResetPasswordPayload = {
  __typename?: "ResetPasswordPayload";
  token: Scalars["String"];
  user: Admin;
};

export type SaveAdminInput = {
  avatar?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
};

export type SaveContactGroupInput = {
  alias: Scalars["String"];
  contacts: Array<ContactGroupAccountInput>;
  hasContactChanges: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type SaveDistributionInput = {
  hasChangedRecipients: Scalars["Boolean"];
  id: Scalars["Int"];
  name: Scalars["String"];
  recipientIds: Array<Scalars["Int"]>;
};

export type SaveExternalContactRequestInput = {
  accountIds: Array<Scalars["Int"]>;
  groupName: Scalars["String"];
  id: Scalars["Int"];
  message: Scalars["String"];
  organization: Scalars["String"];
};

export type SaveOrganizationInput = {
  accountLimit?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  alias: Scalars["String"];
  avatar?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  lastName?: Maybe<Scalars["String"]>;
  mobileNumber?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  startDate: Scalars["DateTime"];
  subscriptionTypeId?: Maybe<Scalars["Int"]>;
};

export type SavePermissionProfileInput = {
  contactGroups?: Maybe<Array<ContactGroupInput>>;
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type SaveProAccountInput = {
  avatar: Scalars["String"];
  contactGroups?: Maybe<Array<ContactGroupInput>>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  jobTitle?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
  permissionTypeId: Scalars["Int"];
  section?: Maybe<Scalars["String"]>;
};

export type SaveProAccountPayload = {
  __typename?: "SaveProAccountPayload";
  generatedPassword?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type SaveProAccountsInput = {
  accounts?: Maybe<Array<UploadedProAccountInput>>;
  contactGroups?: Maybe<Array<ContactGroupInput>>;
  permissionTypeId: Scalars["Int"];
};

export type SaveProAccountsPayload = {
  __typename?: "SaveProAccountsPayload";
  generatedAccounts: Array<GeneratedAccount>;
  success: Scalars["Boolean"];
};

export type SendAdminVerificationInput = {
  id: Scalars["Int"];
};

export type SortBy = {
  direction: SortDirection;
  field: SortField;
};

export enum SortDirection {
  /** Specifies an ascending order for a given `sortBy` argument. */
  ASC = "ASC",
  /** Specifies an descending order for a given `sortBy` argument. */
  DESC = "DESC"
}

export enum SortField {
  CREATED_AT = "CREATED_AT",
  EMAIL = "EMAIL",
  END_DATE = "END_DATE",
  FIRST_NAME = "FIRST_NAME",
  IS_PENDING = "IS_PENDING",
  JOB_TITLE = "JOB_TITLE",
  NAME = "NAME",
  SECTION = "SECTION",
  STATUS_ID = "STATUS_ID",
  SUBSCRIPTION_TYPE_ID = "SUBSCRIPTION_TYPE_ID",
  TOTAL_USERS = "TOTAL_USERS",
  UPDATED_AT = "UPDATED_AT"
}

export type Subscription = {
  __typename?: "Subscription";
  /** @deprecated Field no longer supported */
  _dummy?: Maybe<DummySubscriptionPayload>;
};

export type SubscriptionType = Node & {
  __typename?: "SubscriptionType";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionTypeConnection = {
  __typename?: "SubscriptionTypeConnection";
  edges: Array<Maybe<SubscriptionTypeEdge>>;
  nodes: Array<Maybe<SubscriptionType>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type SubscriptionTypeEdge = {
  __typename?: "SubscriptionTypeEdge";
  cursor: Scalars["String"];
  node: SubscriptionType;
};

export type SubscriptionTypeSort = {
  direction: SortDirection;
  field: SubscriptionTypeSortField;
};

export enum SubscriptionTypeSortField {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT"
}

export type SuccessPayload = {
  __typename?: "SuccessPayload";
  success: Scalars["Boolean"];
};

export type UpdateAccountPermissionInput = {
  id: Scalars["Int"];
  isPendingAccount: Scalars["Boolean"];
  permissionTypeId: Scalars["Int"];
};

export type UpdateExternalContactRequestInput = {
  id: Scalars["Int"];
  message: Scalars["String"];
};

export type UpdateOrganizationGroupInput = {
  accounts: Array<OrganizationGroupAccountInput>;
  alias: Scalars["String"];
  groupId: Scalars["Int"];
  name: Scalars["String"];
};

export type UpdateOrganizationJobTitleInput = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["Int"]>;
};

export type UpdateOrganizationJobTitlePayload = {
  __typename?: "UpdateOrganizationJobTitlePayload";
  organizationJobTitle: OrganizationJobTitle;
};

export type UpdateOrganizationSectionInput = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["Int"]>;
};

export type UpdateOrganizationSectionPayload = {
  __typename?: "UpdateOrganizationSectionPayload";
  organizationSection: OrganizationSection;
};

export type UploadProAccountsPayload = {
  __typename?: "UploadProAccountsPayload";
  accounts: Array<UploadedProAccount>;
};

export type UploadedProAccount = {
  __typename?: "UploadedProAccount";
  department: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
  statusId: Scalars["Int"];
};

export type UploadedProAccountInput = {
  avatar: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  jobTitle?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
  section?: Maybe<Scalars["String"]>;
};

export type ValidateAccountInput = {
  department: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
};

export type VerifyAdminInput = {
  code: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type VerifyAdminPayload = {
  __typename?: "VerifyAdminPayload";
  token: Scalars["String"];
  user: Admin;
};

export type VerifyResetPasswordCodeInput = {
  code: Scalars["String"];
  username: Scalars["String"];
};

export type AdminQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
}>;

export type AdminQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & Pick<
    Admin,
    | "firstName"
    | "lastName"
    | "avatar"
    | "email"
    | "mobileNumber"
    | "isDeletable"
  >;
};

export type AdminsQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type AdminsQuery = { __typename?: "Query" } & {
  admins: { __typename?: "AdminConnection" } & Pick<
    AdminConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "Admin" } & Pick<
          Admin,
          | "id"
          | "firstName"
          | "lastName"
          | "email"
          | "createdAt"
          | "isPending"
          | "avatar"
        >
      >;
    };
};

export type DeleteAdminMutationVariables = Exact<{
  adminId: Scalars["Int"];
}>;

export type DeleteAdminMutation = { __typename?: "Mutation" } & {
  deleteAdmin: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type ResetAdmin2FaMutationVariables = Exact<{
  adminId: Scalars["Int"];
}>;

export type ResetAdmin2FaMutation = { __typename?: "Mutation" } & {
  resetAdmin2FA: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type SaveAdminMutationVariables = Exact<{
  input: SaveAdminInput;
}>;

export type SaveAdminMutation = { __typename?: "Mutation" } & {
  saveAdmin: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type SendVerificationMutationVariables = Exact<{
  input: SendAdminVerificationInput;
}>;

export type SendVerificationMutation = { __typename?: "Mutation" } & {
  sendAdminVerification: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type CreateAnnouncementMutationVariables = Exact<{
  input: CreateAnnouncementInput;
}>;

export type CreateAnnouncementMutation = { __typename?: "Mutation" } & {
  createAnnouncement: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DeleteDistributionMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteDistributionMutation = { __typename?: "Mutation" } & {
  deleteDistribution: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type GetDistributionAnnouncementQueryVariables = Exact<{
  distributionId?: Maybe<Scalars["Int"]>;
}>;

export type GetDistributionAnnouncementQuery = { __typename?: "Query" } & {
  distributionAnnouncement: { __typename?: "DistributionAnnouncement" } & {
    announcements?: Maybe<
      Array<
        { __typename?: "Announcement" } & Pick<
          Announcement,
          "id" | "content" | "createdAt" | "isSent"
        >
      >
    >;
    recipients?: Maybe<
      Array<
        { __typename?: "ProAccount" } & Pick<
          ProAccount,
          "id" | "firstName" | "lastName" | "section" | "jobTitle" | "avatar"
        >
      >
    >;
  };
};

export type GetDistributionRecipientsQueryVariables = Exact<{
  distributionId?: Maybe<Scalars["Int"]>;
}>;

export type GetDistributionRecipientsQuery = { __typename?: "Query" } & {
  distributionAnnouncement: { __typename?: "DistributionAnnouncement" } & {
    recipients?: Maybe<
      Array<
        { __typename?: "ProAccount" } & Pick<
          ProAccount,
          "id" | "firstName" | "lastName" | "section" | "jobTitle" | "avatar"
        >
      >
    >;
  };
};

export type GetDistributionQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
}>;

export type GetDistributionQuery = { __typename?: "Query" } & {
  distribution: { __typename?: "Distribution" } & Pick<
    Distribution,
    "name" | "recipientIds"
  > & {
      accountOptions?: Maybe<
        Array<
          { __typename?: "ProAccount" } & Pick<
            ProAccount,
            "id" | "firstName" | "lastName" | "section" | "jobTitle" | "avatar"
          >
        >
      >;
    };
};

export type DistributionsQueryVariables = Exact<{ [key: string]: never }>;

export type DistributionsQuery = { __typename?: "Query" } & {
  distributions: Array<
    { __typename?: "Distribution" } & Pick<
      Distribution,
      "id" | "name" | "totalRecipients"
    >
  >;
};

export type SaveDistributionMutationVariables = Exact<{
  input: SaveDistributionInput;
}>;

export type SaveDistributionMutation = { __typename?: "Mutation" } & {
  saveDistribution: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DeleteContactGroupMutationVariables = Exact<{
  groupId: Scalars["Int"];
}>;

export type DeleteContactGroupMutation = { __typename?: "Mutation" } & {
  deleteContactGroup: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type OrganizationInternalContactsQueryVariables = Exact<{
  filter?: Maybe<Scalars["String"]>;
}>;

export type OrganizationInternalContactsQuery = { __typename?: "Query" } & {
  organizationInternalContacts: {
    __typename?: "OrganizationGroupContactsDataPayload";
  } & {
    accounts?: Maybe<
      Array<
        { __typename?: "OrganizationContact" } & Pick<
          OrganizationContact,
          | "id"
          | "username"
          | "jobTitle"
          | "section"
          | "permissionTypeId"
          | "avatar"
        >
      >
    >;
  };
};

export type OrganizationGroupQueryVariables = Exact<{
  groupId: Scalars["Int"];
}>;

export type OrganizationGroupQuery = { __typename?: "Query" } & {
  organizationGroup: { __typename?: "OrganizationGroupDataPayload" } & Pick<
    OrganizationGroupDataPayload,
    "name" | "alias"
  > & {
      internalContacts: Array<
        { __typename?: "OrganizationContact" } & Pick<
          OrganizationContact,
          | "id"
          | "username"
          | "jobTitle"
          | "section"
          | "organization"
          | "avatar"
          | "isInternalContact"
        >
      >;
      externalContacts: Array<
        { __typename?: "OrganizationContact" } & Pick<
          OrganizationContact,
          | "id"
          | "username"
          | "jobTitle"
          | "section"
          | "organization"
          | "avatar"
          | "isInternalContact"
        >
      >;
      appContacts: Array<
        { __typename?: "OrganizationContact" } & Pick<
          OrganizationContact,
          | "id"
          | "username"
          | "jobTitle"
          | "section"
          | "email"
          | "organization"
          | "avatar"
          | "isInternalContact"
          | "permissionTypeId"
        >
      >;
    };
};

export type OrganizationGroupsQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type OrganizationGroupsQuery = { __typename?: "Query" } & {
  organizationGroups: { __typename?: "OrganizationGroupsConnection" } & Pick<
    OrganizationGroupsConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "OrganizationGroup" } & Pick<
          OrganizationGroup,
          "id" | "name" | "alias" | "totalContacts"
        >
      >;
    };
};

export type SaveContactGroupMutationVariables = Exact<{
  input: SaveContactGroupInput;
}>;

export type SaveContactGroupMutation = { __typename?: "Mutation" } & {
  saveContactGroup: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type OrganizationUngroupedContactsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationUngroupedContactsQuery = { __typename?: "Query" } & {
  organizationUngroupedContacts: {
    __typename?: "OrganizationGroupContactsDataPayload";
  } & {
    accounts?: Maybe<
      Array<
        { __typename?: "OrganizationContact" } & Pick<
          OrganizationContact,
          "id" | "username" | "jobTitle" | "section" | "organization" | "avatar"
        >
      >
    >;
  };
};

export type DeleteExternalContactRequestMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteExternalContactRequestMutation = {
  __typename?: "Mutation";
} & {
  deleteExternalContactRequest: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type ExternalContactRequestQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
}>;

export type ExternalContactRequestQuery = { __typename?: "Query" } & {
  externalContactRequest: { __typename?: "ExternalContactRequest" } & Pick<
    ExternalContactRequest,
    | "id"
    | "organization"
    | "message"
    | "sharedAccountIds"
    | "isOwnRequest"
    | "statusId"
    | "requestType"
  > & {
      accounts?: Maybe<
        Array<
          { __typename?: "ProAccount" } & Pick<
            ProAccount,
            "id" | "firstName" | "lastName" | "jobTitle" | "section" | "avatar"
          >
        >
      >;
      groupOptions?: Maybe<
        Array<
          { __typename?: "OrganizationGroup" } & Pick<
            OrganizationGroup,
            "id" | "name"
          >
        >
      >;
    };
};

export type ExternalContactRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExternalContactRequestsQuery = { __typename?: "Query" } & {
  externalContactRequests: {
    __typename?: "ExternalContactRequestConnection";
  } & Pick<ExternalContactRequestConnection, "totalCount"> & {
      nodes: Array<
        { __typename?: "ExternalContactRequest" } & Pick<
          ExternalContactRequest,
          "id" | "organization" | "createdAt" | "statusId" | "isOwnRequest"
        >
      >;
    };
};

export type ExternalSharedContactsQueryVariables = Exact<{
  viewExported?: Maybe<Scalars["Boolean"]>;
}>;

export type ExternalSharedContactsQuery = { __typename?: "Query" } & {
  externalSharedContacts: { __typename?: "ExternalSharedContactConnection" } & {
    nodes: Array<
      { __typename?: "ExternalContactRequest" } & Pick<
        ExternalContactRequest,
        "id" | "organization" | "totalSharedContacts"
      >
    >;
  };
};

export type SaveExternalContactRequestMutationVariables = Exact<{
  input: SaveExternalContactRequestInput;
}>;

export type SaveExternalContactRequestMutation = { __typename?: "Mutation" } & {
  saveExternalContactRequest: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type RequestResetPasswordCodeMutationVariables = Exact<{
  input: RequestResetPasswordCodeInput;
}>;

export type RequestResetPasswordCodeMutation = { __typename?: "Mutation" } & {
  requestResetPasswordCode: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & {
  resetPassword: { __typename?: "ResetPasswordPayload" } & Pick<
    ResetPasswordPayload,
    "token"
  > & { user: { __typename?: "Admin" } & Pick<Admin, "id"> };
};

export type VerifyResetPasswordCodeMutationVariables = Exact<{
  input: VerifyResetPasswordCodeInput;
}>;

export type VerifyResetPasswordCodeMutation = { __typename?: "Mutation" } & {
  verifyResetPasswordCode: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DeleteFreeUserMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteFreeUserMutation = { __typename?: "Mutation" } & {
  deleteFreeUser: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DeleteFreeUsersMutationVariables = Exact<{
  ids: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type DeleteFreeUsersMutation = { __typename?: "Mutation" } & {
  deleteFreeUsers: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DisableFreeUserMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DisableFreeUserMutation = { __typename?: "Mutation" } & {
  disableFreeUser: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type EnableFreeUserMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EnableFreeUserMutation = { __typename?: "Mutation" } & {
  enableFreeUser: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type FreeUsersQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type FreeUsersQuery = { __typename?: "Query" } & {
  freeUsers: { __typename?: "FreeUserConnection" } & Pick<
    FreeUserConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "FreeUser" } & Pick<
          FreeUser,
          | "id"
          | "statusId"
          | "username"
          | "avatar"
          | "createdAt"
          | "lastActiveAt"
          | "isOnline"
        >
      >;
    };
};

export type HistoryQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getOrganization?: Maybe<Scalars["Boolean"]>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type HistoryQuery = { __typename?: "Query" } & {
  history: { __typename?: "HistoryConnection" } & Pick<
    HistoryConnection,
    "totalCount" | "organization"
  > & {
      result: Array<
        { __typename?: "History" } & Pick<
          History,
          | "sender"
          | "senderAvatar"
          | "destination"
          | "destinationAvatar"
          | "createdAt"
          | "updatedAt"
          | "statusId"
        >
      >;
    };
};

export type LoqOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type LoqOverviewQuery = { __typename?: "Query" } & {
  loqOverview: { __typename?: "LoqOverview" } & Pick<
    LoqOverview,
    | "totalActiveOrganizations"
    | "totalPendingOrganizations"
    | "totalDisabledOrganizations"
    | "totalActiveUsers"
    | "totalPendingUsers"
    | "totalFreePendingUsers"
    | "totalFreeActiveUsers"
  > & {
      messageStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
      groupMessageStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
      callStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
    };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: "Query" } & {
  me: { __typename?: "MePayload" } & Pick<
    MePayload,
    "username" | "organization" | "avatar" | "versionNumber" | "totalRequests"
  >;
};

export type OrganizationOverviewUserStatisticsQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
}>;

export type OrganizationOverviewUserStatisticsQuery = {
  __typename?: "Query";
} & {
  organizationOverviewUserStatistics: Array<
    { __typename?: "OverviewMonthlyUserStatistic" } & Pick<
      OverviewMonthlyUserStatistic,
      "month" | "totalUsers" | "totalPendingUsers"
    >
  >;
};

export type OrganizationOverviewQueryVariables = Exact<{
  id?: Maybe<Scalars["Int"]>;
}>;

export type OrganizationOverviewQuery = { __typename?: "Query" } & {
  organizationOverview: { __typename?: "OrganizationOverview" } & Pick<
    OrganizationOverview,
    | "name"
    | "subscriptionTypeId"
    | "statusId"
    | "validUntil"
    | "isPastValidDate"
    | "nextPaymentDue"
    | "totalUsers"
    | "totalActiveUsers"
    | "totalPendingUsers"
  > & {
      owner?: Maybe<
        { __typename?: "OrganizationOwner" } & Pick<
          OrganizationOwner,
          "id" | "name" | "email" | "avatar"
        >
      >;
      messageStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
      groupMessageStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
      callStatistics: Array<
        { __typename?: "OverviewMonthlyStatistic" } & Pick<
          OverviewMonthlyStatistic,
          "month" | "totalSent" | "totalNotDelivered"
        >
      >;
    };
};

export type LoginOtpMutationVariables = Exact<{
  input: LoginOtpInput;
}>;

export type LoginOtpMutation = { __typename?: "Mutation" } & {
  loginOTP: { __typename?: "SuccessPayload" } & Pick<SuccessPayload, "success">;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: "Mutation" } & {
  login: { __typename?: "LoginPayload" } & Pick<
    LoginPayload,
    "token" | "qrcode"
  > & {
      user: { __typename?: "Admin" } & Pick<
        Admin,
        "id" | "firstName" | "lastName" | "organizationName" | "avatar"
      >;
    };
};

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteOrganizationMutation = { __typename?: "Mutation" } & {
  deleteOrganization: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DisableOrganizationMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DisableOrganizationMutation = { __typename?: "Mutation" } & {
  disableOrganization: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type EnableOrganizationMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EnableOrganizationMutation = { __typename?: "Mutation" } & {
  enableOrganization: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type OrganizationQuery = { __typename?: "Query" } & {
  organization: { __typename?: "Organization" } & Pick<
    Organization,
    | "name"
    | "address"
    | "accountLimit"
    | "startDate"
    | "endDate"
    | "subscriptionTypeId"
    | "totalUsers"
    | "alias"
  >;
};

export type OrganizationsQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type OrganizationsQuery = { __typename?: "Query" } & {
  organizations: { __typename?: "OrganizationConnection" } & Pick<
    OrganizationConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "Organization" } & Pick<
          Organization,
          | "id"
          | "name"
          | "statusId"
          | "accountLimit"
          | "startDate"
          | "endDate"
          | "totalUsers"
          | "alias"
        > & {
            owner?: Maybe<
              { __typename?: "Admin" } & Pick<
                Admin,
                "id" | "email" | "firstName" | "lastName" | "avatar"
              >
            >;
            subscriptionType?: Maybe<
              { __typename?: "SubscriptionType" } & Pick<
                SubscriptionType,
                "id" | "name"
              >
            >;
          }
      >;
    };
};

export type ResetOrganizationOwner2FaMutationVariables = Exact<{
  organizationId: Scalars["Int"];
}>;

export type ResetOrganizationOwner2FaMutation = { __typename?: "Mutation" } & {
  resetOrganizationOwner2FA: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type SaveOrganizationMutationVariables = Exact<{
  input: SaveOrganizationInput;
}>;

export type SaveOrganizationMutation = { __typename?: "Mutation" } & {
  saveOrganization: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type DeletePermissionProfileMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeletePermissionProfileMutation = { __typename?: "Mutation" } & {
  deletePermissionProfile: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type PermissionProfileQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PermissionProfileQuery = { __typename?: "Query" } & {
  permissionProfile: { __typename?: "PermissionProfile" } & Pick<
    PermissionProfile,
    "name"
  > & {
      contactGroupOptions?: Maybe<
        Array<{ __typename?: "OptionValue" } & Pick<OptionValue, "id" | "name">>
      >;
      contactGroups?: Maybe<
        Array<
          { __typename?: "ContactGroup" } & Pick<
            ContactGroup,
            "id" | "permissionTypeId"
          >
        >
      >;
    };
};

export type PermissionProfilesQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type PermissionProfilesQuery = { __typename?: "Query" } & {
  permissionProfiles: { __typename?: "PermissionProfileConnection" } & Pick<
    PermissionProfileConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "PermissionProfile" } & Pick<
          PermissionProfile,
          "id" | "name" | "totalContactGroups"
        >
      >;
    };
};

export type SavePermissionProfileMutationVariables = Exact<{
  input: SavePermissionProfileInput;
}>;

export type SavePermissionProfileMutation = { __typename?: "Mutation" } & {
  savePermissionProfile: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type CleanupUnusedAvatarsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CleanupUnusedAvatarsMutation = { __typename?: "Mutation" } & {
  cleanupUnusedAvatars: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type UploadProAccountsQueryVariables = Exact<{
  data: Scalars["String"];
  countryCode: Scalars["String"];
}>;

export type UploadProAccountsQuery = { __typename?: "Query" } & {
  uploadProAccounts: { __typename?: "UploadProAccountsPayload" } & {
    accounts: Array<
      { __typename?: "UploadedProAccount" } & Pick<
        UploadedProAccount,
        "department" | "email" | "name" | "phone" | "role" | "statusId"
      >
    >;
  };
};

export type CreateJobTitleMutationVariables = Exact<{
  input: CreateOrganizationJobTitleInput;
}>;

export type CreateJobTitleMutation = { __typename?: "Mutation" } & {
  createOrganizationJobTitle: {
    __typename?: "CreateOrganizationJobTitlePayload";
  } & {
    organizationJobTitle: { __typename?: "OrganizationJobTitle" } & Pick<
      OrganizationJobTitle,
      "id" | "name"
    >;
  };
};

export type CreateOrganizationSectionMutationVariables = Exact<{
  input: CreateOrganizationSectionInput;
}>;

export type CreateOrganizationSectionMutation = { __typename?: "Mutation" } & {
  createOrganizationSection: {
    __typename?: "CreateOrganizationSectionPayload";
  } & {
    organizationSection: { __typename?: "OrganizationSection" } & Pick<
      OrganizationSection,
      "id" | "name"
    >;
  };
};

export type DeleteOrganizationJobTitleMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteOrganizationJobTitleMutation = { __typename?: "Mutation" } & {
  deleteOrganizationJobTitle: {
    __typename?: "DeleteOrganizationJobTitlePayload";
  } & Pick<DeleteOrganizationJobTitlePayload, "success">;
};

export type DeleteOrganizationSectionMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteOrganizationSectionMutation = { __typename?: "Mutation" } & {
  deleteOrganizationSection: {
    __typename?: "DeleteOrganizationSectionPayload";
  } & Pick<DeleteOrganizationSectionPayload, "success">;
};

export type DeleteProAccountMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type DeleteProAccountMutation = { __typename?: "Mutation" } & {
  deleteProAccount: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type OrganizationJobTitlesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationJobTitlesQuery = { __typename?: "Query" } & {
  organizationJobTitles: { __typename?: "OrganizationJobTitleConnection" } & {
    nodes: Array<
      Maybe<
        { __typename?: "OrganizationJobTitle" } & Pick<
          OrganizationJobTitle,
          "id" | "name"
        >
      >
    >;
  };
};

export type PreSignedUploadUrlQueryVariables = Exact<{
  fileName: Scalars["String"];
}>;

export type PreSignedUploadUrlQuery = { __typename?: "Query" } & {
  preSignedUploadURL: { __typename?: "PreSignedURLPayload" } & Pick<
    PreSignedUrlPayload,
    "url"
  >;
};

export type ProAccountContactGroupsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ProAccountContactGroupsQuery = { __typename?: "Query" } & {
  proAccountContactGroups: { __typename?: "ProAccountContactGroupsPayload" } & {
    contactGroupOptions: Array<
      { __typename?: "OptionValue" } & Pick<OptionValue, "id" | "name">
    >;
    contactGroups: Array<
      { __typename?: "ContactGroup" } & Pick<
        ContactGroup,
        "id" | "permissionTypeId"
      >
    >;
    permissionProfileOptions: Array<
      { __typename?: "OptionValue" } & Pick<OptionValue, "id" | "name">
    >;
  };
};

export type ProAccountCredentialMutationVariables = Exact<{
  email: Scalars["String"];
  ignoreAlreadyCreated?: Maybe<Scalars["Boolean"]>;
}>;

export type ProAccountCredentialMutation = { __typename?: "Mutation" } & {
  proAccountCredential?: Maybe<
    { __typename?: "ProAccountCredentialPayload" } & Pick<
      ProAccountCredentialPayload,
      "generatedPassword"
    >
  >;
};

export type ProAccountProfileContactGroupsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ProAccountProfileContactGroupsQuery = { __typename?: "Query" } & {
  proAccountProfileContactGroups: Array<
    { __typename?: "ContactGroup" } & Pick<
      ContactGroup,
      "id" | "permissionTypeId"
    >
  >;
};

export type ProAccountQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ProAccountQuery = { __typename?: "Query" } & {
  proAccount: { __typename?: "ProAccount" } & Pick<
    ProAccount,
    | "firstName"
    | "lastName"
    | "email"
    | "mobileNumber"
    | "section"
    | "jobTitle"
    | "avatar"
    | "permissionTypeId"
    | "totalContactGroups"
  > & {
      sectionOptions?: Maybe<
        Array<{ __typename?: "OptionValue" } & Pick<OptionValue, "id" | "name">>
      >;
      jobTitleOptions?: Maybe<
        Array<{ __typename?: "OptionValue" } & Pick<OptionValue, "id" | "name">>
      >;
    };
};

export type ProAccountsQueryVariables = Exact<{
  page?: Maybe<Scalars["Int"]>;
  rows?: Maybe<Scalars["Int"]>;
  filter?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<SortBy>;
  getTotalCount?: Maybe<Scalars["Boolean"]>;
}>;

export type ProAccountsQuery = { __typename?: "Query" } & {
  proAccounts: { __typename?: "ProAccountConnection" } & Pick<
    ProAccountConnection,
    "totalCount"
  > & {
      result: Array<
        { __typename?: "ProAccount" } & Pick<
          ProAccount,
          | "id"
          | "firstName"
          | "lastName"
          | "isPending"
          | "mobileNumber"
          | "email"
          | "section"
          | "jobTitle"
          | "avatar"
          | "permissionTypeId"
        >
      >;
    };
};

export type SaveProAccountMutationVariables = Exact<{
  input: SaveProAccountInput;
}>;

export type SaveProAccountMutation = { __typename?: "Mutation" } & {
  saveProAccount?: Maybe<
    { __typename?: "SaveProAccountPayload" } & Pick<
      SaveProAccountPayload,
      "success" | "generatedPassword"
    >
  >;
};

export type SaveProAccountsMutationVariables = Exact<{
  input: SaveProAccountsInput;
}>;

export type SaveProAccountsMutation = { __typename?: "Mutation" } & {
  saveProAccounts: { __typename?: "SaveProAccountsPayload" } & Pick<
    SaveProAccountsPayload,
    "success"
  > & {
      generatedAccounts: Array<
        { __typename?: "GeneratedAccount" } & Pick<
          GeneratedAccount,
          "password" | "name"
        >
      >;
    };
};

export type OrganizationSectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationSectionsQuery = { __typename?: "Query" } & {
  organizationSections: { __typename?: "OrganizationSectionConnection" } & {
    nodes: Array<
      Maybe<
        { __typename?: "OrganizationSection" } & Pick<
          OrganizationSection,
          "id" | "name"
        >
      >
    >;
  };
};

export type UpdateAccountPermissionMutationVariables = Exact<{
  input: UpdateAccountPermissionInput;
}>;

export type UpdateAccountPermissionMutation = { __typename?: "Mutation" } & {
  updateAccountPermission: { __typename?: "SuccessPayload" } & Pick<
    SuccessPayload,
    "success"
  >;
};

export type ValidateAccountsQueryVariables = Exact<{
  accounts: Array<ValidateAccountInput> | ValidateAccountInput;
  countryCode: Scalars["String"];
}>;

export type ValidateAccountsQuery = { __typename?: "Query" } & {
  validateAccounts: { __typename?: "UploadProAccountsPayload" } & {
    accounts: Array<
      { __typename?: "UploadedProAccount" } & Pick<
        UploadedProAccount,
        "department" | "email" | "name" | "phone" | "role" | "statusId"
      >
    >;
  };
};

export type VerifyAdminMutationVariables = Exact<{
  input: VerifyAdminInput;
}>;

export type VerifyAdminMutation = { __typename?: "Mutation" } & {
  verifyAdmin: { __typename?: "VerifyAdminPayload" } & Pick<
    VerifyAdminPayload,
    "token"
  > & { user: { __typename?: "Admin" } & Pick<Admin, "id"> };
};

export const AdminDocument = gql`
  query admin($id: Int) {
    admin(id: $id) {
      firstName
      lastName
      avatar
      email
      mobileNumber
      isDeletable
    }
  }
`;

/**
 * __useAdminQuery__
 *
 * To run a query within a Vue component, call `useAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAdminQuery({
 *   id: // value for 'id'
 * });
 */
export function useAdminQuery(
  variables:
    | AdminQueryVariables
    | VueCompositionApi.Ref<AdminQueryVariables>
    | ReactiveFunction<AdminQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<AdminQuery, AdminQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<AdminQuery, AdminQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<AdminQuery, AdminQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<AdminQuery, AdminQueryVariables>(
    AdminDocument,
    variables,
    options
  );
}
export type AdminQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  AdminQuery,
  AdminQueryVariables
>;
export const AdminsDocument = gql`
  query Admins(
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getTotalCount: Boolean
  ) {
    admins(
      filter: $filter
      page: $page
      rows: $rows
      sortBy: $sortBy
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        firstName
        lastName
        email
        createdAt
        isPending
        avatar
      }
      totalCount
    }
  }
`;

/**
 * __useAdminsQuery__
 *
 * To run a query within a Vue component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAdminsQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useAdminsQuery(
  variables:
    | AdminsQueryVariables
    | VueCompositionApi.Ref<AdminsQueryVariables>
    | ReactiveFunction<AdminsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<AdminsQuery, AdminsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<AdminsQuery, AdminsQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<AdminsQuery, AdminsQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<AdminsQuery, AdminsQueryVariables>(
    AdminsDocument,
    variables,
    options
  );
}
export type AdminsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  AdminsQuery,
  AdminsQueryVariables
>;
export const DeleteAdminDocument = gql`
  mutation DeleteAdmin($adminId: Int!) {
    deleteAdmin(adminId: $adminId) {
      success
    }
  }
`;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAdminMutation({
 *   variables: {
 *     adminId: // value for 'adminId'
 *   },
 * });
 */
export function useDeleteAdminMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteAdminMutation,
        DeleteAdminMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteAdminMutation,
          DeleteAdminMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteAdminMutation,
    DeleteAdminMutationVariables
  >(DeleteAdminDocument, options);
}
export type DeleteAdminMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteAdminMutation,
  DeleteAdminMutationVariables
>;
export const ResetAdmin2FaDocument = gql`
  mutation ResetAdmin2FA($adminId: Int!) {
    resetAdmin2FA(adminId: $adminId) {
      success
    }
  }
`;

/**
 * __useResetAdmin2FaMutation__
 *
 * To run a mutation, you first call `useResetAdmin2FaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetAdmin2FaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetAdmin2FaMutation({
 *   variables: {
 *     adminId: // value for 'adminId'
 *   },
 * });
 */
export function useResetAdmin2FaMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ResetAdmin2FaMutation,
        ResetAdmin2FaMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ResetAdmin2FaMutation,
          ResetAdmin2FaMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ResetAdmin2FaMutation,
    ResetAdmin2FaMutationVariables
  >(ResetAdmin2FaDocument, options);
}
export type ResetAdmin2FaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ResetAdmin2FaMutation,
  ResetAdmin2FaMutationVariables
>;
export const SaveAdminDocument = gql`
  mutation SaveAdmin($input: SaveAdminInput!) {
    saveAdmin(input: $input) {
      success
    }
  }
`;

/**
 * __useSaveAdminMutation__
 *
 * To run a mutation, you first call `useSaveAdminMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveAdminMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveAdminMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveAdminMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveAdminMutation,
        SaveAdminMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveAdminMutation,
          SaveAdminMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveAdminMutation,
    SaveAdminMutationVariables
  >(SaveAdminDocument, options);
}
export type SaveAdminMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveAdminMutation,
  SaveAdminMutationVariables
>;
export const SendVerificationDocument = gql`
  mutation SendVerification($input: SendAdminVerificationInput!) {
    sendAdminVerification(input: $input) {
      success
    }
  }
`;

/**
 * __useSendVerificationMutation__
 *
 * To run a mutation, you first call `useSendVerificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendVerificationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SendVerificationMutation,
        SendVerificationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SendVerificationMutation,
          SendVerificationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SendVerificationMutation,
    SendVerificationMutationVariables
  >(SendVerificationDocument, options);
}
export type SendVerificationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendVerificationMutation,
  SendVerificationMutationVariables
>;
export const CreateAnnouncementDocument = gql`
  mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      success
    }
  }
`;

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAnnouncementMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateAnnouncementMutation,
        CreateAnnouncementMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateAnnouncementMutation,
          CreateAnnouncementMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateAnnouncementMutation,
    CreateAnnouncementMutationVariables
  >(CreateAnnouncementDocument, options);
}
export type CreateAnnouncementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateAnnouncementMutation,
  CreateAnnouncementMutationVariables
>;
export const DeleteDistributionDocument = gql`
  mutation DeleteDistribution($id: Int!) {
    deleteDistribution(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteDistributionMutation__
 *
 * To run a mutation, you first call `useDeleteDistributionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistributionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDistributionMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDistributionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteDistributionMutation,
        DeleteDistributionMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteDistributionMutation,
          DeleteDistributionMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteDistributionMutation,
    DeleteDistributionMutationVariables
  >(DeleteDistributionDocument, options);
}
export type DeleteDistributionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteDistributionMutation,
  DeleteDistributionMutationVariables
>;
export const GetDistributionAnnouncementDocument = gql`
  query getDistributionAnnouncement($distributionId: Int) {
    distributionAnnouncement(id: $distributionId) {
      announcements {
        id
        content
        createdAt
        isSent
      }
      recipients {
        id
        firstName
        lastName
        section
        jobTitle
        avatar
      }
    }
  }
`;

/**
 * __useGetDistributionAnnouncementQuery__
 *
 * To run a query within a Vue component, call `useGetDistributionAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionAnnouncementQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDistributionAnnouncementQuery({
 *   distributionId: // value for 'distributionId'
 * });
 */
export function useGetDistributionAnnouncementQuery(
  variables:
    | GetDistributionAnnouncementQueryVariables
    | VueCompositionApi.Ref<GetDistributionAnnouncementQueryVariables>
    | ReactiveFunction<GetDistributionAnnouncementQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetDistributionAnnouncementQuery,
        GetDistributionAnnouncementQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetDistributionAnnouncementQuery,
          GetDistributionAnnouncementQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetDistributionAnnouncementQuery,
          GetDistributionAnnouncementQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetDistributionAnnouncementQuery,
    GetDistributionAnnouncementQueryVariables
  >(GetDistributionAnnouncementDocument, variables, options);
}
export type GetDistributionAnnouncementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  GetDistributionAnnouncementQuery,
  GetDistributionAnnouncementQueryVariables
>;
export const GetDistributionRecipientsDocument = gql`
  query getDistributionRecipients($distributionId: Int) {
    distributionAnnouncement(id: $distributionId) {
      recipients {
        id
        firstName
        lastName
        section
        jobTitle
        avatar
      }
    }
  }
`;

/**
 * __useGetDistributionRecipientsQuery__
 *
 * To run a query within a Vue component, call `useGetDistributionRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionRecipientsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDistributionRecipientsQuery({
 *   distributionId: // value for 'distributionId'
 * });
 */
export function useGetDistributionRecipientsQuery(
  variables:
    | GetDistributionRecipientsQueryVariables
    | VueCompositionApi.Ref<GetDistributionRecipientsQueryVariables>
    | ReactiveFunction<GetDistributionRecipientsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetDistributionRecipientsQuery,
        GetDistributionRecipientsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetDistributionRecipientsQuery,
          GetDistributionRecipientsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetDistributionRecipientsQuery,
          GetDistributionRecipientsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetDistributionRecipientsQuery,
    GetDistributionRecipientsQueryVariables
  >(GetDistributionRecipientsDocument, variables, options);
}
export type GetDistributionRecipientsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  GetDistributionRecipientsQuery,
  GetDistributionRecipientsQueryVariables
>;
export const GetDistributionDocument = gql`
  query getDistribution($id: Int) {
    distribution(id: $id) {
      name
      accountOptions {
        id
        firstName
        lastName
        section
        jobTitle
        avatar
      }
      recipientIds
    }
  }
`;

/**
 * __useGetDistributionQuery__
 *
 * To run a query within a Vue component, call `useGetDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetDistributionQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetDistributionQuery(
  variables:
    | GetDistributionQueryVariables
    | VueCompositionApi.Ref<GetDistributionQueryVariables>
    | ReactiveFunction<GetDistributionQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetDistributionQuery,
        GetDistributionQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetDistributionQuery,
          GetDistributionQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetDistributionQuery,
          GetDistributionQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetDistributionQuery,
    GetDistributionQueryVariables
  >(GetDistributionDocument, variables, options);
}
export type GetDistributionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  GetDistributionQuery,
  GetDistributionQueryVariables
>;
export const DistributionsDocument = gql`
  query Distributions {
    distributions {
      id
      name
      totalRecipients
    }
  }
`;

/**
 * __useDistributionsQuery__
 *
 * To run a query within a Vue component, call `useDistributionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDistributionsQuery();
 */
export function useDistributionsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        DistributionsQuery,
        DistributionsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          DistributionsQuery,
          DistributionsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          DistributionsQuery,
          DistributionsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    DistributionsQuery,
    DistributionsQueryVariables
  >(DistributionsDocument, {}, options);
}
export type DistributionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  DistributionsQuery,
  DistributionsQueryVariables
>;
export const SaveDistributionDocument = gql`
  mutation SaveDistribution($input: SaveDistributionInput!) {
    saveDistribution(input: $input) {
      success
    }
  }
`;

/**
 * __useSaveDistributionMutation__
 *
 * To run a mutation, you first call `useSaveDistributionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveDistributionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveDistributionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveDistributionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveDistributionMutation,
        SaveDistributionMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveDistributionMutation,
          SaveDistributionMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveDistributionMutation,
    SaveDistributionMutationVariables
  >(SaveDistributionDocument, options);
}
export type SaveDistributionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveDistributionMutation,
  SaveDistributionMutationVariables
>;
export const DeleteContactGroupDocument = gql`
  mutation DeleteContactGroup($groupId: Int!) {
    deleteContactGroup(groupId: $groupId) {
      success
    }
  }
`;

/**
 * __useDeleteContactGroupMutation__
 *
 * To run a mutation, you first call `useDeleteContactGroupMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactGroupMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteContactGroupMutation({
 *   variables: {
 *     groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteContactGroupMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteContactGroupMutation,
        DeleteContactGroupMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteContactGroupMutation,
          DeleteContactGroupMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteContactGroupMutation,
    DeleteContactGroupMutationVariables
  >(DeleteContactGroupDocument, options);
}
export type DeleteContactGroupMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteContactGroupMutation,
  DeleteContactGroupMutationVariables
>;
export const OrganizationInternalContactsDocument = gql`
  query OrganizationInternalContacts($filter: String) {
    organizationInternalContacts(filter: $filter) {
      accounts {
        id
        username
        jobTitle
        section
        permissionTypeId
        avatar
      }
    }
  }
`;

/**
 * __useOrganizationInternalContactsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationInternalContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInternalContactsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationInternalContactsQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useOrganizationInternalContactsQuery(
  variables:
    | OrganizationInternalContactsQueryVariables
    | VueCompositionApi.Ref<OrganizationInternalContactsQueryVariables>
    | ReactiveFunction<OrganizationInternalContactsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationInternalContactsQuery,
        OrganizationInternalContactsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationInternalContactsQuery,
          OrganizationInternalContactsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationInternalContactsQuery,
          OrganizationInternalContactsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationInternalContactsQuery,
    OrganizationInternalContactsQueryVariables
  >(OrganizationInternalContactsDocument, variables, options);
}
export type OrganizationInternalContactsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationInternalContactsQuery,
  OrganizationInternalContactsQueryVariables
>;
export const OrganizationGroupDocument = gql`
  query OrganizationGroup($groupId: Int!) {
    organizationGroup(groupId: $groupId) {
      name
      alias
      internalContacts {
        id
        username
        jobTitle
        section
        organization
        avatar
        isInternalContact
      }
      externalContacts {
        id
        username
        jobTitle
        section
        organization
        avatar
        isInternalContact
      }
      appContacts {
        id
        username
        jobTitle
        section
        email
        organization
        avatar
        isInternalContact
        permissionTypeId
      }
    }
  }
`;

/**
 * __useOrganizationGroupQuery__
 *
 * To run a query within a Vue component, call `useOrganizationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGroupQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationGroupQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useOrganizationGroupQuery(
  variables:
    | OrganizationGroupQueryVariables
    | VueCompositionApi.Ref<OrganizationGroupQueryVariables>
    | ReactiveFunction<OrganizationGroupQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationGroupQuery,
        OrganizationGroupQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationGroupQuery,
          OrganizationGroupQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationGroupQuery,
          OrganizationGroupQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationGroupQuery,
    OrganizationGroupQueryVariables
  >(OrganizationGroupDocument, variables, options);
}
export type OrganizationGroupQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationGroupQuery,
  OrganizationGroupQueryVariables
>;
export const OrganizationGroupsDocument = gql`
  query OrganizationGroups(
    $page: Int
    $rows: Int
    $filter: String
    $getTotalCount: Boolean
  ) {
    organizationGroups(
      filter: $filter
      page: $page
      rows: $rows
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        name
        alias
        totalContacts
      }
      totalCount
    }
  }
`;

/**
 * __useOrganizationGroupsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationGroupsQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useOrganizationGroupsQuery(
  variables:
    | OrganizationGroupsQueryVariables
    | VueCompositionApi.Ref<OrganizationGroupsQueryVariables>
    | ReactiveFunction<OrganizationGroupsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationGroupsQuery,
        OrganizationGroupsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationGroupsQuery,
          OrganizationGroupsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationGroupsQuery,
          OrganizationGroupsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationGroupsQuery,
    OrganizationGroupsQueryVariables
  >(OrganizationGroupsDocument, variables, options);
}
export type OrganizationGroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationGroupsQuery,
  OrganizationGroupsQueryVariables
>;
export const SaveContactGroupDocument = gql`
  mutation SaveContactGroup($input: SaveContactGroupInput!) {
    saveContactGroup(input: $input) {
      success
    }
  }
`;

/**
 * __useSaveContactGroupMutation__
 *
 * To run a mutation, you first call `useSaveContactGroupMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveContactGroupMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveContactGroupMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveContactGroupMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveContactGroupMutation,
        SaveContactGroupMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveContactGroupMutation,
          SaveContactGroupMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveContactGroupMutation,
    SaveContactGroupMutationVariables
  >(SaveContactGroupDocument, options);
}
export type SaveContactGroupMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveContactGroupMutation,
  SaveContactGroupMutationVariables
>;
export const OrganizationUngroupedContactsDocument = gql`
  query OrganizationUngroupedContacts {
    organizationUngroupedContacts {
      accounts {
        id
        username
        jobTitle
        section
        organization
        avatar
      }
    }
  }
`;

/**
 * __useOrganizationUngroupedContactsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationUngroupedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUngroupedContactsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationUngroupedContactsQuery();
 */
export function useOrganizationUngroupedContactsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationUngroupedContactsQuery,
        OrganizationUngroupedContactsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationUngroupedContactsQuery,
          OrganizationUngroupedContactsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationUngroupedContactsQuery,
          OrganizationUngroupedContactsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationUngroupedContactsQuery,
    OrganizationUngroupedContactsQueryVariables
  >(OrganizationUngroupedContactsDocument, {}, options);
}
export type OrganizationUngroupedContactsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationUngroupedContactsQuery,
  OrganizationUngroupedContactsQueryVariables
>;
export const DeleteExternalContactRequestDocument = gql`
  mutation DeleteExternalContactRequest($id: Int!) {
    deleteExternalContactRequest(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteExternalContactRequestMutation__
 *
 * To run a mutation, you first call `useDeleteExternalContactRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalContactRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteExternalContactRequestMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExternalContactRequestMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteExternalContactRequestMutation,
        DeleteExternalContactRequestMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteExternalContactRequestMutation,
          DeleteExternalContactRequestMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteExternalContactRequestMutation,
    DeleteExternalContactRequestMutationVariables
  >(DeleteExternalContactRequestDocument, options);
}
export type DeleteExternalContactRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteExternalContactRequestMutation,
  DeleteExternalContactRequestMutationVariables
>;
export const ExternalContactRequestDocument = gql`
  query externalContactRequest($id: Int) {
    externalContactRequest(id: $id) {
      id
      organization
      message
      sharedAccountIds
      isOwnRequest
      statusId
      requestType
      accounts {
        id
        firstName
        lastName
        jobTitle
        section
        avatar
      }
      groupOptions {
        id
        name
      }
    }
  }
`;

/**
 * __useExternalContactRequestQuery__
 *
 * To run a query within a Vue component, call `useExternalContactRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalContactRequestQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useExternalContactRequestQuery({
 *   id: // value for 'id'
 * });
 */
export function useExternalContactRequestQuery(
  variables:
    | ExternalContactRequestQueryVariables
    | VueCompositionApi.Ref<ExternalContactRequestQueryVariables>
    | ReactiveFunction<ExternalContactRequestQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ExternalContactRequestQuery,
        ExternalContactRequestQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ExternalContactRequestQuery,
          ExternalContactRequestQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ExternalContactRequestQuery,
          ExternalContactRequestQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ExternalContactRequestQuery,
    ExternalContactRequestQueryVariables
  >(ExternalContactRequestDocument, variables, options);
}
export type ExternalContactRequestQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ExternalContactRequestQuery,
  ExternalContactRequestQueryVariables
>;
export const ExternalContactRequestsDocument = gql`
  query ExternalContactRequests {
    externalContactRequests {
      nodes {
        id
        organization
        createdAt
        statusId
        isOwnRequest
      }
      totalCount
    }
  }
`;

/**
 * __useExternalContactRequestsQuery__
 *
 * To run a query within a Vue component, call `useExternalContactRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalContactRequestsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useExternalContactRequestsQuery();
 */
export function useExternalContactRequestsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        ExternalContactRequestsQuery,
        ExternalContactRequestsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ExternalContactRequestsQuery,
          ExternalContactRequestsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ExternalContactRequestsQuery,
          ExternalContactRequestsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ExternalContactRequestsQuery,
    ExternalContactRequestsQueryVariables
  >(ExternalContactRequestsDocument, {}, options);
}
export type ExternalContactRequestsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ExternalContactRequestsQuery,
  ExternalContactRequestsQueryVariables
>;
export const ExternalSharedContactsDocument = gql`
  query ExternalSharedContacts($viewExported: Boolean) {
    externalSharedContacts(viewExported: $viewExported) {
      nodes {
        id
        organization
        totalSharedContacts
      }
    }
  }
`;

/**
 * __useExternalSharedContactsQuery__
 *
 * To run a query within a Vue component, call `useExternalSharedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalSharedContactsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useExternalSharedContactsQuery({
 *   viewExported: // value for 'viewExported'
 * });
 */
export function useExternalSharedContactsQuery(
  variables:
    | ExternalSharedContactsQueryVariables
    | VueCompositionApi.Ref<ExternalSharedContactsQueryVariables>
    | ReactiveFunction<ExternalSharedContactsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ExternalSharedContactsQuery,
        ExternalSharedContactsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ExternalSharedContactsQuery,
          ExternalSharedContactsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ExternalSharedContactsQuery,
          ExternalSharedContactsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ExternalSharedContactsQuery,
    ExternalSharedContactsQueryVariables
  >(ExternalSharedContactsDocument, variables, options);
}
export type ExternalSharedContactsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ExternalSharedContactsQuery,
  ExternalSharedContactsQueryVariables
>;
export const SaveExternalContactRequestDocument = gql`
  mutation SaveExternalContactRequest(
    $input: SaveExternalContactRequestInput!
  ) {
    saveExternalContactRequest(input: $input) {
      success
    }
  }
`;

/**
 * __useSaveExternalContactRequestMutation__
 *
 * To run a mutation, you first call `useSaveExternalContactRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveExternalContactRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveExternalContactRequestMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveExternalContactRequestMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveExternalContactRequestMutation,
        SaveExternalContactRequestMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveExternalContactRequestMutation,
          SaveExternalContactRequestMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveExternalContactRequestMutation,
    SaveExternalContactRequestMutationVariables
  >(SaveExternalContactRequestDocument, options);
}
export type SaveExternalContactRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveExternalContactRequestMutation,
  SaveExternalContactRequestMutationVariables
>;
export const RequestResetPasswordCodeDocument = gql`
  mutation RequestResetPasswordCode($input: RequestResetPasswordCodeInput!) {
    requestResetPasswordCode(input: $input) {
      success
    }
  }
`;

/**
 * __useRequestResetPasswordCodeMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordCodeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordCodeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestResetPasswordCodeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordCodeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RequestResetPasswordCodeMutation,
        RequestResetPasswordCodeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RequestResetPasswordCodeMutation,
          RequestResetPasswordCodeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RequestResetPasswordCodeMutation,
    RequestResetPasswordCodeMutationVariables
  >(RequestResetPasswordCodeDocument, options);
}
export type RequestResetPasswordCodeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  RequestResetPasswordCodeMutation,
  RequestResetPasswordCodeMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      user {
        id
      }
      token
    }
  }
`;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ResetPasswordMutation,
        ResetPasswordMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ResetPasswordMutation,
          ResetPasswordMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const VerifyResetPasswordCodeDocument = gql`
  mutation VerifyResetPasswordCode($input: VerifyResetPasswordCodeInput!) {
    verifyResetPasswordCode(input: $input) {
      success
    }
  }
`;

/**
 * __useVerifyResetPasswordCodeMutation__
 *
 * To run a mutation, you first call `useVerifyResetPasswordCodeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPasswordCodeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyResetPasswordCodeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyResetPasswordCodeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        VerifyResetPasswordCodeMutation,
        VerifyResetPasswordCodeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          VerifyResetPasswordCodeMutation,
          VerifyResetPasswordCodeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    VerifyResetPasswordCodeMutation,
    VerifyResetPasswordCodeMutationVariables
  >(VerifyResetPasswordCodeDocument, options);
}
export type VerifyResetPasswordCodeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  VerifyResetPasswordCodeMutation,
  VerifyResetPasswordCodeMutationVariables
>;
export const DeleteFreeUserDocument = gql`
  mutation DeleteFreeUser($id: Int!) {
    deleteFreeUser(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteFreeUserMutation__
 *
 * To run a mutation, you first call `useDeleteFreeUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFreeUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFreeUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFreeUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteFreeUserMutation,
        DeleteFreeUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteFreeUserMutation,
          DeleteFreeUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteFreeUserMutation,
    DeleteFreeUserMutationVariables
  >(DeleteFreeUserDocument, options);
}
export type DeleteFreeUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteFreeUserMutation,
  DeleteFreeUserMutationVariables
>;
export const DeleteFreeUsersDocument = gql`
  mutation DeleteFreeUsers($ids: [Int!]!) {
    deleteFreeUsers(ids: $ids) {
      success
    }
  }
`;

/**
 * __useDeleteFreeUsersMutation__
 *
 * To run a mutation, you first call `useDeleteFreeUsersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFreeUsersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFreeUsersMutation({
 *   variables: {
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFreeUsersMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteFreeUsersMutation,
        DeleteFreeUsersMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteFreeUsersMutation,
          DeleteFreeUsersMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteFreeUsersMutation,
    DeleteFreeUsersMutationVariables
  >(DeleteFreeUsersDocument, options);
}
export type DeleteFreeUsersMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteFreeUsersMutation,
  DeleteFreeUsersMutationVariables
>;
export const DisableFreeUserDocument = gql`
  mutation DisableFreeUser($id: Int!) {
    disableFreeUser(id: $id) {
      success
    }
  }
`;

/**
 * __useDisableFreeUserMutation__
 *
 * To run a mutation, you first call `useDisableFreeUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDisableFreeUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDisableFreeUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDisableFreeUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DisableFreeUserMutation,
        DisableFreeUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DisableFreeUserMutation,
          DisableFreeUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DisableFreeUserMutation,
    DisableFreeUserMutationVariables
  >(DisableFreeUserDocument, options);
}
export type DisableFreeUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DisableFreeUserMutation,
  DisableFreeUserMutationVariables
>;
export const EnableFreeUserDocument = gql`
  mutation EnableFreeUser($id: Int!) {
    enableFreeUser(id: $id) {
      success
    }
  }
`;

/**
 * __useEnableFreeUserMutation__
 *
 * To run a mutation, you first call `useEnableFreeUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEnableFreeUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEnableFreeUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useEnableFreeUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        EnableFreeUserMutation,
        EnableFreeUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          EnableFreeUserMutation,
          EnableFreeUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    EnableFreeUserMutation,
    EnableFreeUserMutationVariables
  >(EnableFreeUserDocument, options);
}
export type EnableFreeUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  EnableFreeUserMutation,
  EnableFreeUserMutationVariables
>;
export const FreeUsersDocument = gql`
  query FreeUsers(
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getTotalCount: Boolean
  ) {
    freeUsers(
      page: $page
      rows: $rows
      filter: $filter
      sortBy: $sortBy
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        statusId
        username
        avatar
        createdAt
        lastActiveAt
        isOnline
      }
      totalCount
    }
  }
`;

/**
 * __useFreeUsersQuery__
 *
 * To run a query within a Vue component, call `useFreeUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreeUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFreeUsersQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useFreeUsersQuery(
  variables:
    | FreeUsersQueryVariables
    | VueCompositionApi.Ref<FreeUsersQueryVariables>
    | ReactiveFunction<FreeUsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        FreeUsersQuery,
        FreeUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          FreeUsersQuery,
          FreeUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          FreeUsersQuery,
          FreeUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<FreeUsersQuery, FreeUsersQueryVariables>(
    FreeUsersDocument,
    variables,
    options
  );
}
export type FreeUsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  FreeUsersQuery,
  FreeUsersQueryVariables
>;
export const HistoryDocument = gql`
  query history(
    $id: Int
    $type: Int
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getOrganization: Boolean
    $getTotalCount: Boolean
  ) {
    history(
      id: $id
      type: $type
      filter: $filter
      page: $page
      rows: $rows
      sortBy: $sortBy
      getOrganization: $getOrganization
      getTotalCount: $getTotalCount
    ) {
      result {
        sender
        senderAvatar
        destination
        destinationAvatar
        createdAt
        updatedAt
        statusId
      }
      totalCount
      organization
    }
  }
`;

/**
 * __useHistoryQuery__
 *
 * To run a query within a Vue component, call `useHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHistoryQuery({
 *   id: // value for 'id'
 *   type: // value for 'type'
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getOrganization: // value for 'getOrganization'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useHistoryQuery(
  variables:
    | HistoryQueryVariables
    | VueCompositionApi.Ref<HistoryQueryVariables>
    | ReactiveFunction<HistoryQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<HistoryQuery, HistoryQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<HistoryQuery, HistoryQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<HistoryQuery, HistoryQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<HistoryQuery, HistoryQueryVariables>(
    HistoryDocument,
    variables,
    options
  );
}
export type HistoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  HistoryQuery,
  HistoryQueryVariables
>;
export const LoqOverviewDocument = gql`
  query LoqOverview {
    loqOverview {
      totalActiveOrganizations
      totalPendingOrganizations
      totalDisabledOrganizations
      totalActiveUsers
      totalPendingUsers
      totalFreePendingUsers
      totalFreeActiveUsers
      messageStatistics {
        month
        totalSent
        totalNotDelivered
      }
      groupMessageStatistics {
        month
        totalSent
        totalNotDelivered
      }
      callStatistics {
        month
        totalSent
        totalNotDelivered
      }
    }
  }
`;

/**
 * __useLoqOverviewQuery__
 *
 * To run a query within a Vue component, call `useLoqOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoqOverviewQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLoqOverviewQuery();
 */
export function useLoqOverviewQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        LoqOverviewQuery,
        LoqOverviewQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          LoqOverviewQuery,
          LoqOverviewQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          LoqOverviewQuery,
          LoqOverviewQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    LoqOverviewQuery,
    LoqOverviewQueryVariables
  >(LoqOverviewDocument, {}, options);
}
export type LoqOverviewQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  LoqOverviewQuery,
  LoqOverviewQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      username
      organization
      avatar
      versionNumber
      totalRequests
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(
  options:
    | VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    {},
    options
  );
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  MeQuery,
  MeQueryVariables
>;
export const OrganizationOverviewUserStatisticsDocument = gql`
  query OrganizationOverviewUserStatistics($id: Int) {
    organizationOverviewUserStatistics(id: $id) {
      month
      totalUsers
      totalPendingUsers
    }
  }
`;

/**
 * __useOrganizationOverviewUserStatisticsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationOverviewUserStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOverviewUserStatisticsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationOverviewUserStatisticsQuery({
 *   id: // value for 'id'
 * });
 */
export function useOrganizationOverviewUserStatisticsQuery(
  variables:
    | OrganizationOverviewUserStatisticsQueryVariables
    | VueCompositionApi.Ref<OrganizationOverviewUserStatisticsQueryVariables>
    | ReactiveFunction<OrganizationOverviewUserStatisticsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationOverviewUserStatisticsQuery,
        OrganizationOverviewUserStatisticsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationOverviewUserStatisticsQuery,
          OrganizationOverviewUserStatisticsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationOverviewUserStatisticsQuery,
          OrganizationOverviewUserStatisticsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationOverviewUserStatisticsQuery,
    OrganizationOverviewUserStatisticsQueryVariables
  >(OrganizationOverviewUserStatisticsDocument, variables, options);
}
export type OrganizationOverviewUserStatisticsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationOverviewUserStatisticsQuery,
  OrganizationOverviewUserStatisticsQueryVariables
>;
export const OrganizationOverviewDocument = gql`
  query OrganizationOverview($id: Int) {
    organizationOverview(id: $id) {
      name
      subscriptionTypeId
      statusId
      validUntil
      isPastValidDate
      nextPaymentDue
      totalUsers
      totalActiveUsers
      totalPendingUsers
      owner {
        id
        name
        email
        avatar
      }
      messageStatistics {
        month
        totalSent
        totalNotDelivered
      }
      groupMessageStatistics {
        month
        totalSent
        totalNotDelivered
      }
      callStatistics {
        month
        totalSent
        totalNotDelivered
      }
    }
  }
`;

/**
 * __useOrganizationOverviewQuery__
 *
 * To run a query within a Vue component, call `useOrganizationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOverviewQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationOverviewQuery({
 *   id: // value for 'id'
 * });
 */
export function useOrganizationOverviewQuery(
  variables:
    | OrganizationOverviewQueryVariables
    | VueCompositionApi.Ref<OrganizationOverviewQueryVariables>
    | ReactiveFunction<OrganizationOverviewQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationOverviewQuery,
        OrganizationOverviewQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationOverviewQuery,
          OrganizationOverviewQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationOverviewQuery,
          OrganizationOverviewQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationOverviewQuery,
    OrganizationOverviewQueryVariables
  >(OrganizationOverviewDocument, variables, options);
}
export type OrganizationOverviewQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationOverviewQuery,
  OrganizationOverviewQueryVariables
>;
export const LoginOtpDocument = gql`
  mutation LoginOTP($input: LoginOTPInput!) {
    loginOTP(input: $input) {
      success
    }
  }
`;

/**
 * __useLoginOtpMutation__
 *
 * To run a mutation, you first call `useLoginOtpMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginOtpMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginOtpMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginOtpMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        LoginOtpMutation,
        LoginOtpMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          LoginOtpMutation,
          LoginOtpMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    LoginOtpMutation,
    LoginOtpMutationVariables
  >(LoginOtpDocument, options);
}
export type LoginOtpMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  LoginOtpMutation,
  LoginOtpMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
        firstName
        lastName
        organizationName
        avatar
      }
      token
      qrcode
    }
  }
`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        LoginMutation,
        LoginMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          LoginMutation,
          LoginMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  LoginMutation,
  LoginMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($id: Int!) {
    deleteOrganization(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOrganizationMutation,
          DeleteOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const DisableOrganizationDocument = gql`
  mutation DisableOrganization($id: Int!) {
    disableOrganization(id: $id) {
      success
    }
  }
`;

/**
 * __useDisableOrganizationMutation__
 *
 * To run a mutation, you first call `useDisableOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDisableOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDisableOrganizationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDisableOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DisableOrganizationMutation,
        DisableOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DisableOrganizationMutation,
          DisableOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DisableOrganizationMutation,
    DisableOrganizationMutationVariables
  >(DisableOrganizationDocument, options);
}
export type DisableOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DisableOrganizationMutation,
  DisableOrganizationMutationVariables
>;
export const EnableOrganizationDocument = gql`
  mutation EnableOrganization($id: Int!) {
    enableOrganization(id: $id) {
      success
    }
  }
`;

/**
 * __useEnableOrganizationMutation__
 *
 * To run a mutation, you first call `useEnableOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEnableOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEnableOrganizationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useEnableOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        EnableOrganizationMutation,
        EnableOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          EnableOrganizationMutation,
          EnableOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    EnableOrganizationMutation,
    EnableOrganizationMutationVariables
  >(EnableOrganizationDocument, options);
}
export type EnableOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  EnableOrganizationMutation,
  EnableOrganizationMutationVariables
>;
export const OrganizationDocument = gql`
  query organization($id: Int!) {
    organization(id: $id) {
      name
      address
      accountLimit
      startDate
      endDate
      subscriptionTypeId
      totalUsers
      alias
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a Vue component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationQuery({
 *   id: // value for 'id'
 * });
 */
export function useOrganizationQuery(
  variables:
    | OrganizationQueryVariables
    | VueCompositionApi.Ref<OrganizationQueryVariables>
    | ReactiveFunction<OrganizationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationQuery,
        OrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationQuery,
          OrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationQuery,
          OrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationQuery,
    OrganizationQueryVariables
  >(OrganizationDocument, variables, options);
}
export type OrganizationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const OrganizationsDocument = gql`
  query Organizations(
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getTotalCount: Boolean
  ) {
    organizations(
      filter: $filter
      page: $page
      rows: $rows
      sortBy: $sortBy
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        name
        statusId
        accountLimit
        startDate
        endDate
        totalUsers
        alias
        owner {
          id
          email
          firstName
          lastName
          avatar
        }
        subscriptionType {
          id
          name
        }
      }
      totalCount
    }
  }
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationsQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useOrganizationsQuery(
  variables:
    | OrganizationsQueryVariables
    | VueCompositionApi.Ref<OrganizationsQueryVariables>
    | ReactiveFunction<OrganizationsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationsQuery,
        OrganizationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationsQuery,
          OrganizationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationsQuery,
          OrganizationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >(OrganizationsDocument, variables, options);
}
export type OrganizationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const ResetOrganizationOwner2FaDocument = gql`
  mutation ResetOrganizationOwner2FA($organizationId: Int!) {
    resetOrganizationOwner2FA(organizationId: $organizationId) {
      success
    }
  }
`;

/**
 * __useResetOrganizationOwner2FaMutation__
 *
 * To run a mutation, you first call `useResetOrganizationOwner2FaMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetOrganizationOwner2FaMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetOrganizationOwner2FaMutation({
 *   variables: {
 *     organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useResetOrganizationOwner2FaMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ResetOrganizationOwner2FaMutation,
        ResetOrganizationOwner2FaMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ResetOrganizationOwner2FaMutation,
          ResetOrganizationOwner2FaMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ResetOrganizationOwner2FaMutation,
    ResetOrganizationOwner2FaMutationVariables
  >(ResetOrganizationOwner2FaDocument, options);
}
export type ResetOrganizationOwner2FaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ResetOrganizationOwner2FaMutation,
  ResetOrganizationOwner2FaMutationVariables
>;
export const SaveOrganizationDocument = gql`
  mutation SaveOrganization($input: SaveOrganizationInput!) {
    saveOrganization(input: $input) {
      success
    }
  }
`;

/**
 * __useSaveOrganizationMutation__
 *
 * To run a mutation, you first call `useSaveOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveOrganizationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveOrganizationMutation,
        SaveOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveOrganizationMutation,
          SaveOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveOrganizationMutation,
    SaveOrganizationMutationVariables
  >(SaveOrganizationDocument, options);
}
export type SaveOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveOrganizationMutation,
  SaveOrganizationMutationVariables
>;
export const DeletePermissionProfileDocument = gql`
  mutation DeletePermissionProfile($id: Int!) {
    deletePermissionProfile(id: $id) {
      success
    }
  }
`;

/**
 * __useDeletePermissionProfileMutation__
 *
 * To run a mutation, you first call `useDeletePermissionProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePermissionProfileMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionProfileMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeletePermissionProfileMutation,
        DeletePermissionProfileMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeletePermissionProfileMutation,
          DeletePermissionProfileMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeletePermissionProfileMutation,
    DeletePermissionProfileMutationVariables
  >(DeletePermissionProfileDocument, options);
}
export type DeletePermissionProfileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeletePermissionProfileMutation,
  DeletePermissionProfileMutationVariables
>;
export const PermissionProfileDocument = gql`
  query PermissionProfile($id: Int!) {
    permissionProfile(id: $id) {
      name
      contactGroupOptions {
        id
        name
      }
      contactGroups {
        id
        permissionTypeId
      }
    }
  }
`;

/**
 * __usePermissionProfileQuery__
 *
 * To run a query within a Vue component, call `usePermissionProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePermissionProfileQuery({
 *   id: // value for 'id'
 * });
 */
export function usePermissionProfileQuery(
  variables:
    | PermissionProfileQueryVariables
    | VueCompositionApi.Ref<PermissionProfileQueryVariables>
    | ReactiveFunction<PermissionProfileQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        PermissionProfileQuery,
        PermissionProfileQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          PermissionProfileQuery,
          PermissionProfileQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          PermissionProfileQuery,
          PermissionProfileQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    PermissionProfileQuery,
    PermissionProfileQueryVariables
  >(PermissionProfileDocument, variables, options);
}
export type PermissionProfileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  PermissionProfileQuery,
  PermissionProfileQueryVariables
>;
export const PermissionProfilesDocument = gql`
  query PermissionProfiles(
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getTotalCount: Boolean
  ) {
    permissionProfiles(
      filter: $filter
      page: $page
      rows: $rows
      sortBy: $sortBy
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        name
        totalContactGroups
      }
      totalCount
    }
  }
`;

/**
 * __usePermissionProfilesQuery__
 *
 * To run a query within a Vue component, call `usePermissionProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionProfilesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePermissionProfilesQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function usePermissionProfilesQuery(
  variables:
    | PermissionProfilesQueryVariables
    | VueCompositionApi.Ref<PermissionProfilesQueryVariables>
    | ReactiveFunction<PermissionProfilesQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        PermissionProfilesQuery,
        PermissionProfilesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          PermissionProfilesQuery,
          PermissionProfilesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          PermissionProfilesQuery,
          PermissionProfilesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    PermissionProfilesQuery,
    PermissionProfilesQueryVariables
  >(PermissionProfilesDocument, variables, options);
}
export type PermissionProfilesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  PermissionProfilesQuery,
  PermissionProfilesQueryVariables
>;
export const SavePermissionProfileDocument = gql`
  mutation SavePermissionProfile($input: SavePermissionProfileInput!) {
    savePermissionProfile(input: $input) {
      success
    }
  }
`;

/**
 * __useSavePermissionProfileMutation__
 *
 * To run a mutation, you first call `useSavePermissionProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSavePermissionProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSavePermissionProfileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSavePermissionProfileMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SavePermissionProfileMutation,
        SavePermissionProfileMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SavePermissionProfileMutation,
          SavePermissionProfileMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SavePermissionProfileMutation,
    SavePermissionProfileMutationVariables
  >(SavePermissionProfileDocument, options);
}
export type SavePermissionProfileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SavePermissionProfileMutation,
  SavePermissionProfileMutationVariables
>;
export const CleanupUnusedAvatarsDocument = gql`
  mutation CleanupUnusedAvatars {
    cleanupUnusedAvatars {
      success
    }
  }
`;

/**
 * __useCleanupUnusedAvatarsMutation__
 *
 * To run a mutation, you first call `useCleanupUnusedAvatarsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCleanupUnusedAvatarsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCleanupUnusedAvatarsMutation();
 */
export function useCleanupUnusedAvatarsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CleanupUnusedAvatarsMutation,
        CleanupUnusedAvatarsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CleanupUnusedAvatarsMutation,
          CleanupUnusedAvatarsMutationVariables
        >
      > = {}
) {
  return VueApolloComposable.useMutation<
    CleanupUnusedAvatarsMutation,
    CleanupUnusedAvatarsMutationVariables
  >(CleanupUnusedAvatarsDocument, options);
}
export type CleanupUnusedAvatarsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CleanupUnusedAvatarsMutation,
  CleanupUnusedAvatarsMutationVariables
>;
export const UploadProAccountsDocument = gql`
  query UploadProAccounts($data: String!, $countryCode: String!) {
    uploadProAccounts(excel: $data, countryCode: $countryCode) {
      accounts {
        department
        email
        name
        phone
        role
        statusId
      }
    }
  }
`;

/**
 * __useUploadProAccountsQuery__
 *
 * To run a query within a Vue component, call `useUploadProAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadProAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUploadProAccountsQuery({
 *   data: // value for 'data'
 *   countryCode: // value for 'countryCode'
 * });
 */
export function useUploadProAccountsQuery(
  variables:
    | UploadProAccountsQueryVariables
    | VueCompositionApi.Ref<UploadProAccountsQueryVariables>
    | ReactiveFunction<UploadProAccountsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        UploadProAccountsQuery,
        UploadProAccountsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          UploadProAccountsQuery,
          UploadProAccountsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          UploadProAccountsQuery,
          UploadProAccountsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    UploadProAccountsQuery,
    UploadProAccountsQueryVariables
  >(UploadProAccountsDocument, variables, options);
}
export type UploadProAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  UploadProAccountsQuery,
  UploadProAccountsQueryVariables
>;
export const CreateJobTitleDocument = gql`
  mutation CreateJobTitle($input: CreateOrganizationJobTitleInput!) {
    createOrganizationJobTitle(input: $input) {
      organizationJobTitle {
        id
        name
      }
    }
  }
`;

/**
 * __useCreateJobTitleMutation__
 *
 * To run a mutation, you first call `useCreateJobTitleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobTitleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateJobTitleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobTitleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateJobTitleMutation,
        CreateJobTitleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateJobTitleMutation,
          CreateJobTitleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateJobTitleMutation,
    CreateJobTitleMutationVariables
  >(CreateJobTitleDocument, options);
}
export type CreateJobTitleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateJobTitleMutation,
  CreateJobTitleMutationVariables
>;
export const CreateOrganizationSectionDocument = gql`
  mutation CreateOrganizationSection($input: CreateOrganizationSectionInput!) {
    createOrganizationSection(input: $input) {
      organizationSection {
        id
        name
      }
    }
  }
`;

/**
 * __useCreateOrganizationSectionMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationSectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationSectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrganizationSectionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationSectionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOrganizationSectionMutation,
        CreateOrganizationSectionMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOrganizationSectionMutation,
          CreateOrganizationSectionMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOrganizationSectionMutation,
    CreateOrganizationSectionMutationVariables
  >(CreateOrganizationSectionDocument, options);
}
export type CreateOrganizationSectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateOrganizationSectionMutation,
  CreateOrganizationSectionMutationVariables
>;
export const DeleteOrganizationJobTitleDocument = gql`
  mutation DeleteOrganizationJobTitle($id: Int!) {
    deleteOrganizationJobTitle(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteOrganizationJobTitleMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationJobTitleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationJobTitleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationJobTitleMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationJobTitleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOrganizationJobTitleMutation,
        DeleteOrganizationJobTitleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOrganizationJobTitleMutation,
          DeleteOrganizationJobTitleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOrganizationJobTitleMutation,
    DeleteOrganizationJobTitleMutationVariables
  >(DeleteOrganizationJobTitleDocument, options);
}
export type DeleteOrganizationJobTitleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteOrganizationJobTitleMutation,
  DeleteOrganizationJobTitleMutationVariables
>;
export const DeleteOrganizationSectionDocument = gql`
  mutation DeleteOrganizationSection($id: Int!) {
    deleteOrganizationSection(id: $id) {
      success
    }
  }
`;

/**
 * __useDeleteOrganizationSectionMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationSectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationSectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationSectionMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationSectionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOrganizationSectionMutation,
        DeleteOrganizationSectionMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOrganizationSectionMutation,
          DeleteOrganizationSectionMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOrganizationSectionMutation,
    DeleteOrganizationSectionMutationVariables
  >(DeleteOrganizationSectionDocument, options);
}
export type DeleteOrganizationSectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteOrganizationSectionMutation,
  DeleteOrganizationSectionMutationVariables
>;
export const DeleteProAccountDocument = gql`
  mutation DeleteProAccount($email: String!) {
    deleteProAccount(email: $email) {
      success
    }
  }
`;

/**
 * __useDeleteProAccountMutation__
 *
 * To run a mutation, you first call `useDeleteProAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProAccountMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useDeleteProAccountMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteProAccountMutation,
        DeleteProAccountMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteProAccountMutation,
          DeleteProAccountMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteProAccountMutation,
    DeleteProAccountMutationVariables
  >(DeleteProAccountDocument, options);
}
export type DeleteProAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteProAccountMutation,
  DeleteProAccountMutationVariables
>;
export const OrganizationJobTitlesDocument = gql`
  query OrganizationJobTitles {
    organizationJobTitles {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useOrganizationJobTitlesQuery__
 *
 * To run a query within a Vue component, call `useOrganizationJobTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationJobTitlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationJobTitlesQuery();
 */
export function useOrganizationJobTitlesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationJobTitlesQuery,
        OrganizationJobTitlesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationJobTitlesQuery,
          OrganizationJobTitlesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationJobTitlesQuery,
          OrganizationJobTitlesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationJobTitlesQuery,
    OrganizationJobTitlesQueryVariables
  >(OrganizationJobTitlesDocument, {}, options);
}
export type OrganizationJobTitlesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationJobTitlesQuery,
  OrganizationJobTitlesQueryVariables
>;
export const PreSignedUploadUrlDocument = gql`
  query PreSignedUploadURL($fileName: String!) {
    preSignedUploadURL(fileName: $fileName) {
      url
    }
  }
`;

/**
 * __usePreSignedUploadUrlQuery__
 *
 * To run a query within a Vue component, call `usePreSignedUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreSignedUploadUrlQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePreSignedUploadUrlQuery({
 *   fileName: // value for 'fileName'
 * });
 */
export function usePreSignedUploadUrlQuery(
  variables:
    | PreSignedUploadUrlQueryVariables
    | VueCompositionApi.Ref<PreSignedUploadUrlQueryVariables>
    | ReactiveFunction<PreSignedUploadUrlQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        PreSignedUploadUrlQuery,
        PreSignedUploadUrlQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          PreSignedUploadUrlQuery,
          PreSignedUploadUrlQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          PreSignedUploadUrlQuery,
          PreSignedUploadUrlQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    PreSignedUploadUrlQuery,
    PreSignedUploadUrlQueryVariables
  >(PreSignedUploadUrlDocument, variables, options);
}
export type PreSignedUploadUrlQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  PreSignedUploadUrlQuery,
  PreSignedUploadUrlQueryVariables
>;
export const ProAccountContactGroupsDocument = gql`
  query ProAccountContactGroups($id: Int!) {
    proAccountContactGroups(id: $id) {
      contactGroupOptions {
        id
        name
      }
      contactGroups {
        id
        permissionTypeId
      }
      permissionProfileOptions {
        id
        name
      }
    }
  }
`;

/**
 * __useProAccountContactGroupsQuery__
 *
 * To run a query within a Vue component, call `useProAccountContactGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProAccountContactGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProAccountContactGroupsQuery({
 *   id: // value for 'id'
 * });
 */
export function useProAccountContactGroupsQuery(
  variables:
    | ProAccountContactGroupsQueryVariables
    | VueCompositionApi.Ref<ProAccountContactGroupsQueryVariables>
    | ReactiveFunction<ProAccountContactGroupsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ProAccountContactGroupsQuery,
        ProAccountContactGroupsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProAccountContactGroupsQuery,
          ProAccountContactGroupsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProAccountContactGroupsQuery,
          ProAccountContactGroupsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProAccountContactGroupsQuery,
    ProAccountContactGroupsQueryVariables
  >(ProAccountContactGroupsDocument, variables, options);
}
export type ProAccountContactGroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ProAccountContactGroupsQuery,
  ProAccountContactGroupsQueryVariables
>;
export const ProAccountCredentialDocument = gql`
  mutation proAccountCredential(
    $email: String!
    $ignoreAlreadyCreated: Boolean
  ) {
    proAccountCredential(
      email: $email
      ignoreAlreadyCreated: $ignoreAlreadyCreated
    ) {
      generatedPassword
    }
  }
`;

/**
 * __useProAccountCredentialMutation__
 *
 * To run a mutation, you first call `useProAccountCredentialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useProAccountCredentialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useProAccountCredentialMutation({
 *   variables: {
 *     email: // value for 'email'
 *     ignoreAlreadyCreated: // value for 'ignoreAlreadyCreated'
 *   },
 * });
 */
export function useProAccountCredentialMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ProAccountCredentialMutation,
        ProAccountCredentialMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ProAccountCredentialMutation,
          ProAccountCredentialMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ProAccountCredentialMutation,
    ProAccountCredentialMutationVariables
  >(ProAccountCredentialDocument, options);
}
export type ProAccountCredentialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ProAccountCredentialMutation,
  ProAccountCredentialMutationVariables
>;
export const ProAccountProfileContactGroupsDocument = gql`
  query ProAccountProfileContactGroups($id: Int!) {
    proAccountProfileContactGroups(id: $id) {
      id
      permissionTypeId
    }
  }
`;

/**
 * __useProAccountProfileContactGroupsQuery__
 *
 * To run a query within a Vue component, call `useProAccountProfileContactGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProAccountProfileContactGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProAccountProfileContactGroupsQuery({
 *   id: // value for 'id'
 * });
 */
export function useProAccountProfileContactGroupsQuery(
  variables:
    | ProAccountProfileContactGroupsQueryVariables
    | VueCompositionApi.Ref<ProAccountProfileContactGroupsQueryVariables>
    | ReactiveFunction<ProAccountProfileContactGroupsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ProAccountProfileContactGroupsQuery,
        ProAccountProfileContactGroupsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProAccountProfileContactGroupsQuery,
          ProAccountProfileContactGroupsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProAccountProfileContactGroupsQuery,
          ProAccountProfileContactGroupsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProAccountProfileContactGroupsQuery,
    ProAccountProfileContactGroupsQueryVariables
  >(ProAccountProfileContactGroupsDocument, variables, options);
}
export type ProAccountProfileContactGroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ProAccountProfileContactGroupsQuery,
  ProAccountProfileContactGroupsQueryVariables
>;
export const ProAccountDocument = gql`
  query proAccount($id: Int!) {
    proAccount(id: $id) {
      firstName
      lastName
      email
      mobileNumber
      section
      jobTitle
      avatar
      permissionTypeId
      sectionOptions {
        id
        name
      }
      jobTitleOptions {
        id
        name
      }
      totalContactGroups
    }
  }
`;

/**
 * __useProAccountQuery__
 *
 * To run a query within a Vue component, call `useProAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProAccountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProAccountQuery({
 *   id: // value for 'id'
 * });
 */
export function useProAccountQuery(
  variables:
    | ProAccountQueryVariables
    | VueCompositionApi.Ref<ProAccountQueryVariables>
    | ReactiveFunction<ProAccountQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ProAccountQuery,
        ProAccountQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProAccountQuery,
          ProAccountQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProAccountQuery,
          ProAccountQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProAccountQuery,
    ProAccountQueryVariables
  >(ProAccountDocument, variables, options);
}
export type ProAccountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ProAccountQuery,
  ProAccountQueryVariables
>;
export const ProAccountsDocument = gql`
  query ProAccounts(
    $page: Int
    $rows: Int
    $filter: String
    $sortBy: SortBy
    $getTotalCount: Boolean
  ) {
    proAccounts(
      filter: $filter
      page: $page
      rows: $rows
      sortBy: $sortBy
      getTotalCount: $getTotalCount
    ) {
      result {
        id
        firstName
        lastName
        isPending
        mobileNumber
        email
        section
        jobTitle
        avatar
        permissionTypeId
      }
      totalCount
    }
  }
`;

/**
 * __useProAccountsQuery__
 *
 * To run a query within a Vue component, call `useProAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProAccountsQuery({
 *   page: // value for 'page'
 *   rows: // value for 'rows'
 *   filter: // value for 'filter'
 *   sortBy: // value for 'sortBy'
 *   getTotalCount: // value for 'getTotalCount'
 * });
 */
export function useProAccountsQuery(
  variables:
    | ProAccountsQueryVariables
    | VueCompositionApi.Ref<ProAccountsQueryVariables>
    | ReactiveFunction<ProAccountsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        ProAccountsQuery,
        ProAccountsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ProAccountsQuery,
          ProAccountsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ProAccountsQuery,
          ProAccountsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ProAccountsQuery,
    ProAccountsQueryVariables
  >(ProAccountsDocument, variables, options);
}
export type ProAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ProAccountsQuery,
  ProAccountsQueryVariables
>;
export const SaveProAccountDocument = gql`
  mutation SaveProAccount($input: SaveProAccountInput!) {
    saveProAccount(input: $input) {
      success
      generatedPassword
    }
  }
`;

/**
 * __useSaveProAccountMutation__
 *
 * To run a mutation, you first call `useSaveProAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveProAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveProAccountMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveProAccountMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveProAccountMutation,
        SaveProAccountMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveProAccountMutation,
          SaveProAccountMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveProAccountMutation,
    SaveProAccountMutationVariables
  >(SaveProAccountDocument, options);
}
export type SaveProAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveProAccountMutation,
  SaveProAccountMutationVariables
>;
export const SaveProAccountsDocument = gql`
  mutation SaveProAccounts($input: SaveProAccountsInput!) {
    saveProAccounts(input: $input) {
      success
      generatedAccounts {
        password
        name
      }
    }
  }
`;

/**
 * __useSaveProAccountsMutation__
 *
 * To run a mutation, you first call `useSaveProAccountsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveProAccountsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveProAccountsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSaveProAccountsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        SaveProAccountsMutation,
        SaveProAccountsMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          SaveProAccountsMutation,
          SaveProAccountsMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    SaveProAccountsMutation,
    SaveProAccountsMutationVariables
  >(SaveProAccountsDocument, options);
}
export type SaveProAccountsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SaveProAccountsMutation,
  SaveProAccountsMutationVariables
>;
export const OrganizationSectionsDocument = gql`
  query OrganizationSections {
    organizationSections {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useOrganizationSectionsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSectionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationSectionsQuery();
 */
export function useOrganizationSectionsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationSectionsQuery,
        OrganizationSectionsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationSectionsQuery,
          OrganizationSectionsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationSectionsQuery,
          OrganizationSectionsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationSectionsQuery,
    OrganizationSectionsQueryVariables
  >(OrganizationSectionsDocument, {}, options);
}
export type OrganizationSectionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  OrganizationSectionsQuery,
  OrganizationSectionsQueryVariables
>;
export const UpdateAccountPermissionDocument = gql`
  mutation UpdateAccountPermission($input: UpdateAccountPermissionInput!) {
    updateAccountPermission(input: $input) {
      success
    }
  }
`;

/**
 * __useUpdateAccountPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPermissionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPermissionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAccountPermissionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPermissionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateAccountPermissionMutation,
        UpdateAccountPermissionMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateAccountPermissionMutation,
          UpdateAccountPermissionMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateAccountPermissionMutation,
    UpdateAccountPermissionMutationVariables
  >(UpdateAccountPermissionDocument, options);
}
export type UpdateAccountPermissionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateAccountPermissionMutation,
  UpdateAccountPermissionMutationVariables
>;
export const ValidateAccountsDocument = gql`
  query ValidateAccounts(
    $accounts: [ValidateAccountInput!]!
    $countryCode: String!
  ) {
    validateAccounts(accounts: $accounts, countryCode: $countryCode) {
      accounts {
        department
        email
        name
        phone
        role
        statusId
      }
    }
  }
`;

/**
 * __useValidateAccountsQuery__
 *
 * To run a query within a Vue component, call `useValidateAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useValidateAccountsQuery({
 *   accounts: // value for 'accounts'
 *   countryCode: // value for 'countryCode'
 * });
 */
export function useValidateAccountsQuery(
  variables:
    | ValidateAccountsQueryVariables
    | VueCompositionApi.Ref<ValidateAccountsQueryVariables>
    | ReactiveFunction<ValidateAccountsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ValidateAccountsQuery,
        ValidateAccountsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ValidateAccountsQuery,
          ValidateAccountsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ValidateAccountsQuery,
          ValidateAccountsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ValidateAccountsQuery,
    ValidateAccountsQueryVariables
  >(ValidateAccountsDocument, variables, options);
}
export type ValidateAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ValidateAccountsQuery,
  ValidateAccountsQueryVariables
>;
export const VerifyAdminDocument = gql`
  mutation VerifyAdmin($input: VerifyAdminInput!) {
    verifyAdmin(input: $input) {
      user {
        id
      }
      token
    }
  }
`;

/**
 * __useVerifyAdminMutation__
 *
 * To run a mutation, you first call `useVerifyAdminMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAdminMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useVerifyAdminMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useVerifyAdminMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        VerifyAdminMutation,
        VerifyAdminMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          VerifyAdminMutation,
          VerifyAdminMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    VerifyAdminMutation,
    VerifyAdminMutationVariables
  >(VerifyAdminDocument, options);
}
export type VerifyAdminMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  VerifyAdminMutation,
  VerifyAdminMutationVariables
>;
