
import { defineComponent, PropType } from 'vue';
import Breadcrumb from 'primevue/breadcrumb';
import { IMenuModel } from '@/shared/interface/IMenuModel';

export default defineComponent({
  components: {
    Breadcrumb,
  },
  props: {
    menuItems: {
      type: Object as PropType<IMenuModel>,
      default: () => ({}),
    },
    home: {
      type: Object as PropType<IMenuModel>,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
});
