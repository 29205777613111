
import { defineComponent, ref } from "vue";
import Textarea from "primevue/textarea";

export default defineComponent({
  components: {
    PrimeTextarea: Textarea
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    maxRows: {
      type: Number,
      default: 2
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<string>(props.value);
    return {
      data
    };
  },
  methods: {
    onInput() {
      this.$emit("update:value", this.data);
    },
    focus() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.inputTextarea as any).$el.focus();
    }
  }
});
