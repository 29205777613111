import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Checkbox, {
      modelValue: _ctx.input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      binary: true,
      onChange: _ctx.onValueChange
    }, null, 8, ["modelValue", "onChange"]),
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.class)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 64))
}