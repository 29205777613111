import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeButton = _resolveComponent("PrimeButton")!

  return (_openBlock(), _createBlock(_component_PrimeButton, {
    icon: _ctx.icon,
    class: "primary",
    badge: _ctx.badge,
    label: _ctx.label,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["icon", "badge", "label", "disabled"]))
}