import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeMenu = _resolveComponent("PrimeMenu")!

  return (_openBlock(), _createBlock(_component_PrimeMenu, {
    ref: "menuRef",
    model: _ctx.menuItems,
    class: _normalizeClass(_ctx.sideBarContainerClass),
    popup: _ctx.hasPopUp
  }, null, 8, ["model", "class", "popup"]))
}