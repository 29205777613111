
import { defineComponent, ref } from "vue";
import Checkbox from "primevue/checkbox";

export default defineComponent({
  components: {
    Checkbox
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    class: {
      type: String,
      default: ""
    }
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const input = ref<boolean>(props.value);

    const onValueChange = () => {
      emit("update:value", input.value);
    };

    return {
      input,
      onValueChange
    };
  }
});
