
import { defineComponent, PropType } from "vue";
import Dropdown from "primevue/dropdown";
import usePropsWrapper from "@/composables/use-props-wrapper";

export default defineComponent({
  components: {
    Dropdown
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    options: {
      type: Array as PropType<unknown[]>,
      default: () => []
    },
    optionLabel: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    dropDownClass: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    appendTo: {
      type: String,
      default: "body"
    },
    editable: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: false
    },
    filterFields: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    optionValue: {
      type: String,
      default: null
    }
  },
  emits: ["update:value", "value-changed"],
  setup(props, { emit }) {
    const onChange = (event: { value: string | number }) => {
      if (event.value === "string") emit("value-changed", event.value.trim());
      else emit("value-changed", event.value);
    };
    return {
      onChange,
      input: usePropsWrapper(props, emit, "value")
    };
  }
});
