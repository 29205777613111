import { createI18n } from "vue-i18n";
import us from "@/plugins/i18n/us.json";
import se from "@/plugins/i18n/se.json";

export const i18n = createI18n({
  locale: "us",
  fallbackLocale: "us",
  messages: { us, se },
  legacy: false
});
