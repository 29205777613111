import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/flag_placeholder.png'


const _hoisted_1 = {
  key: 0,
  class: "c-textbox-label"
}
const _hoisted_2 = {
  key: 0,
  class: "c-textbox-label-required"
}
const _hoisted_3 = { class: "c-mobile-number-container" }
const _hoisted_4 = {
  key: 0,
  class: "c-mobile-number"
}
const _hoisted_5 = { class: "c-textbox-error" }
const _hoisted_6 = { class: "c-country-option" }
const _hoisted_7 = { class: "c-country-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_base_listbox = _resolveComponent("base-listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["c-textbox", { 'is-invalid': _ctx.errorMessage }])
    }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required && !_ctx.data)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(!_ctx.isCountryCode ? 'c-country-code' : 'c-country-code full-row')
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: _normalizeClass(`c-country-flag flag flag-${_ctx.selectedCountry.country}`)
          }, null, 2),
          _createVNode(_component_InputText, {
            modelValue: _ctx.countryCodeData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.countryCodeData) = $event)),
            autocomplete: "off",
            type: 'text',
            disabled: _ctx.countryCodeDisabled,
            readonly: true,
            onFocus: _ctx.showCountryCodes
          }, null, 8, ["modelValue", "disabled", "onFocus"])
        ], 2),
        (!_ctx.isCountryCode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_InputText, {
                ref: "inputMobileNumber",
                modelValue: _ctx.mobileNumberData,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mobileNumberData) = $event)),
                autocomplete: "off",
                disabled: _ctx.disabled,
                readonly: _ctx.readonly,
                autofocus: _ctx.autofocus,
                onInput: _ctx.onInput,
                onKeypress: _ctx.onKeyPress,
                onFocusin: _ctx.closeCountryCodeForm,
                onFocusout: _ctx.closeCountryCodeForm
              }, null, 8, ["modelValue", "disabled", "readonly", "autofocus", "onInput", "onKeypress", "onFocusin", "onFocusout"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1)
    ], 2),
    _createVNode(_component_OverlayPanel, {
      ref: "countryCodeOptions",
      class: "c-country-code-options-container",
      onHide: _ctx.focusMobileNumber
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_listbox, {
          data: _ctx.countryOptions,
          selected: _ctx.selectedCountry,
          onValueChanged: _ctx.countrySelected
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: _normalizeClass(`flag flag-${slotProps.data.country}`)
              }, null, 2),
              _createElementVNode("div", _hoisted_7, _toDisplayString(slotProps.data.name) + " +" + _toDisplayString(slotProps.data.code), 1)
            ])
          ]),
          _: 1
        }, 8, ["data", "selected", "onValueChanged"])
      ]),
      _: 1
    }, 8, ["onHide"])
  ], 64))
}