import { RouteRecordRaw } from 'vue-router';
import { RouteName } from '@/shared/constants/route-name';
import { FrontLayout } from '@/layouts';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/verify-account/:code',
    name: RouteName.VERIFY_ACCOUNT,
    component: () =>
      import(
        /* webpackChunkName: "organization-verify-account" */ '../views/verify-account.vue'
      ),
    meta: { layout: FrontLayout },
  },
];

export default routes;
