import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo-light.svg'
import _imports_1 from '@/assets/logo-icon-light.svg'


const _hoisted_1 = { class: "c-logo-container" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "logo",
  alt: ""
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "logo-icon",
  alt: ""
}
const _hoisted_4 = { class: "c-navigation-container" }
const _hoisted_5 = { class: "c-navigation-label" }
const _hoisted_6 = { class: "c-version-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-sidebar", { 'is-collapsed': _ctx.isCollapsed }])
  }, [
    _createElementVNode("span", {
      class: "c-toggle-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSideBar && _ctx.toggleSideBar(...args))),
      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSideBar && _ctx.toggleSideBar(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(`${_ctx.isCollapsed ? 'pi pi-angle-right' : 'pi pi-angle-left'}`)
      }, null, 2)
    ], 32),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : (_openBlock(), _createElementBlock("img", _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: (item.label as string)
          }, [
            _createVNode(_component_router_link, {
              to: item.to ?? '',
              class: _normalizeClass(["c-navigation-link", { 'is-active': item.page === _ctx.curentPage }])
            }, {
              default: _withCtx(() => [
                (_ctx.isCollapsed)
                  ? _withDirectives((_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["pi", item.icon])
                    }, null, 2)), [
                      [
                        _directive_tooltip,
                        {
                class: 'c-sidebar-tooltip',
                value: _ctx.renderItemLabel(item.label)
              },
                        void 0,
                        { left: true }
                      ]
                    ])
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["pi", item.icon])
                    }, null, 2)),
                _createElementVNode("span", _hoisted_5, _toDisplayString((item.label as ComputedRef).value), 1)
              ]),
              _: 2
            }, 1032, ["to", "class"])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", null, "v" + _toDisplayString(_ctx.headerDetails.version), 1)
      ])
    ])
  ], 2))
}