import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")!

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    visible: _ctx.display,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.display) = $event)),
    modal: true,
    closable: false,
    class: "dialog-preloader"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ProgressSpinner, { class: "spinner" })
    ]),
    _: 1
  }, 8, ["visible"]))
}