import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListBox = _resolveComponent("ListBox")!

  return (_openBlock(), _createBlock(_component_ListBox, {
    modelValue: _ctx.selectedOption,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
    "option-label": "name",
    options: _ctx.data,
    "empty-message": _ctx.emptyMessage,
    class: _normalizeClass({ 'is-read-only': _ctx.isReadOnly }),
    onChange: _ctx.selectDistribution
  }, {
    option: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "option", {
        data: slotProps.option
      })
    ]),
    _: 3
  }, 8, ["modelValue", "options", "empty-message", "class", "onChange"]))
}