
import { ComputedRef, defineComponent, ref, watch } from "vue";
import useAdmin from "@/composables/use-admin";
import Tooltip from "primevue/tooltip";
import { IMenuModel } from "@/shared/interface";
import { RouteName } from "@/shared/constants/route-name";
import { useRoute } from "vue-router";
import useHeaderDetails from "@/composables/use-header-details";

export default defineComponent({
  directives: {
    tooltip: Tooltip
  },
  setup() {
    const { currentMenuItems } = useAdmin();
    const { headerDetails } = useHeaderDetails();

    const isCollapsed = ref(false);
    const curentPage = ref("");
    const route = useRoute();

    const menuItems: IMenuModel[] = currentMenuItems.value[0].items ?? [];

    const tooltipOptions = {
      value: "",
      class: "c-sidebar-tooltip"
    };

    return {
      isCollapsed,
      menuItems,
      curentPage,
      tooltipOptions,
      route,
      headerDetails
    };
  },
  mounted() {
    setTimeout(() => {
      this.setCurrentPage();
    }, 200);

    watch(
      () => this.route.name,
      async () => {
        this.setCurrentPage();
      }
    );
  },
  methods: {
    setCurrentPage() {
      let name = this.route.name?.toString() ?? "";
      if (
        name === RouteName.ORGANIZATION_DETAILS ||
        name === RouteName.ORGANIZATION_HISTORY
      ) {
        name = RouteName.ORGANIZATIONS;
      } else if (name === RouteName.HISTORY || name === RouteName.ALL_HISTORY) {
        name = RouteName.HOME;
      }
      this.curentPage = name;
    },
    toggleSideBar() {
      this.isCollapsed = !this.isCollapsed;
    },
    renderItemLabel(label: string | ComputedRef<string> | undefined) {
      return typeof label === "string" ? label : label?.value;
    }
  }
});
