
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import Menu from 'primevue/menu';
import { IMenuModel } from '@/shared/interface/IMenuModel';

export default defineComponent({
  components: {
    PrimeMenu: Menu,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Object as PropType<IMenuModel[]>,
      default: null,
    },
    activeMenu: {
      type: Boolean,
      default: true,
    },
    hasPopUp: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Event,
      default: null,
    },
  },
  setup(props) {
    const menuRef = ref<Menu>();

    const sideBarContainerClass = computed(() => [
      {
        collapsed: !props.activeMenu,
      },
    ]);

    const toggleWatcher = watch(
      () => props.toggle,
      () => menuRef.value?.toggle(props.toggle),
    );

    return { sideBarContainerClass, toggleWatcher, menuRef };
  },
  methods: {
    linkClass(routerProps: { isActive: boolean; isExactActive: boolean }) {
      return [
        'p-menuitem-link',
        {
          'router-link-active': routerProps && routerProps.isActive,
          'router-link-active-exact': routerProps && routerProps.isExactActive,
        },
      ];
    },
  },
});
