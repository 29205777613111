import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/organizations",
    name: RouteName.ORGANIZATIONS,
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "../views/organizations-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/organizations/:id",
    name: RouteName.ORGANIZATION_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "organization-detail" */ "../views/organization-detail-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/organizations/:id/history/:type",
    name: RouteName.ORGANIZATION_HISTORY,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../../home/views/home-history.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  }
];

export default routes;
