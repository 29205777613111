
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { usePreloader, useAuth } from "./composables";
import { RouteName } from "./shared/constants/route-name";

export default defineComponent({
  setup() {
    const { isLoading } = usePreloader();
    const router = useRouter();

    const route = useRoute();

    const layout = computed(() => route.meta.layout);
    const { checkExpiration } = useAuth();
    const isExpired = checkExpiration();

    if (isExpired) {
      router.push({ name: RouteName.LOGIN });
    }

    return {
      layout,
      isLoading
    };
  }
});
