
import { PermissionType } from "@/core/enums";
import { defineComponent } from "vue";
import Tooltip from "primevue/tooltip";

enum ProfilePermissionTooltip {
  HIDDEN_CAN_SEE_OTHERS = "tooltip.hidden-can-see-others",
  VISIBLE_CANT_SEE_OTHERS = "tooltip.visible-cant-see-others",
  VISIBLE_CAN_SEE_OTHERS = "tooltip.visible-can-see-others",
  HIDDEN_CANT_SEE_OTHERS = "tooltip.hidden-cant-see-others"
}

export default defineComponent({
  directives: {
    tooltip: Tooltip
  },
  props: {
    permissionTypeId: {
      type: Number,
      default: PermissionType.VISIBLE_CAN_SEE_OTHERS
    }
  },
  setup(props) {
    const eyeIconClass =
      props.permissionTypeId === PermissionType.VISIBLE_CAN_SEE_OTHERS ||
      props.permissionTypeId === PermissionType.VISIBLE_CANT_SEE_OTHERS
        ? "pi pi-eye"
        : "pi pi-eye-slash";

    let directionClass = "";

    if (props.permissionTypeId === PermissionType.HIDDEN_CAN_SEE_OTHERS) {
      directionClass = "pi pi-eye";
    } else if (
      props.permissionTypeId === PermissionType.VISIBLE_CANT_SEE_OTHERS
    ) {
      directionClass = "pi pi-eye-slash";
    }

    let tooltip = "";

    switch (props.permissionTypeId) {
      case PermissionType.HIDDEN_CANT_SEE_OTHERS: {
        tooltip = ProfilePermissionTooltip.HIDDEN_CANT_SEE_OTHERS;
        break;
      }
      case PermissionType.HIDDEN_CAN_SEE_OTHERS: {
        tooltip = ProfilePermissionTooltip.HIDDEN_CAN_SEE_OTHERS;
        break;
      }
      case PermissionType.VISIBLE_CANT_SEE_OTHERS: {
        tooltip = ProfilePermissionTooltip.VISIBLE_CANT_SEE_OTHERS;
        break;
      }
      case PermissionType.VISIBLE_CAN_SEE_OTHERS: {
        tooltip = ProfilePermissionTooltip.VISIBLE_CAN_SEE_OTHERS;
        break;
      }
      default: {
        break;
      }
    }

    return {
      eyeIconClass,
      directionClass,
      tooltip
    };
  }
});
