import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")!

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    visible: _ctx.display,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.display) = $event)),
    position: 'center',
    header: _ctx.header,
    "dismissable-mask": _ctx.dismissableMask,
    modal: _ctx.modal,
    closable: _ctx.closable,
    draggable: false
  }, {
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["visible", "header", "dismissable-mask", "modal", "closable"]))
}