
import { defineComponent, ref, PropType } from 'vue';
import SingleOtpInput from './single-otp-input.vue';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default defineComponent({
  name: 'OtpInput', // vue component name
  components: {
    SingleOtpInput,
  },
  props: {
    numInputs: {
      type: Number,
      default: 4,
    },
    separator: {
      type: String,
      default: '**',
    },
    inputClasses: {
      type: [String, Array] as PropType<string[] | string>,
      default: '',
    },
    conditionalClass: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    inputType: {
      type: String as PropType<
        'number' | 'tel' | 'letter-numeric' | 'password'
      >,
      validator: (value: string) =>
        ['number', 'tel', 'letter-numeric', 'password'].includes(value),
      default: '',
    },
    inputmode: {
      type: String,
      validator: (value: string) =>
        ['numeric', 'text', 'tel', 'none'].includes(value),
      default: 'numeric',
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-complete', 'on-change'],
  setup(props, { emit }) {
    const activeInput = ref<number>(0);
    const otp = ref<[]>([]);
    const oldOtp = ref<[]>([]);

    const handleOnFocus = (index: number) => {
      activeInput.value = index;
    };
    const handleOnBlur = () => {
      activeInput.value = -1;
    };

    // Helper to return OTP from input
    const checkFilledAllInputs = () => {
      if (otp.value.join('').length === props.numInputs) {
        return emit('on-complete', otp.value.join(''));
      }
      return 'Wait until the user enters the required number of characters';
    };

    // Focus on input by index
    const focusInput = (input: number) => {
      activeInput.value = Math.max(Math.min(props.numInputs - 1, input), 0);
    };
    // Focus on next input
    const focusNextInput = () => {
      focusInput(activeInput.value + 1);
    };
    // Focus on previous input
    const focusPrevInput = () => {
      focusInput(activeInput.value - 1);
    };

    // Change OTP value at focused input
    const changeCodeAtFocus = (value: number | string) => {
      oldOtp.value = Object.assign([], otp.value);

      (otp.value[activeInput.value] as string | number) = value;

      if (oldOtp.value.join('') !== otp.value.join('')) {
        emit('on-change', otp.value.join(''));
        checkFilledAllInputs();
      }
    };

    // Handle pasted OTP
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnPaste = (event: any) => {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, props.numInputs - activeInput.value)
        .split('');
      if (props.inputType === 'number' && !pastedData.join('').match(/^\d+$/)) {
        return 'Invalid pasted data';
      }

      if (
        props.inputType === 'letter-numeric' &&
        !pastedData.join('').match(/^\w+$/)
      ) {
        return 'Invalid pasted data';
      }
      // Paste data from focused input onwards
      const currentCharsInOtp = otp.value.slice(0, activeInput.value);
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData);

      combinedWithPastedData.slice(0, props.numInputs).forEach((value, i) => {
        otp.value[i] = value;
      });

      focusInput(combinedWithPastedData.slice(0, props.numInputs).length);
      return checkFilledAllInputs();
    };

    const handleOnChange = (value: number) => {
      changeCodeAtFocus(value);
      focusNextInput();
    };
    const clearInput = () => {
      if (otp.value.length > 0) {
        emit('on-change', '');
      }
      otp.value = [];
      activeInput.value = 0;
    };

    // Handle cases of backspace, delete, left arrow, right arrow
    const handleOnKeyDown = (event: KeyboardEvent) => {
      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault();
          changeCodeAtFocus('');
          focusPrevInput();
          break;
        case DELETE:
          event.preventDefault();
          changeCodeAtFocus('');
          break;
        case LEFT_ARROW:
          event.preventDefault();
          focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          focusNextInput();
          break;
        default:
          break;
      }
    };

    return {
      activeInput,
      otp,
      oldOtp,
      clearInput,
      handleOnPaste,
      handleOnKeyDown,
      handleOnBlur,
      changeCodeAtFocus,
      focusInput,
      focusNextInput,
      focusPrevInput,
      handleOnFocus,
      checkFilledAllInputs,
      handleOnChange,
    };
  },
});
