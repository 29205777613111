import { IMenuModel } from "@/shared/interface/IMenuModel";
import { RouteName } from "./route-name";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

const { t } = i18n.global;

const homeLink: IMenuModel = {
  label: computed(() => t("home.overview")),
  icon: "pi pi-chart-bar",
  to: { name: RouteName.HOME },
  page: RouteName.HOME
};

const organizationLink: IMenuModel = {
  label: computed(() => t("home.organizations")),
  icon: "pi pi-building",
  to: { name: RouteName.ORGANIZATIONS },
  page: RouteName.ORGANIZATIONS
};

const freeUserLink: IMenuModel = {
  label: computed(() => t("home.free-users")),
  icon: "pi pi-users",
  to: { name: RouteName.FREE_USERS },
  page: RouteName.FREE_USERS
};

const externalContactLink: IMenuModel = {
  label: computed(() => t("home.external-contacts")),
  icon: "pi pi-book",
  to: { name: RouteName.EXTERNAL_CONTACTS },
  page: RouteName.EXTERNAL_CONTACTS
};

const announcementLink: IMenuModel = {
  label: computed(() => t("home.announcements")),
  icon: "pi pi-send",
  to: { name: RouteName.ANNOUNCEMENTS },
  page: RouteName.ANNOUNCEMENTS
};

const administratorsLink: IMenuModel = {
  label: computed(() => t("home.administrators")),
  icon: "pi pi-shield",
  to: { name: RouteName.ADMINISTRATORS },
  page: RouteName.ADMINISTRATORS
};

const accountsLink: IMenuModel = {
  label: computed(() => t("home.users")),
  icon: "pi pi-users",
  to: { name: RouteName.ACCOUNTS },
  page: RouteName.ACCOUNTS
};

const contactGroupsLink: IMenuModel = {
  label: computed(() => t("home.contact-groups")),
  icon: "pi pi-users",
  to: { name: RouteName.CONTACT_GROUPS },
  page: RouteName.CONTACT_GROUPS
};

const permissionProfilesLink: IMenuModel = {
  label: computed(() => t("home.permission-profiles")),
  icon: "pi pi-map",
  to: { name: RouteName.PERMISSION_PROFILES },
  page: RouteName.PERMISSION_PROFILES
};

const loqAdminMenuItems: IMenuModel[] = [
  {
    label: "",
    items: [homeLink, administratorsLink, organizationLink, freeUserLink]
  }
];

const organizationAdminMenuItems: IMenuModel[] = [
  {
    label: "",
    items: [
      homeLink,
      administratorsLink,
      accountsLink,
      externalContactLink,
      contactGroupsLink,
      permissionProfilesLink,
      announcementLink
    ]
  }
];

export const menuItems = {
  loqAdminMenuItems,
  organizationAdminMenuItems
};
