import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { FrontLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: RouteName.LOGIN,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/log-in.vue"),
    meta: { layout: FrontLayout }
  },
  {
    path: "/2fa",
    name: RouteName.LOGIN_2FA,
    component: () =>
      import(/* webpackChunkName: "login 2fa" */ "../views/login-2fa.vue"),
    meta: { layout: FrontLayout }
  }
];

export default routes;
