
import { defineComponent, ref } from "vue";
import Password from "primevue/password";

export default defineComponent({
  components: {
    Password
  },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    toggleMask: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    feedback: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<string>(props.value);

    const strongPasswordRegEx =
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[@#$^*()_+={}|,.\\/?!^: <>'\";`%-])(?=.{8,})";

    const hasEightCharacters = ref(false);
    const hasUpperCase = ref(false);
    const hasLowerCase = ref(false);
    const hasNumeric = ref(false);
    const hasSymbol = ref(false);

    return {
      data,
      strongPasswordRegEx,
      hasEightCharacters,
      hasUpperCase,
      hasLowerCase,
      hasNumeric,
      hasSymbol
    };
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.textboxPassword as any).$el.children[0].setAttribute(
      "autocomplete",
      "new-password"
    );
  },
  methods: {
    onInput() {
      if (this.data) {
        this.hasEightCharacters = this.data.length >= 8;
        this.hasUpperCase = !!/[A-Z]/.exec(this.data);
        this.hasLowerCase = !!/[a-z]/.exec(this.data);
        this.hasNumeric = !!/[0-9]/.exec(this.data);
        this.hasSymbol = !!/[@#$^*()_+=[\]{}|,.\\/?!^: <>'";`%-]/.exec(
          this.data
        );
      }

      this.$emit("update:value", this.data);
    }
  }
});
