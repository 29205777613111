import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/permission-profiles",
    name: RouteName.PERMISSION_PROFILES,
    component: () =>
      import(
        /* webpackChunkName: "permission-profiles" */ "../views/permission-profiles-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  }
];

export default routes;
