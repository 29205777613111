
import { defineComponent } from 'vue';
import Toast from 'primevue/toast';

export default defineComponent({
  components: {
    Toast,
  },
  props: {
    position: {
      type: String,
      default: 'top-right',
    },
  },
  setup() {
    return {};
  },
});
