
import { i18n } from "@/plugins/i18n";
import { computed, defineComponent, ref } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import useHeaderDetails from "@/composables/use-header-details";

export default defineComponent({
  components: {
    OverlayPanel
  },
  setup() {
    const { getCurrentLocale, saveCurrentLocale } = useHeaderDetails();
    const { locale } = i18n.global;
    const languages = ["us", "se"];
    const selectedLocale = computed(() => getCurrentLocale());
    const localeOptions = ref();

    const showLocaleDropdown = (event: Event) => {
      localeOptions.value.show(event);
    };

    const hideLocaleDropdown = () => {
      localeOptions.value.hide();
    };

    const setLocale = (newLocale: "us" | "se") => {
      locale.value = newLocale;
      saveCurrentLocale();
      hideLocaleDropdown();
    };

    return {
      setLocale,
      showLocaleDropdown,
      hideLocaleDropdown,
      languages,
      selectedLocale,
      localeOptions
    };
  }
});
