import { computed, ref } from "vue";

import { LocalStorageKey } from "@/shared/constants/local-storage-keys";
import { IUser } from "@/shared/interface/IUser";
import { doesExceedTimeLimit } from "@/util";

const userFromSessionStorage = sessionStorage.getItem(LocalStorageKey.USER);

const user = ref<IUser | null>(
  userFromSessionStorage ? JSON.parse(userFromSessionStorage) : null
);

const isLoggedIn = ref(!!userFromSessionStorage);

export default function useAuth() {
  function setUser(user: IUser) {
    sessionStorage.setItem(LocalStorageKey.USER, JSON.stringify(user));
    sessionStorage.setItem(LocalStorageKey.EXPIRES_AT, Date.now().toString());

    isLoggedIn.value = true;
  }

  function setPendingUser(value: IUser) {
    sessionStorage.setItem(LocalStorageKey.PENDING_USER, JSON.stringify(value));
    user.value = value;
  }

  function logout() {
    sessionStorage.clear();
    isLoggedIn.value = false;
  }

  function checkExpiration() {
    let isExpired = false;
    const dateJSON = sessionStorage.getItem(LocalStorageKey.EXPIRES_AT);
    if (dateJSON) {
      const date = new Date(parseInt(dateJSON, 10));

      if (doesExceedTimeLimit(date)) {
        logout();
        isExpired = true;
      }
    }
    return isExpired;
  }

  function clearSession() {
    sessionStorage.clear();
  }

  return {
    setUser,
    logout,
    setPendingUser,
    checkExpiration,
    clearSession,
    user: computed(() => user.value),
    isLoggedIn: computed(() => isLoggedIn.value)
  };
}
