import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OtpInput = _resolveComponent("OtpInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_OtpInput, {
      "input-classes": _ctx.inputClasses,
      separator: _ctx.seperator,
      "input-type": _ctx.inputType,
      "num-inputs": _ctx.numInputs,
      "should-auto-focus": _ctx.shouldAutoFocus,
      "is-input-num": true,
      "conditional-class": _ctx.conditionalClass,
      placeholder: _ctx.placeholder,
      onOnChange: _ctx.handleOnChange,
      onOnComplete: _ctx.handleOnComplete
    }, null, 8, ["input-classes", "separator", "input-type", "num-inputs", "should-auto-focus", "conditional-class", "placeholder", "onOnChange", "onOnComplete"])
  ]))
}