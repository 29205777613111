
import { defineComponent, ref } from "vue";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import {
  getCountryCode,
  getCountryOptions,
  getDefaultCountryCode,
  getMobileNumber
} from "@/util";
import { ICountryCode } from "@/shared/interface";

export default defineComponent({
  components: {
    InputText,
    OverlayPanel
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    countryCodeDisabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    isCountryCode: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<string>(props.value);
    const countryCodeData = ref<string>("");
    const mobileNumberData = ref<string>("");

    const countryOptions = getCountryOptions();
    const selectedCountry = ref();

    const countryCode = props.value
      ? getCountryCode(props.value)
      : getDefaultCountryCode();

    selectedCountry.value = countryOptions.find(
      option => option.name === countryCode.name
    );

    countryCodeData.value = `+${
      countryCode ? countryCode.code.toString() : ""
    }`;

    if (props.value) {
      mobileNumberData.value = getMobileNumber(props.value) ?? "";
    }

    return {
      countryOptions,
      selectedCountry,
      countryCodeData,
      mobileNumberData,
      data
    };
  },
  methods: {
    closeCountryCodeForm() {
      (this.$refs.countryCodeOptions as InstanceType<
        typeof OverlayPanel
      >).hide();
    },
    countrySelected(option: ICountryCode) {
      this.closeCountryCodeForm();
      this.selectedCountry = option;
      this.countryCodeData = `+${option.code.toString()}`;
      this.data = `${this.countryCodeData}${this.mobileNumberData}`;

      this.$emit("update:value", this.data);
    },
    onKeyPress(event: KeyboardEvent) {
      if (!/\d/.test(event.key)) event.preventDefault();
    },
    onInput() {
      this.data = `${this.countryCodeData}${this.mobileNumberData}`;
      this.$emit("update:value", this.data);
    },
    showCountryCodes(event: Event) {
      (this.$refs.countryCodeOptions as InstanceType<typeof OverlayPanel>).show(
        event
      );
    },
    focusMobileNumber() {
      if (!this.isCountryCode)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.inputMobileNumber as any).$el.focus();
    }
  }
});
