
import { defineComponent, ref } from "vue";
import InputText from "primevue/inputtext";

export default defineComponent({
  components: {
    InputText
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    maxLength: {
      type: Number,
      default: 50
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    iconLeft: {
      type: String,
      default: ""
    },
    iconRight: {
      type: String,
      default: ""
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<string>(props.value);

    return {
      data
    };
  },
  methods: {
    onInput() {
      if (this.type === "email") {
        this.data = this.data.toLowerCase();
      }
      this.$emit("update:value", this.data.trim());
    }
  }
});
