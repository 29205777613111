import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-field-label"
}
const _hoisted_2 = {
  key: 0,
  class: "form-field-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field-row", _ctx.rowClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-field-container", `${_ctx.isFullRow ? 'full-row' : ''}`])
    }, [
      (_ctx.rowLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.rowLabel), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "field")
    ], 2),
    (!_ctx.isFullRow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "field2")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}