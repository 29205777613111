import { ICountryCode } from "@/shared/interface/ICountryCode";
import { IContactGroupTableData, IOption } from "@/shared/interface";
import { ContactGroup, Maybe, OptionValue } from "@/generated/graphql";
import { PermissionType } from "@/core/enums";
import { i18n } from "@/plugins/i18n";
import { computed } from "vue";

export default function loqUtil() {
  const defaultCountryCode = "+46";
  const countries: ICountryCode[] = [
    {
      country: "dk",
      code: 45,
      name: "Denmark",
      minLength: 11,
      maxLength: 11
    },
    {
      country: "ee",
      code: 372,
      name: "Estonia",
      minLength: 11,
      maxLength: 12
    },
    {
      country: "fi",
      code: 358,
      name: "Finland",
      minLength: 12,
      maxLength: 15
    },
    {
      country: "lv",
      code: 371,
      name: "Latvia",
      minLength: 12,
      maxLength: 12
    },
    {
      country: "lt",
      code: 370,
      name: "Lithuania",
      minLength: 12,
      maxLength: 12
    },
    {
      country: "no",
      code: 47,
      name: "Norway",
      minLength: 11,
      maxLength: 11
    },
    {
      country: "ph",
      code: 63,
      name: "Philippines",
      minLength: 13,
      maxLength: 13
    },
    {
      country: "se",
      code: 46,
      name: "Sweden",
      minLength: 10,
      maxLength: 13
    },
    {
      country: "us",
      code: 1,
      name: "USA",
      minLength: 10,
      maxLength: 10
    }
  ];

  const { t } = i18n.global;

  function getCountryOptions() {
    return countries;
  }

  function getMobileNumber(mobileNumber: string) {
    const phoneNumber = mobileNumber.replace("+", "");
    const country = countries.find(
      country => phoneNumber.indexOf(country.code.toString()) === 0
    );
    return country ? phoneNumber.replace(country?.code.toString(), "") : "";
  }

  function getCountryCode(mobileNumber: string) {
    const phoneNumber = mobileNumber.replace("+", "");
    const country = countries.find(
      country => phoneNumber.indexOf(country.code.toString()) === 0
    );

    return (
      country ?? {
        country: "",
        code: "",
        name: "",
        minLength: 0,
        maxLength: 0
      }
    );
  }

  function getDefaultCountryCode() {
    const defaultCountryCode =
      countries.find(country => country.country === "se") ?? null;
    return {
      country: defaultCountryCode?.country ?? "",
      code: defaultCountryCode?.code ?? 0,
      name: defaultCountryCode?.name
    };
  }

  function applyTitleCaseOnWord(word: string) {
    if (word === word.toUpperCase()) {
      return word;
    }

    return word.length > 1
      ? word.charAt(0).toUpperCase() + word.substring(1)
      : word.toUpperCase();
  }

  function getWords(text: string) {
    const words = text
      .trim()
      .replaceAll("  ", " ")
      .toLowerCase()
      .split(" ")
      .map(word => applyTitleCaseOnWord(word));
    return words;
  }

  function applyTitleCase(text: string) {
    const words = getWords(text);

    return words.join(" ");
  }

  function getInitials(text: string) {
    const words = getWords(text).map(word => word[0]);

    return words.splice(0, 2).join("");
  }

  function getGridHeight() {
    return `${window.innerHeight - 380}px`;
  }

  function getFullGridHeight() {
    return `${window.innerHeight - 330}px`;
  }

  function timeAgo(date: Date) {
    const seconds = Math.round((Date.now() - date.getTime()) / 1000);
    const years = Math.round(seconds / 31536000);
    const months = Math.round(seconds / 2592000);
    const days = Math.round(seconds / 86400);

    if (days > 548) {
      return `${years} years ago`;
    }
    if (days >= 320 && days <= 547) {
      return "a year ago";
    }
    if (days >= 45 && days <= 319) {
      return `${months} months ago`;
    }
    if (days >= 26 && days <= 45) {
      return "a month ago";
    }

    const hours = Math.round(seconds / 3600);

    if (hours >= 36 && days <= 25) {
      return `${days} days ago`;
    }
    if (hours >= 22 && hours <= 35) {
      return "a day ago";
    }

    const minutes = Math.round(seconds / 60);

    if (minutes >= 90 && hours <= 21) {
      return `${hours} hours ago`;
    }
    if (minutes >= 45 && minutes <= 89) {
      return "an hour ago";
    }
    if (seconds >= 90 && minutes <= 44) {
      return `${minutes} minutes ago`;
    }
    if (seconds >= 45 && seconds <= 89) {
      return "a minute ago";
    }
    if (seconds >= 0 && seconds <= 45) {
      return "a few seconds ago";
    }

    return "";
  }

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const monthString = `${month < 10 ? "0" : ""}${month}`;
    const dayString = `${day < 10 ? "0" : ""}${day}`;

    return `${year}-${monthString}-${dayString}`;
  }

  function dateISOString(date: Date) {
    return `${formatDate(date)}T00:00:00.000Z`;
  }

  function doesExceedTimeLimit(date: Date) {
    const hourInMilliseconds = 1000 * 60 * 60;

    return Date.now() - date.getTime() >= hourInMilliseconds;
  }

  function createDebounce() {
    let timeout: number | null = null;
    return (fnc: () => void, delayMs: number | undefined) => {
      clearTimeout(timeout ?? undefined);
      timeout = setTimeout(() => {
        fnc();
      }, delayMs || 500);
    };
  }

  function getDoubleDigitsString(input: number) {
    return input < 10 ? `0${input}` : input.toString();
  }

  function getDateFromTimeStamp(timestamp: string) {
    if (!timestamp.length) {
      return "";
    }
    const date = new Date(timestamp);
    const dayText = getDoubleDigitsString(date.getDate());
    const monthText = getDoubleDigitsString(date.getMonth() + 1);

    return `${date.getFullYear()}-${monthText}-${dayText}`;
  }

  function getTimeFromTimeStamp(timestamp: string) {
    if (!timestamp.length) {
      return "";
    }
    const date = new Date(timestamp);
    const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const hoursText = getDoubleDigitsString(hours);
    const minutesText = getDoubleDigitsString(date.getMinutes());
    const secondsText = getDoubleDigitsString(date.getSeconds());
    const dateSuffix = date.getHours() >= 12 ? "PM" : "AM";

    return `${hoursText}:${minutesText}:${secondsText} ${dateSuffix}`;
  }

  function getContactTitle(jobTitle: Maybe<string>, section: Maybe<string>) {
    let title = "";

    if (jobTitle) {
      title = section ? `${jobTitle} at ${section}` : jobTitle;
    } else if (section) {
      title = section;
    }

    return title;
  }

  function isValidMobileNumber(mobileNumber: string | undefined) {
    if (!mobileNumber) {
      return false;
    }

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const doesMatch = mobileNumber.match(phoneRegExp);

    if (!doesMatch) {
      return false;
    }

    const countryCode = getCountryCode(mobileNumber);
    const numLength = mobileNumber.length;

    if (
      numLength > countryCode.maxLength ||
      numLength < countryCode.minLength
    ) {
      return false;
    }

    return true;
  }

  async function uploadAvatar(
    data: string,
    presignedUploadURL: string
  ): Promise<string> {
    const blob = await (await fetch(data)).blob();
    let fileName = presignedUploadURL.substring(
      presignedUploadURL.lastIndexOf("/") + 1,
      presignedUploadURL.indexOf("?")
    );

    await fetch(presignedUploadURL, {
      method: "PUT",
      body: blob
    }).catch(() => {
      fileName = "";
    });

    return fileName;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getOptions(options: Maybe<any[]>) {
    // temporary fix since we can't replicate the duplicate options on section and jobtitle dropdown
    const mappedOptions =
      options?.map(option => {
        const newOption: IOption = {
          id: option.id,
          name: option.name
        };
        return newOption;
      }) ?? [];

    const filteredOptions = mappedOptions.filter(
      (item, index) =>
        mappedOptions.findIndex(el => el.name === item.name) === index
    );

    return filteredOptions;
  }

  function getContactGroups(
    options: Pick<OptionValue, "id" | "name">[],
    contactGroups: Pick<ContactGroup, "id" | "permissionTypeId">[]
  ) {
    const contactGroupOptions = options.map(option => {
      const contactGroup = contactGroups.find(group => group.id === option.id);
      const contactGroupOption = {
        id: +option.id,
        name: option.name,
        alias: "",
        permissionTypeId:
          contactGroup?.permissionTypeId ??
          PermissionType.VISIBLE_CAN_SEE_OTHERS
      } as IContactGroupTableData;
      return contactGroupOption;
    });

    const contactGroupIds = contactGroups.map(group => +group.id);
    const selectedContactGroups = contactGroupOptions.filter(
      option => contactGroupIds.indexOf(option.id) >= 0
    );

    return {
      contactGroupOptions,
      selectedContactGroups
    };
  }

  const chartOptions = {
    bar: {
      indexAxis: "y",
      plugins: {
        legend: {
          display: false
        }
      }
    },
    line: {
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      }
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  function createBarData(labels: string[], data: number[]) {
    const backgroundColor = ["#00637a", "#05c46b", "#ffa801", "#999999"];
    if (labels.length < 4) {
      backgroundColor.pop();
    }

    const chartData = {
      labels,
      datasets: [
        {
          label: "",
          data,
          backgroundColor
        }
      ]
    };
    return chartData;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function createLineData(statistics: any[]) {
    const labels: string[] = [];
    const sent: number[] = [];
    const notDelivered: number[] = [];

    statistics.forEach(stats => {
      labels.push(months[stats.month]);
      sent.push(stats.totalSent);
      notDelivered.push(stats.totalNotDelivered);
    });

    const chartData = {
      labels,
      datasets: [
        {
          label: computed(() => t("home.sent")),
          data: sent,
          tension: 0.3,
          borderColor: "#05c46b",
          fill: true,
          backgroundColor: "rgba(5, 196, 107, .1)"
        },
        {
          label: computed(() => t("home.not-delivered")),
          data: notDelivered,
          tension: 0.3,
          borderDash: [5],
          borderColor: "#ff5e57"
        }
      ]
    };

    return chartData;
  }

  function printElement(elementId: string) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (!element) {
        return;
      }

      const printFrame = document.createElement("iframe");
      document.body.appendChild(printFrame);
      printFrame.contentWindow?.document.open();
      printFrame.contentWindow?.document.write(
        "<style>@page { size: auto; margin: 20px; }</style>"
      );
      printFrame.contentWindow?.document.write(element.innerHTML);
      printFrame.contentWindow?.print();
      printFrame.contentWindow?.document.close();
      printFrame.remove();
    });
  }

  /* eslint-disable */
  function sliceIntoChunks(arr: unknown[], chunkSize: number): any[][] {
    const res: any[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk: any = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  /* eslint-enable */

  return {
    getContactTitle,
    getCountryOptions,
    getMobileNumber,
    getCountryCode,
    getDefaultCountryCode,
    getGridHeight,
    getFullGridHeight,
    getInitials,
    applyTitleCase,
    timeAgo,
    dateISOString,
    doesExceedTimeLimit,
    debounce: createDebounce(),
    uploadAvatar,
    isValidMobileNumber,
    getOptions,
    getContactGroups,
    getDateFromTimeStamp,
    getTimeFromTimeStamp,
    createBarData,
    createLineData,
    printElement,
    sliceIntoChunks,
    countries,
    chartOptions,
    months,
    defaultCountryCode
  };
}
