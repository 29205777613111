
import { defineComponent, ref } from 'vue';
import InputSwitch from 'primevue/inputswitch';

export default defineComponent({
  components: {
    InputSwitch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const input = ref<boolean>(props.value);

    const onValueChange = () => {
      emit('update:value', input.value);
    };

    return {
      input,
      onValueChange,
    };
  },
});
