
import { defineComponent, ref } from "vue";
import FileUpload from "primevue/fileupload";

export default defineComponent({
  components: {
    FileUpload
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: "advanced"
    },
    chooseIcon: {
      type: String,
      default: "pi pi-plus"
    },
    fileType: {
      type: String,
      default: "image/*"
    }
  },
  emits: ["upload"],
  setup(_, { emit }) {
    const fileUploadRef = ref<FileUpload>();

    const onUpload = (event: Event) => {
      emit("upload", event);
    };

    return {
      fileUploadRef,
      onUpload
    };
  }
});
