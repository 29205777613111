import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/free-users",
    name: RouteName.FREE_USERS,
    component: () =>
      import(
        /* webpackChunkName: "free-users" */ "../../free-users/views/free-users-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/users",
    name: RouteName.ACCOUNTS,
    component: () =>
      import(
        /* webpackChunkName: "pro-users" */ "../views/pro-accounts-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  }
];

export default routes;
