export enum GraphQLErrorCode {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  EXISTING_EMAIL = "EXISTING_EMAIL",
  ACCOUNT_ALREADY_ACTIVATED = "ACCOUNT_ALREADY_ACTIVATED",
  DELETED_ORGANIZATION = "DELETED_ORGANIZATION",
  INACTIVE_ORGANIZATION = "INACTIVE_ORGANIZATION",
  INVALID_VERIFICATION_CODE = "INVALID_VERIFICATION_CODE",
  EXPIRED_VERIFICATION_CODE = "EXPIRED_VERIFICATION_CODE",
  ORGANIZATION_REACHED_ACCOUNT_LIMIT = "ORGANIZATION_REACHED_ACCOUNT_LIMIT",
  NOT_EXISTING_ORGANIZATION = "NOT_EXISTING_ORGANIZATION",
  NOT_EXISTING_ADMIN = "NOT_EXISTING_ADMIN",
  EXISTING_EXTERNAL_CONTACT_REQUEST = "EXISTING_EXTERNAL_CONTACT_REQUEST",
  SAME_ORGANIZATION_REQUEST = "SAME_ORGANIZATION_REQUEST",
  EXISTING_ALIAS = "EXISTING_ALIAS",
  EXISTING_MOBILE_NUMBER = "EXISTING_MOBILE_NUMBER",
  EXISTING_ADMIN_USERNAME = "EXISTING_ADMIN_USERNAME",
  INVALID_OTP = "INVALID_OTP",
  EXISTING_DISTRIBUTION = "EXISTING_DISTRIBUTION",
  EXISTING_DISTRIBUTION_NAME = "EXISTING_DISTRIBUTION_NAME",
  INVALID_INPUT = "BAD_INPUT",
  EXISTING_NAME = "EXISTING_NAME",
  INVALID_MOBILE_NUMBER = "INVALID_MOBILE_NUMBER",
  INVALID_FILE = "INVALID_FILE",
  EMPTY_FILE = "EMPTY_FILE"
}
