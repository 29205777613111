import { computed } from "vue";

import { menuItems } from "@/shared/constants/menu-items";
import { HomeComponent } from "@/home/enums";
import { AnnouncementComponent } from "@/announcements/enums/AnnouncementComponent";
import { IAdminViewComponent } from "@/shared/interface/IAdminViewComponent";
import { useAuth } from "@/composables";

const { user, setUser } = useAuth();

export default function useAdmin() {
  const { organizationAdminMenuItems, loqAdminMenuItems } = menuItems;

  function setCredentials(userId: string, token: string) {
    setUser({
      userId,
      token
    });
  }

  return {
    currentMenuItems: computed(() =>
      user.value?.organizationName
        ? organizationAdminMenuItems
        : loqAdminMenuItems
    ),
    adminViewComponent: computed(() => {
      const viewComponent: IAdminViewComponent = {
        home: user.value?.organizationName
          ? HomeComponent.ORGANIZATION_OVERVIEW
          : HomeComponent.LOQ_OVERVIEW,
        announcement: user.value?.organizationName
          ? AnnouncementComponent.ORGANIZATION_ANNOUNCEMENT
          : AnnouncementComponent.LOQ_ANNOUNCEMENT
      };
      return viewComponent;
    }),
    setCredentials
  };
}
