import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64eda560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = ["type", "inputmode", "placeholder", "disabled"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      "data-test": "single-input",
      type: _ctx.inputTypeValue,
      inputmode: _ctx.inputmode,
      placeholder: _ctx.placeholder,
      disabled: _ctx.isDisabled,
      autocomplete: "new-password",
      min: "0",
      max: "9",
      maxlength: "1",
      pattern: "[0-9]",
      class: _normalizeClass([_ctx.inputClasses, _ctx.conditionalClass, { 'is-complete': _ctx.model }]),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOnChange && _ctx.handleOnChange(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleOnKeyDown && _ctx.handleOnKeyDown(...args))),
      onPaste: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleOnPaste && _ctx.handleOnPaste(...args))),
      onFocus: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleOnFocus && _ctx.handleOnFocus(...args))),
      onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleOnBlur && _ctx.handleOnBlur(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelDynamic, _ctx.model]
    ]),
    (!_ctx.isLastChild && _ctx.separator)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.separator), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}