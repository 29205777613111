import { useToast as useToastPrimeVue } from "primevue/usetoast";

export default function useToast() {
  enum ToastSeverity {
    SUCCESS = "success",
    INFO = "info",
    WARN = "warn",
    ERROR = "error"
  }

  enum ToastSummary {
    SUCCESS = "Success",
    INFO = "Generic Information",
    WARN = "Warning",
    ERROR = "Error"
  }

  const { add: addToast } = useToastPrimeVue();

  function calculateToastDuration(toastMessage: string): number {
    const minimum = 2000;
    const maximum = 7000;
    const durationPerCharacter = 50;

    const numberOfCharacters = toastMessage.length;

    // https://ux.stackexchange.com/a/85898
    const duration = Math.min(
      Math.max(numberOfCharacters * durationPerCharacter, minimum),
      maximum
    );

    return duration;
  }

  function successToast(message: string) {
    addToast({
      severity: ToastSeverity.SUCCESS,
      summary: ToastSummary.SUCCESS,
      detail: message,
      life: calculateToastDuration(message)
    });
  }

  function errorToast(message: string) {
    addToast({
      severity: ToastSeverity.ERROR,
      summary: ToastSummary.ERROR,
      detail: message,
      life: calculateToastDuration(message)
    });
  }

  function warningToast(message: string) {
    addToast({
      severity: ToastSeverity.WARN,
      summary: ToastSummary.WARN,
      detail: message,
      life: calculateToastDuration(message)
    });
  }

  function infoToast(message: string) {
    addToast({
      severity: ToastSeverity.INFO,
      summary: ToastSummary.INFO,
      detail: message,
      life: calculateToastDuration(message)
    });
  }

  return { successToast, errorToast, warningToast, infoToast };
}
