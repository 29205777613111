import loqUtil from "./loq-util";

const {
  getContactTitle,
  getCountryCode,
  getDefaultCountryCode,
  getMobileNumber,
  getCountryOptions,
  applyTitleCase,
  getGridHeight,
  getFullGridHeight,
  getInitials,
  timeAgo,
  dateISOString,
  doesExceedTimeLimit,
  debounce,
  uploadAvatar,
  isValidMobileNumber,
  getOptions,
  getContactGroups,
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  createBarData,
  createLineData,
  printElement,
  sliceIntoChunks,
  chartOptions,
  months,
  defaultCountryCode
} = loqUtil();

export {
  getContactTitle,
  getCountryOptions,
  getCountryCode,
  getDefaultCountryCode,
  getMobileNumber,
  applyTitleCase,
  getGridHeight,
  getFullGridHeight,
  getInitials,
  timeAgo,
  dateISOString,
  doesExceedTimeLimit,
  debounce,
  uploadAvatar,
  isValidMobileNumber,
  getOptions,
  getContactGroups,
  getDateFromTimeStamp,
  getTimeFromTimeStamp,
  createBarData,
  createLineData,
  printElement,
  sliceIntoChunks,
  chartOptions,
  months,
  defaultCountryCode
};
