
import { defineComponent, useSlots } from "vue";

export default defineComponent({
  props: {
    rowLabel: {
      type: String,
      default: ""
    },
    rowClass: {
      type: String,
      default: ""
    }
  },
  setup() {
    const slots = useSlots();
    const isFullRow = !slots.field2;

    return {
      isFullRow
    };
  }
});
