
import { defineComponent, ref } from "vue";
import Avatar from "primevue/avatar";
import OverlayPanel from "primevue/overlaypanel";
import UploadAvatarForm from "@/shared/components/upload-avatar-form.vue";
import { getInitials } from "@/util";

export default defineComponent({
  components: {
    Avatar,
    UploadAvatarForm,
    OverlayPanel
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  emits: ["avatar-updated"],
  setup(props) {
    const avatarURL = ref(props.image);
    const avatarLabel = !avatarURL.value ? getInitials(props.label) : "";

    return {
      avatarLabel,
      avatarURL
    };
  },
  methods: {
    showUploadForm() {
      (this.$refs.uploadForm as InstanceType<typeof UploadAvatarForm>).showForm(
        this.isAdmin
      );
    },
    showUploadOptions(event: Event) {
      if (this.$props.isEditable) {
        if (this.avatarURL) {
          (this.$refs.avatarOptions as InstanceType<typeof OverlayPanel>).show(
            event
          );
        } else {
          this.showUploadForm();
        }
      }
    },
    clearAvatar() {
      this.avatarURL = "";
      this.closeUploadOptions();
      this.$emit("avatar-updated", this.avatarURL, "");
    },
    closeUploadOptions() {
      (this.$refs.avatarOptions as InstanceType<typeof OverlayPanel>).hide();
    },
    onAvatarUpdated(avatarURL: string, presignedUploadURL: string) {
      this.avatarURL = avatarURL;
      this.$emit("avatar-updated", this.avatarURL, presignedUploadURL);
    }
  }
});
