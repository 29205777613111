import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = {
  key: 0,
  autocomplete: "off",
  name: "hidden",
  type: "text",
  style: {"display":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleOtpInput = _resolveComponent("SingleOtpInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.inputType === 'password')
      ? (_openBlock(), _createElementBlock("input", _hoisted_2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numInputs, (_, i) => {
      return (_openBlock(), _createBlock(_component_SingleOtpInput, {
        key: i,
        focus: _ctx.activeInput === i,
        value: _ctx.otp[i],
        separator: _ctx.separator,
        "input-type": _ctx.inputType,
        inputmode: _ctx.inputmode,
        "input-classes": _ctx.inputClasses,
        "conditional-class": _ctx.conditionalClass[i],
        "is-last-child": i === _ctx.numInputs - 1,
        "should-auto-focus": _ctx.shouldAutoFocus,
        placeholder: _ctx.placeholder[i],
        "is-disabled": _ctx.isDisabled,
        onOnChange: _ctx.handleOnChange,
        onOnKeydown: _ctx.handleOnKeyDown,
        onOnPaste: _ctx.handleOnPaste,
        onOnFocus: ($event: any) => (_ctx.handleOnFocus(i)),
        onOnBlur: _ctx.handleOnBlur
      }, null, 8, ["focus", "value", "separator", "input-type", "inputmode", "input-classes", "conditional-class", "is-last-child", "should-auto-focus", "placeholder", "is-disabled", "onOnChange", "onOnKeydown", "onOnPaste", "onOnFocus", "onOnBlur"]))
    }), 128))
  ]))
}