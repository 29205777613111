import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _directive_badge = _resolveDirective("badge")!

  return (_ctx.type === 'icon')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.severity === 'danger')
          ? _withDirectives((_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass(_ctx.icon + ' mr-4 p-text-primary ' + _ctx.className),
              style: _normalizeStyle(_ctx.style)
            }, null, 6)), [
              [
                _directive_badge,
                _ctx.value,
                void 0,
                { danger: true }
              ]
            ])
          : (_ctx.severity === 'info')
            ? _withDirectives((_openBlock(), _createElementBlock("i", {
                key: 1,
                class: _normalizeClass(_ctx.icon + ' mr-4 p-text-primary ' + _ctx.className),
                style: _normalizeStyle(_ctx.style)
              }, null, 6)), [
                [
                  _directive_badge,
                  _ctx.value,
                  void 0,
                  { info: true }
                ]
              ])
            : (_ctx.severity === 'success')
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 2,
                  class: _normalizeClass(_ctx.icon + ' mr-4 p-text-primary ' + _ctx.className),
                  style: _normalizeStyle(_ctx.style)
                }, null, 6)), [
                  [
                    _directive_badge,
                    _ctx.value,
                    void 0,
                    { success: true }
                  ]
                ])
              : (_ctx.severity === 'warning')
                ? _withDirectives((_openBlock(), _createElementBlock("i", {
                    key: 3,
                    class: _normalizeClass(_ctx.icon + ' mr-4 p-text-primary ' + _ctx.className),
                    style: _normalizeStyle(_ctx.style)
                  }, null, 6)), [
                    [
                      _directive_badge,
                      _ctx.value,
                      void 0,
                      { warning: true }
                    ]
                  ])
                : _createCommentVNode("", true)
      ]))
    : (_ctx.type === 'badge')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Badge, {
            value: _ctx.value,
            severity: _ctx.severity,
            size: _ctx.size
          }, null, 8, ["value", "severity", "size"])
        ]))
      : _createCommentVNode("", true)
}