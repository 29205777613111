import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-grid p-d-flex p-flex-nowrap"
}
const _hoisted_2 = { class: "c-page-container" }
const _hoisted_3 = { class: "organization-name p-d-flex p-ai-center" }
const _hoisted_4 = { class: "p-ml-2" }
const _hoisted_5 = { class: "c-content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_base_badge = _resolveComponent("base-badge")!
  const _component_base_avatar = _resolveComponent("base-avatar")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_menu = _resolveComponent("base-menu")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_base_toast = _resolveComponent("base-toast")!
  const _component_base_confirm_modal = _resolveComponent("base-confirm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerDetails.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SideBar, { "active-menu": _ctx.activeMenu }, null, 8, ["active-menu"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TopBar, null, {
              details: _withCtx(() => [
                (_openBlock(), _createElementBlock("div", {
                  key: _ctx.headerDetails.key,
                  class: "p-d-flex"
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.headerDetails.organization), 1),
                  (_ctx.headerDetails.totalNotifications > 0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "c-notification-badge",
                        onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toExternalContactsPage && _ctx.toExternalContactsPage(...args))),
                        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toExternalContactsPage && _ctx.toExternalContactsPage(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_base_badge, {
                          type: "icon",
                          severity: "danger",
                          value: _ctx.headerDetails.totalNotifications,
                          style: {"font-size":"24px"}
                        }, null, 8, ["value"])
                      ], 32))
                    : _createCommentVNode("", true),
                  _createVNode(_component_base_button, {
                    class: "topbar-button p-ml-2",
                    onClick: _ctx.onPopUp
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_base_avatar, {
                        label: _ctx.headerDetails.name,
                        image: _ctx.headerDetails.avatar
                      }, null, 8, ["label", "image"]),
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.headerDetails.name), 1),
                      _createElementVNode("i", {
                        class: _normalizeClass(_ctx.userDropDownToggle)
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_base_menu, {
                    class: "c-user-logout",
                    toggle: _ctx.popUp,
                    "menu-items": _ctx.logoutItems,
                    "has-pop-up": true
                  }, null, 8, ["toggle", "menu-items"])
                ]))
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _createVNode(_component_base_toast, {
              position: "bottom-right",
              class: "modal-toast"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_base_confirm_modal)
  ], 64))
}