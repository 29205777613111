import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menubar = _resolveComponent("Menubar")!

  return (_openBlock(), _createBlock(_component_Menubar, {
    model: [],
    class: "c-top-bar"
  }, {
    start: _withCtx(() => [
      _renderSlot(_ctx.$slots, "search")
    ]),
    end: _withCtx(() => [
      _renderSlot(_ctx.$slots, "notification"),
      _renderSlot(_ctx.$slots, "details")
    ]),
    _: 3
  }))
}