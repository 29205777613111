
import { defineComponent } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';

export default defineComponent({
  components: {
    OverlayPanel,
  },
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
