/* eslint-disable @typescript-eslint/no-explicit-any */
// https://www.vuemastery.com/blog/vue-3-data-down-events-up/

import { computed, WritableComputedRef } from 'vue';
/**
 * Wraps a component prop in a WritableComputedRef that automatically emits the value to the parent ref
 * @param {Props} props - the props args from the component
 * @param {emit} emit - the emit function from the component
 * @param {string} name - the name of the prop to be emitted
 * @example
 * props: ['name'],
 * setup(props, { emit }){
 *   ...
 *   usePropsWrapper(props, emit, 'name');
 *   ...
 * }
 *  */
export default function usePropsWrapper<
  T extends { [key: string]: any },
  K extends keyof T
>(
  props: T,
  emit: (event: any, ...args: any[]) => void,
  name: K,
): WritableComputedRef<T[K]> {
  return computed({
    get: () => props[name],
    set: value => emit(`update:${String(name)}`, value),
  });
}
