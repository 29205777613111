import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-dropdown-label"
}
const _hoisted_2 = {
  key: 0,
  class: "c-dropdown-label-required"
}
const _hoisted_3 = { class: "c-dropdown-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-dropdown", {
      'is-invalid': _ctx.errorMessage,
      dropDownClass: _ctx.dropDownClass
    }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required && !_ctx.input)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dropdown, {
      id: _ctx.label,
      modelValue: _ctx.input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      options: _ctx.options,
      placeholder: _ctx.placeholder,
      editable: _ctx.editable,
      "show-clear": _ctx.showClear,
      filter: _ctx.filter,
      "append-to": _ctx.appendTo,
      "option-label": _ctx.optionLabel,
      "filter-fields": _ctx.filterFields,
      "option-value": _ctx.optionValue,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange($event)))
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, name) => {
        return {
          name: name,
          fn: _withCtx((slotData) => [
            _renderSlot(_ctx.$slots, name, { data: slotData })
          ])
        }
      })
    ]), 1032, ["id", "modelValue", "options", "placeholder", "editable", "show-clear", "filter", "append-to", "option-label", "filter-fields", "option-value"]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
  ], 2))
}