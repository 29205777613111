
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';

export default defineComponent({
  components: {
    TabView,
  },
  emits: ['tab-change'],
  setup() {
    return {};
  },
});
