
import { defineComponent } from "vue";
import Menubar from "primevue/menubar";

export default defineComponent({
  components: {
    Menubar
  },
  setup() {
    return {};
  }
});
