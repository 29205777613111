import { reactive } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { IConfirmationOptions } from "@/shared/interface/IConfirmationOptions";

const confirmButtonLabels: IConfirmationOptions = {
  acceptLabel: "Confirm",
  rejectLabel: "Cancel"
};

const acceptButtonLabels: IConfirmationOptions = {
  acceptLabel: "Ok",
  acceptClass: "alert-accept-button",
  rejectClass: "alert-reject-button"
};

export default function useConfirmDialog() {
  const { require: confirmRequire } = useConfirm();

  // todo: refactor deleting customer process

  function showConfirmDialog(
    message: string,
    header: string,
    isAlert?: boolean
  ) {
    const confirmationOptions = reactive<IConfirmationOptions>({
      ...(isAlert ? acceptButtonLabels : confirmButtonLabels),
      message,
      header
    });

    return new Promise<boolean>(resolve => {
      confirmRequire({
        ...confirmationOptions,
        accept: async () => {
          resolve(true);
        },
        reject: () => {
          // reject();
        }
      });
    });
  }

  function showUserEnableDialog(name?: string) {
    const message = `Are you sure you want to enable ${
      name ? `${name}'s account` : "the selected accounts"
    }? 
    This will allow the user to use their LOQ account again. You can undo this any time`;
    const header = "Enable account?";

    return showConfirmDialog(message, header);
  }

  function showUserDisableDialog(name?: string) {
    const message = `Are you sure you want to disable ${
      name ? `${name}'s account` : "the selected accounts"
    }? 
    This will prohibit the user from using their LOQ account. You can undo this any time`;
    const header = "Disable account?";

    return showConfirmDialog(message, header);
  }

  function showOrganizationDeleteDialog(name?: string) {
    const message = `Are you sure you want to delete the ${
      name ? `${name} organization` : "selected organizations"
    }? 
    This will erase all of the organization's data. This action cannot be undone.`;
    const header = "Delete organization?";

    return showConfirmDialog(message, header);
  }

  function showOrganizationDisableDialog(name?: string) {
    const message = `Are you sure you want to disable ${
      name ? `${name} organization` : "selected organizations"
    }? 
    This will prohibit the organization from using their LOQ account. You can undo this any time`;
    const header = "Disable organization?";

    return showConfirmDialog(message, header);
  }

  function showAccountPasswordResetDialog(name?: string) {
    const message = `This account has already set a password. Are you sure you want to reset ${name}'s password? 
    This action cannot be undone.`;
    const header = "Reset password";

    return showConfirmDialog(message, header);
  }

  function showOrganizationEnableDialog(name?: string) {
    const message = `Are you sure you want to enable ${
      name ? `${name}'s account` : "the selected accounts"
    }? 
    This will allow the organization to use their LOQ account again. You can undo this any time`;
    const header = "Enable organization?";

    return showConfirmDialog(message, header);
  }

  function showUserDeleteDialog(name?: string) {
    const message = `Are you sure you want to delete ${
      name ? `${name} account` : "selected accounts"
    }?
    This will erase all of the user's data. This action cannot be undone.`;
    const header = "Delete account?";

    return showConfirmDialog(message, header);
  }

  function showAlertDialog(message: string, header = "") {
    return showConfirmDialog(message, header, true);
  }

  function showDistributionDisableDialog(name?: string) {
    const message = `Are you sure you want to disable ${name} distribution list? You can undo this any time`;
    const header = "Disable distribution list?";

    return showConfirmDialog(message, header);
  }

  function showDistributionEnableDialog(name?: string) {
    const message = `Are you sure you want to enable ${name} distribution list? You can undo this any time`;
    const header = "Enable distribution list?";

    return showConfirmDialog(message, header);
  }

  function showDistributionDeleteDialog(name?: string) {
    const message = `Are you sure you want to delete the ${name} distribution list? This action cannot be undone.`;
    const header = "Delete distribution list?";

    return showConfirmDialog(message, header);
  }

  function showPermissionProfileDeleteDialog(name?: string) {
    const message = `Are you sure you want to delete the ${name} permission profile? This action cannot be undone`;
    const header = "Delete permission profile?";

    return showConfirmDialog(message, header);
  }

  return {
    showUserDisableDialog,
    showUserEnableDialog,
    showUserDeleteDialog,
    showOrganizationDeleteDialog,
    showOrganizationDisableDialog,
    showOrganizationEnableDialog,
    showAccountPasswordResetDialog,
    showDistributionEnableDialog,
    showDistributionDisableDialog,
    showDistributionDeleteDialog,
    showPermissionProfileDeleteDialog,
    showAlertDialog,
    showConfirmDialog
  };
}
