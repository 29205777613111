import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-search-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_textbox = _resolveComponent("base-textbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_base_textbox, {
      key: _ctx.containerKey,
      value: _ctx.searchText,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event)),
      "icon-left": "pi-search",
      placeholder: "Search"
    }, {
      rightIcon: _withCtx(() => [
        _withDirectives(_createElementVNode("i", {
          class: "pi pi-times",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args))),
          onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args)))
        }, null, 544), [
          [_vShow, _ctx.searchText]
        ])
      ]),
      _: 1
    }, 8, ["value"]))
  ]))
}