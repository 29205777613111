
import { computed, defineComponent } from 'vue';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  components: {
    PrimeDialog: Dialog,
    ProgressSpinner,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const display = computed({
      get: () => props.visible,
      set: value => emit('update:visible', value),
    });
    return { display };
  },
});
