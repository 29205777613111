
import { defineComponent, ref } from 'vue';
import RadioButton from 'primevue/radiobutton';

export default defineComponent({
  components: {
    RadioButton,
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    model: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const input = ref(props.model);

    const onValueChange = () => {
      emit('update:value', input.value);
    };

    return {
      input,
      onValueChange,
    };
  },
});
