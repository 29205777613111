import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/billing",
    name: RouteName.BILLING,
    component: () =>
      import(
        /* webpackChunkName: "billing-page" */ "../views/billing-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/password-generator",
    name: RouteName.PASSWORD_GENERATOR,
    component: () =>
      import(
        /* webpackChunkName: "password-generator-page" */ "../views/password-generator-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/settings",
    name: RouteName.SETTINGS,
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/admin-settings.vue"),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  },
  {
    path: "/administrators",
    name: RouteName.ADMINISTRATORS,
    component: () =>
      import(
        /* webpackChunkName: "administrators-page" */ "../views/administrators-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  }
];

export default routes;
