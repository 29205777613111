
import { i18n } from "@/plugins/i18n";
import { computed, defineComponent, useSlots } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ""
    },
    backLabel: {
      type: String,
      default: ""
    },
    textContent: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    linkLabel: {
      type: String,
      default: ""
    },
    linkIcon: {
      type: String,
      default: "pi-calendar"
    },
    flipIcon: {
      type: String,
      default: ""
    },
    flipBackIcon: {
      type: String,
      default: ""
    },
    isBlank: {
      type: Boolean,
      default: false
    },
    titleWithAvatar: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: ""
    },
    avatarUrl: {
      type: String,
      default: ""
    }
  },
  emits: ["flip-card"],
  setup(props) {
    const { t } = i18n.global;

    const slots = useSlots();
    const isFlippable = !!slots.contentBack;
    const translatedLinkLabel = computed(
      () => props.linkLabel || t("home.view-history")
    );

    return {
      isFlippable,
      translatedLinkLabel
    };
  },
  methods: {
    flipCard() {
      this.$emit("flip-card");
    }
  }
});
