import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/external-contacts",
    name: RouteName.EXTERNAL_CONTACTS,
    component: () =>
      import(
        /* webpackChunkName: "external-contacts-page" */ "../views/external-contacts-page.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DefaultLayout
    }
  }
];

export default routes;
