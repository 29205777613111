
import { defineComponent } from 'vue';
import ScrollPanel from 'primevue/scrollpanel';

export default defineComponent({
  components: {
    ScrollPanel,
  },
  setup() {
    return {};
  },
});
