
import { defineComponent, ref } from "vue";
import InputNumber, { InputNumberBlurEvent } from "primevue/inputnumber";

export default defineComponent({
  components: {
    InputNumber
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<number>(props.value);

    return {
      data
    };
  },
  methods: {
    onInput(event: InputNumberBlurEvent) {
      this.$emit("update:value", event.value ?? this.$props.min);
    }
  }
});
