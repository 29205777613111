import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-permission-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.eyeIconClass)
    }, null, 2),
    (_ctx.directionClass)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["direction-icon", _ctx.directionClass])
        }, null, 2))
      : _createCommentVNode("", true)
  ])), [
    [_directive_tooltip, _ctx.$t(_ctx.tooltip)]
  ])
}