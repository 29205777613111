
import { defineComponent, ref } from "vue";
import Calendar from "primevue/calendar";

export default defineComponent({
  components: {
    Calendar
  },
  props: {
    value: {
      type: Date,
      default: null
    },
    minDate: {
      type: Date,
      default: new Date()
    },
    maxDate: {
      type: Date,
      default: null
    },
    class: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    appendTo: {
      type: String,
      default: "body"
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  emits: ["update:value"],
  setup(props) {
    const data = ref<Date>(props.value);

    return {
      data
    };
  },
  methods: {
    onDateSelected(date: Date) {
      this.$emit("update:value", date);
    }
  }
});
