import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createBlock(_component_RadioButton, {
    modelValue: _ctx.input,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
    value: _ctx.value,
    name: _ctx.name,
    onChange: _ctx.onValueChange
  }, null, 8, ["modelValue", "value", "name", "onChange"]))
}