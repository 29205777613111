import { ref, watch } from 'vue';

const isLoading = ref<boolean>(false);

export default function usePreloader() {
  /* eslint-disable @typescript-eslint/no-empty-function */
  let watchingFunction = () => {};

  async function showLoader() {
    isLoading.value = true;
  }

  async function hideLoader() {
    isLoading.value = false;
  }

  async function watchThis(loading: { value: boolean }) {
    if (watchingFunction) {
      watchingFunction();
    }

    watchingFunction = watch(
      () => loading.value,
      () => {
        isLoading.value = loading.value;
      },
    );
  }

  return {
    isLoading,
    showLoader,
    hideLoader,
    watchThis,
  };
}
