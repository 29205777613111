import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "c-textbox-label"
}
const _hoisted_2 = {
  key: 0,
  class: "c-textbox-label-required"
}
const _hoisted_3 = { class: "c-textbox-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-textbox", {
      'is-invalid': _ctx.errorMessage
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'p-input-icon-left': _ctx.iconLeft,
        'p-input-icon-right': _ctx.iconRight || _ctx.$slots.rightIcon
      })
    }, [
      (_ctx.iconLeft)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["pi", _ctx.iconLeft])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required && !_ctx.data)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_InputText, {
        modelValue: _ctx.data,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
        type: _ctx.type,
        autocomplete: "off",
        disabled: _ctx.disabled,
        maxlength: _ctx.maxLength,
        placeholder: _ctx.placeholder,
        readonly: _ctx.readonly,
        autofocus: _ctx.autofocus,
        onInput: _ctx.onInput
      }, null, 8, ["modelValue", "type", "disabled", "maxlength", "placeholder", "readonly", "autofocus", "onInput"]),
      (_ctx.iconRight && !_ctx.$slots.rightIcon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 2,
            class: _normalizeClass(["pi", _ctx.iconRight])
          }, null, 2))
        : _createCommentVNode("", true),
      (!_ctx.iconRight)
        ? _renderSlot(_ctx.$slots, "rightIcon", { key: 3 })
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
    ], 2)
  ], 2))
}