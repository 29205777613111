import { RouteRecordRaw } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";
import { DefaultLayout } from "@/layouts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.HOME,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/home-dashboard.vue"),
    meta: { layout: DefaultLayout }
  },
  {
    path: "/history/:type",
    name: RouteName.HISTORY,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/home-history.vue"),
    meta: { layout: DefaultLayout }
  },
  {
    path: "/history/all/:type",
    name: RouteName.ALL_HISTORY,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/home-history.vue"),
    meta: { layout: DefaultLayout }
  }
];

export default routes;
