import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import primeVue from "primevue/config";
import confirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/plugins/apollo";

import registerBaseComponents from "@/shared/base";
import dashboardModule from "@/home";

import { RouteName } from "@/shared/constants/route-name";

import organizationModule from "./organizations";
import proAccountsModule from "./pro-accounts";
import announcementModule from "./announcements";
import adminModule from "./admin";

import App from "./app.vue";
import { useAuth } from "./composables";
import logInModule from "./login";
import forgotPasswordModule from "./forgot-password";
import verifyAccountModule from "./verify-account";
import externalContactsModule from "./external-contacts";
import contactGroupsModule from "./contact-groups";
import permissioProfilesModule from "./permission-profiles";

import "@/plugins/primevue";
import "./styles/layout/layout.scss";
import { i18n } from "./plugins/i18n";

const { isLoggedIn } = useAuth();

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: App,
      beforeEnter: (to, from, next) => {
        if (isLoggedIn.value) {
          next({ name: RouteName.HOME });
        } else {
          next({ name: RouteName.LOGIN });
        }
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn.value) {
      next({ name: RouteName.LOGIN });
    } else {
      next();
    }
  } else {
    next();
  }
});

const app = createApp(App)
  .provide(DefaultApolloClient, apolloClient)
  .use(primeVue);

registerBaseComponents(app);
dashboardModule({ app, router });
organizationModule({ app, router });
proAccountsModule({ app, router });
announcementModule({ app, router });
adminModule({ app, router });
logInModule({ app, router });
externalContactsModule({ app, router });
forgotPasswordModule({ app, router });
verifyAccountModule({ app, router });
contactGroupsModule({ app, router });
permissioProfilesModule({ app, router });

app.use(router);
app.use(confirmationService);
app.use(ToastService);
app.use(i18n);

app.config.globalProperties.$routeName = RouteName;
app.mount("#app");
