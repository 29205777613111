import {
  useCleanupUnusedAvatarsMutation,
  useMeQuery
} from "@/generated/graphql";
import { RouteName } from "@/shared/constants/route-name";
import { IHeaderDetails } from "@/shared/interface/IHeaderDetails";
import { ref } from "vue";
import { useRouter } from "vue-router";
import useAuth from "./use-auth";
import { i18n } from "@/plugins/i18n";
import { LocalStorageKey } from "@/shared/constants/local-storage-keys";

const headerDetails = ref<IHeaderDetails>({
  id: 0,
  name: "",
  organization: "",
  organizationId: 0,
  avatar: "",
  totalNotifications: 0,
  key: 0,
  version: "1.0.0"
});

export default function useHeaderDetails() {
  const { user, isLoggedIn, logout, clearSession } = useAuth();
  const router = useRouter();

  const { mutate: cleanupAvatars } = useCleanupUnusedAvatarsMutation({
    fetchPolicy: "no-cache"
  });

  function onClearUnusedAvatar() {
    cleanupAvatars();
  }

  function fetchMe() {
    const { onResult: meFetched, onError: onMeError } = useMeQuery({
      fetchPolicy: "cache-and-network"
    });

    meFetched(result => {
      const {
        username,
        organization,
        avatar,
        versionNumber,
        totalRequests
      } = result.data.me;

      const previousDetails = {
        name: headerDetails.value.name,
        organization: headerDetails.value.organization,
        avatar: headerDetails.value.avatar,
        version: headerDetails.value.version
      };

      headerDetails.value.name = username;
      headerDetails.value.organization = organization;
      headerDetails.value.avatar = avatar;
      headerDetails.value.totalNotifications = totalRequests;
      headerDetails.value.version = versionNumber;

      if (
        previousDetails.name !== headerDetails.value.name ||
        previousDetails.organization !== headerDetails.value.organization ||
        previousDetails.avatar !== headerDetails.value.avatar
      ) {
        headerDetails.value.key = headerDetails.value.key === 1 ? 0 : 1;
        clearSession();
      }
    });

    onMeError(() => {
      logout();
      router.push({ name: RouteName.LOGIN });
    });
  }

  function initialize() {
    if (isLoggedIn.value) {
      headerDetails.value.id = +(user.value?.userId ?? 0);
      headerDetails.value.name = user.value?.name ?? "";
      headerDetails.value.organization = user.value?.organizationName ?? "";
      headerDetails.value.avatar = user.value?.avatar ?? "";
      headerDetails.value.totalNotifications = 0;
      headerDetails.value.version = "1.0.0";

      console.log("pre-version : ", headerDetails.value.version);

      fetchMe();
    } else {
      logout();
      router.push({ name: RouteName.LOGIN });
    }
  }

  function updateHeaderIfNecessary(id: number) {
    if (headerDetails.value.id === id) {
      fetchMe();
    }
  }

  function reload() {
    fetchMe();
  }

  function saveCurrentLocale() {
    const { locale } = i18n.global;

    localStorage.setItem(LocalStorageKey.LOCALE, locale.value);
  }

  function getCurrentLocale() {
    i18n.global.locale.value = "us";

    return i18n.global.locale.value;
  }

  return {
    initialize,
    updateHeaderIfNecessary,
    reload,
    onClearUnusedAvatar,
    saveCurrentLocale,
    getCurrentLocale,
    headerDetails
  };
}
