import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-textbox-label"
}
const _hoisted_2 = {
  key: 0,
  class: "c-textbox-label-required"
}
const _hoisted_3 = { class: "c-textbox-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-textbox", {
      'is-invalid': _ctx.errorMessage
    }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required && !_ctx.data)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_InputNumber, {
      modelValue: _ctx.data,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
      autocomplete: "off",
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      autofocus: _ctx.autofocus,
      format: false,
      min: _ctx.min,
      max: _ctx.max,
      onInput: _ctx.onInput
    }, null, 8, ["modelValue", "disabled", "placeholder", "readonly", "autofocus", "min", "max", "onInput"]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
  ], 2))
}