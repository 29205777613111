
import { defineComponent, PropType } from 'vue';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';

export type BadgeSeverityType =
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | undefined;
export type BadgeSizeType = 'large' | 'xlarge' | undefined;

export default defineComponent({
  directives: {
    badge: BadgeDirective,
  },
  components: {
    Badge,
  },
  props: {
    type: {
      type: String,
      default: 'icon',
    },
    severity: {
      type: String as PropType<BadgeSeverityType>,
      default: 'info',
    },
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: 'pi pi-bell',
    },
    style: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<BadgeSizeType>,
      default: undefined,
    },
  },
});
