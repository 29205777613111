
import { defineComponent, ref, computed } from "vue";
import { useAuth } from "@/composables";

import SideBar from "@/shared/components/side-bar.vue";
import TopBar from "@/shared/components/top-bar.vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/shared/constants/route-name";

import useHeaderDetails from "@/composables/use-header-details";
import { i18n } from "@/plugins/i18n";

export default defineComponent({
  components: {
    TopBar,
    SideBar
  },
  setup() {
    const { logout } = useAuth();

    const router = useRouter();

    const {
      headerDetails,
      initialize,
      onClearUnusedAvatar
    } = useHeaderDetails();

    initialize();

    const activeMenu = ref<boolean>(true);
    const popUp = ref<Event>();
    const userDropDownToggle = ref<string>("pi pi-angle-down p-ml-2");

    const toggleIcon = computed(() =>
      activeMenu.value ? "pi pi-angle-left" : "pi pi-angle-right"
    );

    const { t } = i18n.global;

    function onMenuToggle() {
      activeMenu.value = !activeMenu.value;
    }

    const logoutItems = ref([
      {
        label: computed(() => t("login.logout")),
        icon: "pi pi-sign-out",
        command: () => {
          logout();
          router.push({ name: RouteName.LOGIN });
        }
      }
    ]);

    function onPopUp(event: Event) {
      popUp.value = event;
    }

    function toExternalContactsPage() {
      router.push({ name: RouteName.EXTERNAL_CONTACTS });
    }

    return {
      onMenuToggle,
      onPopUp,
      toExternalContactsPage,
      onClearUnusedAvatar,
      activeMenu,
      toggleIcon,
      logoutItems,
      popUp,
      userDropDownToggle,
      headerDetails
    };
  }
});
