import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    selection: _ctx.selectedRow,
    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRow) = $event)),
    dataKey: _ctx.dataKey,
    value: _ctx.data,
    metaKeySelection: false,
    paginator: _ctx.paginator && _ctx.totalRecords > 0,
    rows: 10,
    selectionMode: _ctx.selectionMode,
    currentPageReportTemplate: _ctx.PageReportTemplate.DEFAULT,
    rowsPerPageOptions: _ctx.getRowsPerPage(),
    paginatorTemplate: _ctx.getPaginatorTemplate(),
    scrollable: true,
    loading: _ctx.isLoading,
    lazy: true,
    totalRecords: _ctx.totalRecords,
    "sort-field": _ctx.sortField,
    "sort-order": _ctx.sortOrder,
    class: _normalizeClass({ 'is-multi-select': _ctx.isMultiSelect }),
    onRowClick: _ctx.onRowClick,
    onRowSelect: _ctx.onValueChanged,
    onRowUnselect: _ctx.onValueChanged,
    onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('page-changed', $event))),
    onSort: _ctx.onSort,
    onRowSelectAll: _ctx.onSelectAllChange,
    onRowUnselectAll: _ctx.onUnselectAllChange
  }, _createSlots({
    default: _withCtx(() => [
      (_ctx.isMultiSelect)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            selectionMode: 'multiple'
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (col, index) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: index,
          header: _ctx.t(col.header as string),
          field: col.field,
          style: _normalizeStyle(col.style),
          sortable: _ctx.totalRecords > 0 && col.sortField !== undefined
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (_, name) => {
            return {
              name: name,
              fn: _withCtx((slotData) => [
                _renderSlot(_ctx.$slots, name, {
                  data: slotData.data,
                  header: col.header,
                  field: col.field
                })
              ])
            }
          })
        ]), 1032, ["header", "field", "style", "sortable"]))
      }), 128))
    ]),
    _: 2
  }, [
    (!_ctx.isLoading)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.emptyMessage), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["selection", "dataKey", "value", "paginator", "selectionMode", "currentPageReportTemplate", "rowsPerPageOptions", "paginatorTemplate", "loading", "totalRecords", "sort-field", "sort-order", "class", "onRowClick", "onRowSelect", "onRowUnselect", "onSort", "onRowSelectAll", "onRowUnselectAll"]))
}