export enum RouteName {
  HOME = "Home Page",
  HISTORY = "History",
  ALL_HISTORY = "All History",
  LOGIN = "Login Page",
  ORGANIZATIONS = "Organization Page",
  ORGANIZATION_DETAILS = "Organization Details",
  ORGANIZATION_STATISTICS_DETAIL = "Organization Statistics Detail Page",
  ORGANIZATION_HISTORY = "Organization History",
  VERIFY_ACCOUNT = "Verify Account",
  FREE_USERS = "Free Users Page",
  ANNOUNCEMENTS = "Announcements Page",
  SEND_ANNOUNCEMENTS = "Send Announcements Page",
  ACCOUNTS = "Accounts Page",
  ADMINISTRATORS = "Administrators Page",
  EXTERNAL_CONTACTS = "External Contacts",
  BILLING = "Billings Page",
  PASSWORD_GENERATOR = "Password Generator Page",
  SETTINGS = "Settings Page",
  FORGOT_PASSWORD = "Forgot Password Page",
  RESET_PASSWORD = "Reset Password Page",
  VERIFY_RESET_PASSWORD_CODE = "Verify Reset Password Code",
  CONTACT_GROUPS = "Contact Groups Page",
  LOGIN_2FA = "Login 2FA Page",
  PERMISSION_PROFILES = "Permission Profiles",
  PERMISSION_GROUPS = "Permissions",
  CREATE_ROOM = "Create Room"
}
