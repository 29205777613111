import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/flag_placeholder.png'


const _hoisted_1 = { class: "c-locale-changer" }
const _hoisted_2 = { class: "c-locale-option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_listbox = _resolveComponent("base-listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      ref: "localeInput",
      src: _imports_0,
      class: _normalizeClass(`flag flag-${_ctx.selectedLocale}`),
      alt: "",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showLocaleDropdown && _ctx.showLocaleDropdown(...args))),
      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showLocaleDropdown && _ctx.showLocaleDropdown(...args)))
    }, null, 34),
    _createVNode(_component_OverlayPanel, {
      ref: "localeOptions",
      class: "c-locale-options-container",
      onHide: _ctx.hideLocaleDropdown
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_listbox, {
          data: _ctx.languages,
          selected: _ctx.selectedLocale,
          onValueChanged: _ctx.setLocale
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                class: _normalizeClass(`flag flag-${slotProps.data}`)
              }, null, 2)
            ])
          ]),
          _: 1
        }, 8, ["data", "selected", "onValueChanged"])
      ]),
      _: 1
    }, 8, ["onHide"])
  ]))
}