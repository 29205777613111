
import { defineComponent, onMounted, PropType, ref } from "vue";

import DataTable, { DataTableSortEvent } from "primevue/datatable";
import Column from "primevue/column";

import { DataTableSelectionType } from "@/core/enums/DataTableSelectionType";
import { IDataTableHeader } from "@/shared/interface/IDataTableHeader";
import { PageReportTemplate } from "@/core/enums/PageReportTemplate";
import { PaginatorTemplate } from "@/core/enums/PaginatorTemplate";
import { SortDirection, SortField } from "@/generated/graphql";
import { i18n } from "@/plugins/i18n";

export default defineComponent({
  components: {
    DataTable,
    Column
  },
  props: {
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      default: () => []
    },
    headers: {
      type: Array as PropType<IDataTableHeader[]>,
      default: () => []
    },
    currentSelectedRows: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any>,
      default: () => []
    },
    totalRecords: {
      type: Number,
      default: null
    },
    paginator: {
      type: Boolean,
      default: true
    },
    emptyMessage: {
      type: String,
      default: "No records found"
    },
    dataKey: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    sortField: {
      type: String,
      default: null
    },
    sortOrder: {
      type: Number,
      default: 1
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "page-changed",
    "sort-changed",
    "row-select",
    "row-unselect",
    "value-changed",
    "multiple-select"
  ],
  setup(props) {
    const selectedRow = !props.isMultiSelect
      ? ref<unknown>({})
      : ref<unknown>([]);

    const selectionMode = props.isMultiSelect
      ? DataTableSelectionType.MULTIPLE
      : DataTableSelectionType.SINGLE;

    onMounted(() => {
      if (props.currentSelectedRows.length > 0) {
        const dataKey = props.dataKey ?? "";
        if (dataKey) {
          selectedRow.value = props.data.filter(
            x => props.currentSelectedRows.indexOf(x[dataKey]) >= 0
          );
        } else {
          selectedRow.value = props.currentSelectedRows;
        }
      }
    });

    return {
      selectedRow,
      selectionMode,
      DataTableSelectionType,
      PageReportTemplate,
      PaginatorTemplate
    };
  },
  methods: {
    onRowClick(row: { data: object }) {
      if (!this.isMultiSelect) {
        this.emitChange(row.data);
      }
    },
    onValueChanged() {
      if (this.isMultiSelect && this.selectedRow) {
        this.emitChange(this.selectedRow);
      }
    },
    emitChange(row: unknown) {
      const returnData = ref();
      const dataKey = this.$props.dataKey ?? "";
      if (dataKey) {
        if (this.$props.isMultiSelect) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const selected: any[] = row as any[];
          returnData.value = selected.map(row => row[dataKey]);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const selected: any = row;
          returnData.value = selected[dataKey];
        }
      } else {
        returnData.value = row;
      }
      this.$emit("row-select", returnData.value);
      this.$emit("row-unselect", returnData.value);
      this.$emit("value-changed", returnData.value);
    },
    onSort(event: DataTableSortEvent) {
      this.$emit("sort-changed", {
        sortField:
          this.$props.headers.find(header => header.field === event.sortField)
            ?.sortField ?? SortField.NAME,
        sortDirection:
          event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC
      });
    },
    getPaginatorTemplate() {
      if (this.$props.totalRecords <= 10) return "CurrentPageReport";

      return this.$props.totalRecords > 20
        ? PaginatorTemplate.DEFAULT
        : PaginatorTemplate.DEFAULT_TWO_PAGES;
    },
    getRowsPerPage() {
      if (this.$props.totalRecords <= 10) return undefined;

      const rowsPerPage = [10];
      if (this.$props.totalRecords > 10) rowsPerPage.push(20);

      if (this.$props.totalRecords > 20) rowsPerPage.push(30);

      return rowsPerPage;
    },
    onSelectAllChange(rows: { data: object }) {
      this.emitChange(rows.data);
    },
    onUnselectAllChange() {
      this.emitChange([]);
    },
    t(key: string) {
      return i18n.global.t(key);
    }
  }
});
