import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createBlock(_component_FileUpload, {
    ref: "fileUploadRef",
    name: _ctx.name,
    mode: _ctx.mode,
    url: "./uploads",
    customUpload: true,
    accept: _ctx.fileType,
    auto: true,
    chooseLabel: _ctx.$t('upload.browse'),
    chooseIcon: " ",
    showUploadButton: false,
    showCancelButton: false,
    onUploader: _ctx.onUpload
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slotName) => {
      return {
        name: slotName,
        fn: _withCtx((slotData) => [
          _renderSlot(_ctx.$slots, slotName, { data: slotData })
        ])
      }
    })
  ]), 1032, ["name", "mode", "accept", "chooseLabel", "onUploader"]))
}