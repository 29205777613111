
import { StatusType } from "@/core/enums";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    statusId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    let badgeColor = "";
    let badgeText = "";

    switch (props.statusId) {
      case StatusType.ACTIVE: {
        badgeColor = "green";
        badgeText = "Active";
        break;
      }
      case StatusType.ENABLED: {
        badgeColor = "green";
        badgeText = "Enabled";
        break;
      }
      case StatusType.ONLINE: {
        badgeColor = "green";
        badgeText = "online";
        break;
      }
      case StatusType.PENDING: {
        badgeColor = "yellow";
        badgeText = "Pending";
        break;
      }
      case StatusType.DISABLED: {
        badgeColor = "gray";
        badgeText = "Disabled";
        break;
      }
      case StatusType.ENTERPRISE: {
        badgeColor = "green";
        badgeText = "Enterprise";
        break;
      }
      case StatusType.FREE_TRIAL: {
        badgeColor = "yellow";
        badgeText = "Free Trial";
        break;
      }
      case StatusType.SENT: {
        badgeColor = "gray";
        badgeText = "Sent";
        break;
      }
      case StatusType.DELIVERED: {
        badgeColor = "green";
        badgeText = "Delivered";
        break;
      }
      case StatusType.READ: {
        badgeColor = "green";
        badgeText = "Read";
        break;
      }
      default: {
        badgeColor = "";
        badgeText = "";
        break;
      }
    }

    return {
      badgeColor,
      badgeText
    };
  }
});
