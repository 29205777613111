
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import ListBox from 'primevue/listbox';

export default defineComponent({
  components: {
    ListBox,
  },
  props: {
    selected: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      default: null,
    },
    data: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any[]>,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: ' ',
    },
  },
  emits: ['value-changed'],
  setup(props) {
    const selectedOption = ref();
    const previousSelectedOption = ref();

    onMounted(() => {
      if (props.selected) {
        selectedOption.value = props.selected;
        previousSelectedOption.value = props.selected;
      }
    });

    watch(
      () => props.data,
      () => {
        if (selectedOption.value) {
          const selectedItem = props.data.find(
            item => +item.id === +selectedOption.value.id,
          );
          selectedOption.value = selectedItem ?? null;
        }
      },
    );

    return {
      selectedOption,
      previousSelectedOption,
    };
  },
  methods: {
    selectDistribution() {
      if (this.selectedOption) {
        this.previousSelectedOption = this.selectedOption;
        this.$emit('value-changed', this.selectedOption);
      } else {
        this.selectedOption = this.previousSelectedOption;
      }
    },
  },
});
