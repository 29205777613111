import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from "@apollo/client/core";
import { useAuth } from "@/composables";

import { GraphQLErrorCode } from "@/shared/constants/graphql-error-code";
import { LocalStorageKey } from "@/shared/constants/local-storage-keys";

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "http://localhost:8080/graphql"
});

const { user } = useAuth();

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: user.value?.token ? `Bearer ${user.value.token}` : ""
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const logoutLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) {
    return;
  }

  const authError = graphQLErrors.find(
    x => x.extensions?.code === GraphQLErrorCode.UNAUTHENTICATED
  );

  if (authError) {
    localStorage.removeItem(LocalStorageKey.USER);
  }
});

// Cache implementation
const cache = new InMemoryCache();

const link = ApolloLink.from([authLink, logoutLink, httpLink]);

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache
});

export default apolloClient;
