
import { defineComponent, ref } from 'vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import { usePreSignedUploadUrlQuery } from '@/generated/graphql';
import { usePreloader, useToast } from '@/composables';
import 'vue-advanced-cropper/dist/style.css';

export default defineComponent({
  components: {
    Cropper,
  },
  emits: ['avatar-updated'],
  setup() {
    const { showLoader, hideLoader } = usePreloader();
    const toast = useToast();

    const circleStencil = CircleStencil;

    const isFormVisible = ref(false);
    const isImageLoaded = ref<boolean>(false);
    const isAdminAvatar = ref<boolean>(false);

    const uploadedImage = ref();
    const cropperRef = ref();
    const croppedImg = ref();

    const cropperProps = {
      minAspectRatio: 16 / 8,
      maxAspectRatio: 4 / 8,
    };
    const canvasSize = {
      height: 100,
      width: 100,
    };

    return {
      circleStencil,
      cropperProps,
      canvasSize,
      cropperRef,
      croppedImg,
      isFormVisible,
      isAdminAvatar,
      uploadedImage,
      isImageLoaded,
      toast,
      showLoader,
      hideLoader,
    };
  },
  methods: {
    onImageLoaded() {
      this.isImageLoaded = true;
    },
    resetForm() {
      this.cropperRef = '';
      this.croppedImg = '';
      this.uploadedImage = '';
    },
    showForm(isAdmin: boolean | undefined) {
      this.isFormVisible = true;
      this.isAdminAvatar = isAdmin ?? false;
      this.isImageLoaded = false;
    },
    closeForm() {
      this.isFormVisible = false;
      this.resetForm();
    },
    setAvatarImage(event: { files: [{ objectURL: unknown; name: string }] }) {
      const file = event.files[0];
      this.uploadedImage = file.objectURL;
    },
    async getAvatarURL() {
      const { canvas } = this.cropperRef.getResult();
      const avatarURL = await canvas.toDataURL();
      return avatarURL;
    },
    getAvatarFileName() {
      const fileNamePrefix = this.isAdminAvatar
        ? 'admin_profile_'
        : 'user_profile_';

      const fileName = `${fileNamePrefix}${Date.now().toString()}`;
      return fileName;
    },
    async setAvatar() {
      const avatarURL = await this.getAvatarURL();
      const fileName = this.getAvatarFileName();

      this.showLoader();

      const { onResult, onError } = usePreSignedUploadUrlQuery(
        { fileName },
        { fetchPolicy: 'no-cache' },
      );

      onResult(result => {
        const presignedUploadURL = result.data.preSignedUploadURL.url;

        this.hideLoader();
        this.$emit('avatar-updated', avatarURL, presignedUploadURL);
        this.closeForm();
      });

      onError(() => {
        this.hideLoader();
        this.toast.errorToast('An error has occured');
      });
    },
  },
});
