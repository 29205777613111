
import { computed, defineComponent } from "vue";
import Dialog from "primevue/dialog";

export default defineComponent({
  components: {
    PrimeDialog: Dialog
  },
  props: {
    header: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    dismissableMask: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: null
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const display = computed({
      get: () => props.visible,
      set: value => emit("update:visible", value)
    });
    return { display };
  }
});
