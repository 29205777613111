import { RouteRecordRaw } from 'vue-router';
import { RouteName } from '@/shared/constants/route-name';
import { FrontLayout } from '@/layouts';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/forgot-password',
    name: RouteName.FORGOT_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/forgot-password.vue'
      ),
    meta: { layout: FrontLayout },
  },
  {
    path: '/reset-password',
    name: RouteName.RESET_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '../views/reset-password.vue'
      ),
    meta: { layout: FrontLayout },
  },
  {
    path: '/verify-password-reset-code',
    name: RouteName.VERIFY_RESET_PASSWORD_CODE,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/verify-password-reset-code.vue'
      ),
    meta: { layout: FrontLayout },
  },
];

export default routes;
