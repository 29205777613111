export enum StatusType {
  ACTIVE = 1,
  PENDING = 2,
  DISABLED = 3,
  DELETED = 4,
  ENABLED = 5,
  ONLINE = 6,
  ENTERPRISE = 7,
  FREE_TRIAL = 8,
  SENT = 11,
  DELIVERED = 12,
  READ = 13
}
