import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-overview-card is-blank"
}
const _hoisted_2 = {
  key: 1,
  class: "c-overview-card is-text"
}
const _hoisted_3 = { class: "c-overview-card-content with-avatar" }
const _hoisted_4 = { class: "c-overview-card-header" }
const _hoisted_5 = { class: "c-overview-card-title" }
const _hoisted_6 = { class: "c-overview-card-subtitle" }
const _hoisted_7 = {
  key: 0,
  class: "c-overview-label"
}
const _hoisted_8 = { class: "c-overview-card-title" }
const _hoisted_9 = {
  key: 0,
  class: "c-overview-label"
}
const _hoisted_10 = {
  key: 3,
  class: "c-overview-card is-flippable"
}
const _hoisted_11 = { class: "c-overview-card-flippable" }
const _hoisted_12 = { class: "c-overview-card-front" }
const _hoisted_13 = { class: "c-overview-card-title" }
const _hoisted_14 = { class: "c-overview-card-back" }
const _hoisted_15 = { class: "c-overview-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_avatar = _resolveComponent("base-avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isBlank)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_ctx.titleWithAvatar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_base_avatar, {
              label: _ctx.label,
              image: _ctx.avatarUrl
            }, null, 8, ["label", "image"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.label), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
            ])
          ]),
          _renderSlot(_ctx.$slots, "content", {}, () => [
            (_ctx.textContent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.textContent), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : (!_ctx.isFlippable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["c-overview-card", { 'is-text': !!_ctx.textContent }])
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.link)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: _ctx.link,
                    class: "c-overview-card-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translatedLinkLabel) + " ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(["pi", _ctx.linkIcon])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : _createCommentVNode("", true)
            ]),
            _renderSlot(_ctx.$slots, "content", {}, () => [
              (_ctx.textContent)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.textContent), 1))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
                  (_ctx.link)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: _ctx.link,
                        class: "c-overview-card-link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translatedLinkLabel) + " ", 1),
                          _createElementVNode("span", {
                            class: _normalizeClass(["pi", _ctx.linkIcon])
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "content"),
                _createElementVNode("div", {
                  class: "c-flip-button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.flipCard && _ctx.flipCard(...args))),
                  onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.flipCard && _ctx.flipCard(...args)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["pi", _ctx.flipIcon])
                  }, null, 2)
                ], 32)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_ctx.backLabel) + " ", 1),
                  (_ctx.link)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: _ctx.link,
                        class: "c-overview-card-link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translatedLinkLabel) + " ", 1),
                          _createElementVNode("span", {
                            class: _normalizeClass(["pi", _ctx.linkIcon])
                          }, null, 2)
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "contentBack"),
                _createElementVNode("div", {
                  class: "c-flip-button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.flipCard && _ctx.flipCard(...args))),
                  onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.flipCard && _ctx.flipCard(...args)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["pi", _ctx.flipBackIcon])
                  }, null, 2)
                ], 32)
              ])
            ])
          ]))
}