
import { defineComponent, PropType } from 'vue';
import OtpInput from '@/shared/components/otp-input.vue';

export default defineComponent({
  components: {
    OtpInput,
  },
  props: {
    numInputs: {
      type: Number,
      default: 4,
    },
    inputClasses: {
      type: [String, Array] as PropType<string[] | string>,
      default: '',
    },
    conditionalClass: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    inputType: {
      type: String as PropType<
        'number' | 'tel' | 'letter-numeric' | 'password'
      >,
      validator: (value: string) =>
        ['number', 'tel', 'letter-numeric', 'password'].includes(value),
      default: 'number',
    },
    inputmode: {
      type: String,
      validator: (value: string) =>
        ['numeric', 'text', 'tel', 'none'].includes(value),
      default: 'numeric',
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    seperator: {
      type: String,
      default: '-',
    },
  },
  emits: ['on-complete', 'on-change'],
  setup(_, { emit }) {
    const handleOnComplete = (value: string) => emit('on-complete', value);

    const handleOnChange = (value: string) => {
      emit('on-change', value);
    };

    return { handleOnComplete, handleOnChange };
  },
});
