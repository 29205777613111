
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  components: {
    PrimeButton: Button
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    badge: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ""
    }
  },
  setup() {
    return {};
  }
});
