
import { defineComponent } from 'vue';
import TabPanel from 'primevue/tabpanel';

export default defineComponent({
  components: {
    TabPanel,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
